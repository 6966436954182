import React from "react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";

// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/css/global.css";
import "assets/css/customcss.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import UserAuthLayout from "layouts/User/UserAuth";
import General from "layouts/User/General";
import UserPanel from "layouts/User/UserPanel";
import OrganizationPanel from "layouts/Organization/OrganizationPanel";
import AdminPanel from "layouts/Admin/AdminPanel";
import { TourProvider } from "@reactour/tour";

const root = ReactDOM.createRoot(document.getElementById("root"));
function RequireAuth({ children }) {
  const isAuthenticated = localStorage.getItem("token") ? true : false;
  return isAuthenticated ? children : <Redirect to="/auth/login" />;
}

const steps = [
  {
    selector: ".profile-step",
    content: "Profile Step",
  },
  {
    selector: ".client-step",
    content: "Client Step",
  },
  {
    selector: ".case-step",
    content: "Case Step",
  },
  {
    selector: ".referrals-step",
    content: "Referral Step",
  },
  {
    selector: ".reporting-step",
    content: "Reporting Step",
  },
  {
    selector: ".powerups-step",
    content: "Power Ups Step",
  },
  {
    selector: ".integration-step",
    content: "Integrations",
  },
  {
    selector: ".billing-step",
    content: "Billing",
  },
];
root.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/admin"
        render={(props) => (
          <RequireAuth>
            <AdminPanel {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path="/user"
        render={(props) => (
          <RequireAuth>
            <UserPanel {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path="/organization"
        render={(props) => (
          <RequireAuth>
            <OrganizationPanel {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path="/general"
        render={(props) => (
          <RequireAuth>
            <General {...props} />
          </RequireAuth>
        )}
      />
      <Route path="/uauth" render={(props) => <UserAuthLayout {...props} />} />
      <Redirect from="*" to="/uauth/login" />
    </Switch>
  </BrowserRouter>
);
