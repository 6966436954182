import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import axiosConfig from "consts/axiosConfig";

function OrganizationDashboard() {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});
  const getReports = () => {
    axiosConfig
      .get("organization/reports")
      .then((res) => {
        setReports(res.data.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getReports();
  }, []);
  if (loading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
      <CardsHeader name="" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total Contacts
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {reports?.clients}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-active-40" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Your Network
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {reports?.network}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                      <i className="ni ni-chart-pie-35" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Number of Cases Referred In
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {reports?.in}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Number of Cases Referred
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {reports?.out}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrganizationDashboard;
