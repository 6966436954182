import { removeWidgetItem } from "consts/Constants";
import { currencyFormatter } from "consts/Constants";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import AddEditServiceModal from "./AddEditServiceModal";
import UploadFileServiceModel from "./UploadFileServiceModel";

export default function CaseServicesSection(props) {
  const [currentModel, setCurrentModel] = useState({});
  const [currentServiceId, setCurrentServiceId] = useState(null);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const removeItem = (id) => {
    removeWidgetItem("organization/cases/services/delete/" + id, props.getItem);
  };
  const renderServices = () => {
    if (props.caseData.services) {
      if (props.caseData.services.length) {
        return props.caseData.services.map((service) => {
          return (
            <tr key={service.id}>
              <td scope="row">
                {service.name}
                {service.completed ? "(Completed)" : ""}
              </td>
              <td>{currencyFormatter.format(service.cost)}</td>
              <td>{service.date}</td>
              <td>
                {props.functions.services ? (
                  <>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setCurrentServiceId(service.id);
                        setShowFileUploadModal(true);
                      }}
                    >
                      <i className="fa fa-file" />
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => {
                        setShowAddServiceModal(true);
                        setCurrentModel(service);
                      }}
                    >
                      <i className="fa fa-pen" />
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            removeItem(service.id);
                          }
                        })
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          );
        });
      }
    }
    return (
      <tr>
        <td colSpan={4} className="text-center">
          No Items
        </td>
      </tr>
    );
  };
  return (
    <Card>
      {props.functions.services ? (
        <>
          <AddEditServiceModal
            showModal={showAddServiceModal}
            serviceList={props.serviceList}
            serviceName={props.serviceName}
            toggleModal={setShowAddServiceModal}
            currentModel={currentModel}
            case_id={props.caseData.id}
            getItem={props.getItem}
            title={`Case ${props.serviceName ? props.serviceName : "Service"}`}
          />
          <UploadFileServiceModel
            showModal={showFileUploadModal}
            toggleModal={setShowFileUploadModal}
            case_id={props.caseData.id}
            service_id={currentServiceId}
            getItem={props.getItem}
            title={`Attachement`}
          />
        </>
      ) : (
        <></>
      )}
      <CardHeader>
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>{props.serviceName ? props.serviceName : "Services"}</h3>
          {props.functions.services ? (
            <button
              className="btn btn-link btn-md"
              onClick={() => {
                setShowAddServiceModal(true);
                setCurrentModel({});
              }}
            >
              <i className="fa fa-plus" /> Add New{" "}
              {props.serviceName ? props.serviceName : "Service"}
            </button>
          ) : (
            <></>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Cost</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{renderServices()}</tbody>
          </Table>
        </Row>
      </CardBody>
    </Card>
  );
}
