import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";

export default function UserPracticesModal(props) {
  const [loading, setLoading] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const sync_areas = () => {
    var list = [];
    if (selectedAreas.length) {
      list = selectedAreas.map((a) => a.value);
    }
    var url = props.url_perfix + "practice_areas";
    setLoading(true);
    axiosConfig
      .post(url, { list: list.toString() })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    currentAreas();
  }, []);
  const currentAreas = () => {
    var opts = [];
    if (props.selectedAreas) {
      props.selectedAreas.map((cl) => {
        opts.push({
          value: cl.id,
          label: cl.name,
        });
      });
    }
    setSelectedAreas(opts);
  };
  const getAreas = () => {
    var opts = [];
    if (props.areas) {
      props.areas.map((cl) => {
        opts.push({
          value: cl.id,
          label: cl.name,
        });
      });
    }
    return opts;
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label className="form-control-label">
            Select your {props.title}
          </label>
          <MultiSelect
            options={getAreas()}
            value={selectedAreas}
            onChange={setSelectedAreas}
            labelledBy="Select"
          />
        </FormGroup>
      </ModalBody>
      {loading ? (
        <ModalFooter>
          <div className="d-flex ai-center jc-center" style={{ width: "100%" }}>
            <Spinner animation="border" />
          </div>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button color="secondary" onClick={() => props.toggleModal(false)}>
            Discard
          </Button>
          <Button
            disabled={loading}
            color="primary"
            type="button"
            onClick={() => sync_areas()}
          >
            Save
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
