import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";
import { simpleWebRequest } from "consts/Constants";
import AddPowerUpModal from "./AddPowerUpModal";
import { states } from "consts/USSTATES";
import moment from "moment";

export default function UserPowerUps(props) {
  const [loading, setLoading] = useState(false);
  const [powerUps, setPowerUps] = useState([]);
  const [areas, setAreas] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const getItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get("user/powerups")
      .then((res) => {
        setLoading(false);
        setPowerUps(res.data.result.powerups);
        setAreas(res.data.result.areas);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getItems();
  }, []);
  const renderStatus = (status) => {
    switch (status) {
      case "queue":
        return <Badge color="info">Queue</Badge>;

      case "active":
        return <Badge color="success">Active</Badge>;
      case "expired":
        return <Badge color="warning">Expired</Badge>;
      default:
        break;
    }
  };
  const renderPowerUps = () => {
    if (loading) {
      return (
        <tr className="text-center">
          <td colSpan={7}>
            <Spinner color="info" size="xl" />
          </td>
        </tr>
      );
    } else if (powerUps) {
      return powerUps.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>{tr.area?.name}</td>
            <td>{tr.state}</td>
            <td>{tr.frequency}</td>
            <td>{moment(tr.starts_at).format("YYYY-MM-DD")}</td>
            <td>{moment(tr.expires_at).format("YYYY-MM-DD")}</td>
            <td>{renderStatus(tr.status)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-center">
          <td colSpan={7}>
            <p>No Powerups yet!</p>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <AddPowerUpModal
        areas={areas}
        showModal={showModal}
        toggleModal={setShowModal}
        getItems={getItems}
      />
      <ListHeader
        name="Preferred Members"
        parentName="Dashboard"
        setShowModal={setShowModal}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Preferred Members</h3>
              </CardHeader>
              <Table striped>
                <thead>
                  <tr>
                    <th>PRACTICE AREA</th>
                    <th>STATE</th>
                    <th>TYPE</th>
                    <th>STARTS AT</th>
                    <th>EXPIRES AT</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>{renderPowerUps()}</tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
