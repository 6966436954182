import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";

export default function CaseInformationRead(props) {
  const renderCustomFields = () => {
    if (props.customFields)
      if (props.customFields.length) {
        return props.customFields.map((cf) => {
          if (cf.type === "text") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>{props.caseData?.cf_data[`element-${cf.id}`]}</span>
                </div>
              </Col>
            );
          } else if (cf.type === "textarea") {
            return (
              <Col key={`element-${cf.id}`} lg={12}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>{props.caseData?.cf_data[`element-${cf.id}`]}</span>
                </div>
              </Col>
            );
          } else if (cf.type === "checkbox") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>{props.caseData?.cf_data[`element-${cf.id}`]}</span>
                </div>
              </Col>
            );
          } else if (cf.type === "radio") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>{props.caseData?.cf_data[`element-${cf.id}`]}</span>
                </div>
              </Col>
            );
          } else if (cf.type === "select") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>{props.caseData?.cf_data[`element-${cf.id}`]}</span>
                </div>
              </Col>
            );
          } else if (cf.type === "date") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <div className="d-flex flex-row ai-center">
                  <h3>{cf.name}: </h3>
                  <span>
                    {props.caseData?.cf_data[`element-${cf.id}`]
                      ? moment(
                          props.caseData?.cf_data[`element-${cf.id}`]
                        ).format("yyyy-MM-DD")
                      : ""}
                  </span>
                </div>
              </Col>
            );
          }
        });
      }
  };
  return (
    <Card className="h3-class">
      <CardHeader>
        <h3>Case Dashboard</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={6}>
            <div className="d-flex flex-row ai-center">
              <h3>Case Name: </h3>
              <span>{props.caseData?.name}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="d-flex flex-row ai-center">
              <h3>Case Date: </h3>
              <span>{moment(props.caseData?.date).format("yyyy-MM-DD")}</span>
            </div>
          </Col>

          <Col lg={6}>
            <div className="d-flex flex-row ai-center">
              <h3>Case Status: </h3>
              <span>{props.caseData?.status}</span>
            </div>
          </Col>
          <Col lg={6}>
            <div className="d-flex flex-row ai-center">
              <h3>Completion Status:</h3>
              <span>
                {props.caseData?.completed ? "Completed" : "Not completed"}
              </span>
            </div>
          </Col>
          {renderCustomFields()}
        </Row>
      </CardBody>
    </Card>
  );
}
