import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";

export default function Plans(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [voucher, setVoucher] = useState("");
  const renderPlans = () => {
    if (props.plans.length) {
      return props.plans.map((plan) => {
        if (plan.duration === "month") {
          return (
            <Card className="card-pricing border-0 text-center mb-4">
              <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                  {plan.name}
                </h4>
                <span className="text-muted">Per {plan.duration}</span>
              </CardHeader>
              <CardBody className="px-lg-7">
                <div className="display-2">${plan.price}</div>
                <Button
                  className="mb-3"
                  color="info"
                  type="button"
                  onClick={() => {
                    setSelectedPlan(plan);
                    setShowModal(true);
                  }}
                >
                  Subscribe
                </Button>
              </CardBody>
            </Card>
          );
        } else {
          return (
            <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
              <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                  {plan.name}
                </h4>{" "}
                <span className="text-white">per {plan.duration}</span>
              </CardHeader>
              <CardBody className="px-lg-7">
                <div className="display-2 text-white">${plan.price}</div>
                <Button
                  className="mb-3"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setSelectedPlan(plan);
                    setShowModal(true);
                  }}
                >
                  Subscribe
                </Button>
              </CardBody>
            </Card>
          );
        }
      });
    }
  };
  const subscribe = () => {
    var data = { code: voucher };
    setLoading(true);
    axiosConfig
      .post("billing/subscribe/" + selectedPlan.id, data)
      .then((res) => {
        if (!res.data.hasError) {
          setShowModal(false);
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const checkVoucher = () => {
    if (voucher == null || voucher === "") return false;
    setLoading(true);
    if (voucher) {
      axiosConfig
        .post("billing/check_voucher/", { code: voucher })
        .then((res) => {
          if (!res.data.hasError) {
            if (res.data.result.voucher) {
              setVoucherApplied(true);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Card>
      <Modal isOpen={showModal}>
        <ModalHeader>Subscribe</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <h3>
                You are subscribing to {selectedPlan.duration}ly{" "}
                {selectedPlan.name} Plan.
              </h3>
              <h5>
                You will be charged ${selectedPlan.price} now and every{" "}
                {selectedPlan.duration} from now.
              </h5>
              <hr className="my-4" />
              <FormGroup>
                <h6 className="text-muted" htmlFor={`input-name`}>
                  If you have a voucher, please type it down here.
                </h6>
                <Input
                  size="sm"
                  id={`input-name`}
                  placeholder="Name"
                  type="text"
                  disabled={loading || voucherApplied}
                  value={voucher}
                  onChange={(e) => setVoucher(e.target.value)}
                />
                {voucherApplied ? (
                  <Button
                    className="float-right"
                    size="sm"
                    color="link"
                    onClick={() => {
                      setVoucher("");
                      setVoucherApplied(false);
                    }}
                  >
                    Remove Voucher
                  </Button>
                ) : (
                  <Button
                    className="float-right"
                    size="sm"
                    color="link"
                    disabled={voucher ? false : true}
                    onClick={() => {
                      checkVoucher();
                    }}
                  >
                    Check Voucher Availabilty
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button color="info" type="button" onClick={() => subscribe()}>
              Confirm
            </Button>
          </ModalFooter>
        )}
      </Modal>
      <CardHeader>
        <h3>Plans</h3>
      </CardHeader>
      <CardBody>
        <div className="pricing card-group flex-column flex-md-row mb-3">
          {renderPlans()}
        </div>
      </CardBody>
    </Card>
  );
}
