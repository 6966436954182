import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { APP_IMAGE_URL } from "consts/Constants";
import UserCertificateModal from "./UserCertificateModal";
import UserAddButtonWidget from "../Components/UserAddButtonWidget";
import UserEditDeleteButtonWidget from "../Components/UserEditDeleteButtonWidget";
import UserSortableButtonWidget from "../Components/UserSortableButtonWidget";
import { sortWidgetItems } from "consts/Constants";
import { removeWidgetItem } from "consts/Constants";
export default function UserCertificateComponent(props) {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentModel, setCurrenModel] = useState({});

  useEffect(() => {
    setItems(props.userData.certificates);
  }, [props.userData.certificates]);

  const SortableItem = SortableElement(({ item }) => (
    <div>
      <div className="d-flex flex-row jc-space-between edu-item jc-center">
        <div className="d-flex flex-row ai-start">
          <div className="d-flex flex-row edu-img ai-center">
            <UserSortableButtonWidget editable={props.editable} />
            <img
              alt="edu-logo"
              src={
                item.image
                  ? APP_IMAGE_URL + item.image
                  : require("assets/img/theme/img-1-1000x600.jpg")
              }
              width={50}
              height={50}
              className="rounded-circle"
            />
          </div>
          <div className="d-flex flex-column edu-item-inner ml-3">
            <h4>{item.name}</h4>
            <span>{item.year ? moment(item.year).format("yyyy") : ""}</span>
            <span>{item.description}</span>
          </div>
        </div>
        <UserEditDeleteButtonWidget
          editable={props.editable}
          setCurrenModel={setCurrenModel}
          setShowModal={setShowModal}
          item={item}
          removeItem={removeItem}
        />
      </div>
      <hr className="my-4" />
    </div>
  ));
  const SortableList = SortableContainer(({ items }) => {
    if (items.length) {
      return (
        <ul>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} item={value} />
          ))}
        </ul>
      );
    } else {
      return (
        <div className="text-center p-3">
          <h3 className="text-muted">No {props.title} yet!</h3>
          {props.editable ? (
            <a
              className="btn btn-link text-muted font-italic"
              onClick={() => setShowModal(true)}
            >
              Boost your profile and showcase your expertise - Add your
              certificates now!
            </a>
          ) : (
            <></>
          )}
        </div>
      );
    }
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(items, oldIndex, newIndex);
    sortWidgetItems(
      props.url_perfix + "certificates_priority",
      setItems,
      newItems,
      props.getProfile,
      props.editable
    );
  };
  const removeItem = (id) => {
    removeWidgetItem(
      props.url_perfix + "certificates_delete/" + id,
      props.getProfile
    );
  };
  if (!props.coudBeRendered) {
    return;
  }
  return (
    <Col lg={6}>
      {props.editable ? (
        <UserCertificateModal
          showModal={showModal}
          toggleModal={setShowModal}
          getProfile={props.getProfile}
          title={props.title}
          currentModel={currentModel}
          url_perfix={props.url_perfix}
        />
      ) : (
        <></>
      )}
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{props.title}</h3>
            </Col>
            <UserAddButtonWidget
              editable={props.editable}
              setCurrenModel={setCurrenModel}
              setShowModal={setShowModal}
            />
          </Row>
        </CardHeader>
        <CardBody className="p-0 sortable-list">
          {items ? (
            <SortableList
              pressDelay={500}
              items={items}
              onSortEnd={onSortEnd}
            />
          ) : (
            <div className="text-center p-3">
              <h3 className="text-muted">No {props.title} yet!</h3>
              <a
                className="btn btn-link text-muted font-italic"
                onClick={() => setShowModal(true)}
              >
                Start by adding to your {props.title}
              </a>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}
