import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";
import OverlayLoader from "consts/OverlayLoader";
import FilterModal from "./FilterModal";
import BadgeIcon from "assets/svg/Badge";
import Pagination from "react-js-pagination";
import moment from "moment";
import DownloadModal from "views/pages/components/DownloadModal";

const { SearchBar } = Search;

export default function UnRegisteredUsers(props) {
  const query = new URLSearchParams(props.location.search);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [stateName, setStateName] = useState("");
  const [city, setCity] = useState("");
  const [userType, setUserType] = useState("");
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [utm, setUtm] = useState("");
  const [sort, setSort] = useState(false);
  const [filterisOn, setFilterIsOn] = useState(false);
  const [url, setUrl] = useState("");

  const searchItems = (pageNum = 1, exportFile = false) => {
    setFilterIsOn(true);
    window.scrollTo(0, 0);
    var from = fromDate
      ? moment(
          fromDate.year + "-" + fromDate.month + "-" + fromDate.day
        ).format("yyyy-MM-DD")
      : "";
    var to = toDate
      ? moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
          "yyyy-MM-DD"
        )
      : "";
    setLoading(true);
    setShowModal(false);
    axiosConfig
      .get(
        `admin/users/search?keyword=${keyword}&page=${pageNum}&lead=1${
          stateName ? "&state=" + stateName : ""
        }${city ? "&city=" + city : ""}${userType ? "&type=" + userType : ""}${
          from ? "&start=" + from : ""
        }${to ? "&end=" + to : ""}${utm ? "&utm=" + utm : ""}${
          exportFile ? "&export=1" : ""
        }`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setUsers(res.data.result.users);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const resetFilter = () => {
    setFilterIsOn(false);
    setFromDate(null);
    setToDate(null);
    setCity(null);
    setStateName(null);
    setUserType(null);
    setKeyWord("");
    setUtm(null);
  };
  const getItems = (pageNum, exportFile = false) => {
    resetFilter();
    window.scrollTo(0, 0);

    setLoading(true);
    axiosConfig
      .get(
        `admin/users?page=${pageNum}${query.toString()}${
          stateName ? "&state=" + stateName : ""
        }${city ? "&city=" + city : ""}${userType ? "&type=" + userType : ""}${
          sort ? "&sort=firstname" : ""
        }${sort ? "&sort_type=ASC" : ""}${exportFile ? "&export=1" : ""}&lead=1`
      )
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.users);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setCity(query.get("city"));
    setStateName(query.get("state"));
    setUserType(query.get("type"));
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (users?.data) {
      return users?.data.map((tr, index) => {
        return (
          <tr
            key={tr.id}
            className={`text-center ${tr.verified ? "table-success" : ""}`}
          >
            <td>{tr.firstname + " " + tr.lastname}</td>
            <td>{tr.type?.name}</td>
            <td>{tr.utm}</td>
            <td>{tr.email}</td>
            <td>{tr.phone}</td>
            <td>{tr.city}</td>
            <td>{tr.state}</td>
            <td>
              <a href={tr.website}>{tr.website}</a>
            </td>
          </tr>
        );
      });
    }
  };
  const renderActiveFilters = () => {
    if (!showModal)
      return (
        <>
          {stateName ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              State: {stateName}
            </Button>
          ) : (
            <></>
          )}
          {city ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              City: {city}
            </Button>
          ) : (
            <></>
          )}
          {userType ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              User Type: {userType}
            </Button>
          ) : (
            <></>
          )}
          {utm ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              UTM: {utm}
            </Button>
          ) : (
            <></>
          )}
          {fromDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Since:{" "}
              {fromDate
                ? moment(
                    fromDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {toDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Until:{" "}
              {toDate
                ? moment(
                    toDate.year + "-" + toDate.month + "-" + toDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {filterisOn ||
          stateName ||
          city ||
          utm ||
          keyword ||
          userType ||
          fromDate ||
          toDate ? (
            <Button color="link" size="sm" onClick={() => getItems()}>
              Reset
            </Button>
          ) : (
            <></>
          )}
        </>
      );
  };

  const exportFile = () => {
    if (filterisOn) {
      searchItems(1, true);
    } else {
      getItems(1, true);
    }
  };
  return (
    <>
      <FilterModal
        showModal={showModal}
        toggleModal={setShowModal}
        city={city}
        setCity={setCity}
        stateName={stateName}
        setStateName={setStateName}
        filterItems={searchItems}
        title="Filter"
        query={query}
        loading={loading}
        userType={userType}
        setUserType={setUserType}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        utm={utm}
        setUtm={setUtm}
      />
      <ListHeader
        name="Unregistered Users"
        parentName="Dashboard"
        setShowModal={setShowModal}
        btnName="Filter"
        setExtraButton={() => exportFile()}
        extraBtnName="Export"
      />
      <DownloadModal
        showModal={downloadModal}
        toggleModal={setDownloadModal}
        url={url}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Unregistered Users</h3>
              </CardHeader>
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1 mt-4"
              >
                <form
                  onSubmit={(e) => {
                    setCurrenPage(1);
                    setUsers([]);
                    searchItems(1);
                    e.preventDefault();
                  }}
                >
                  <label>
                    Search:
                    <label for="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => {
                          setKeyWord(e.target.value);
                        }}
                      />
                    </label>
                    {renderActiveFilters()}
                  </label>
                </form>
              </div>
              <Table striped responsive>
                {loading ? (
                  <div className="overlay-loading d-flex jc-center pt-3">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <></>
                )}
                <thead>
                  <tr>
                    <th>
                      Name{" "}
                      <i
                        className="fa fa-sort"
                        onClick={() => setSort(!sort)}
                      ></i>
                    </th>
                    <th>Type</th>
                    <th>UTM</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Website</th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                <div>
                  <p>
                    Showing {users?.from}-{users?.to} from {users?.total}{" "}
                    results
                  </p>
                </div>
                <Pagination
                  activePage={users?.current_page ? users?.current_page : 0}
                  itemsCountPerPage={users?.per_page ? users?.per_page : 0}
                  totalItemsCount={users?.total ? users?.total : 0}
                  onChange={(pageNumber) => {
                    filterisOn ? searchItems(pageNumber) : getItems(pageNumber);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
