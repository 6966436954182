import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
  CardHeader,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import axiosConfig from "consts/axiosConfig";

function AdminDashboard() {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});
  const getReports = () => {
    axiosConfig
      .get("admin/reports")
      .then((res) => {
        setReports(res.data.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getReports();
  }, []);
  if (loading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  const UserType = {
    Attorney: 1,
    "Medical Provider": 2,
    "Case Funding": 3,
    Pharmacy: 4,
    Transportation: 5,
    "Law Firm": 1,
    Clinic: 2,
  };
  const getReportLink = (type, url, lead = false) => {
    if (type === "usertype") {
      return `/admin/users?type=${UserType[url] ? UserType[url] : url}${
        lead ? "&lead=1" : ""
      }`;
    } else if (type === "userstate") {
      return `/admin/users?state=${url}`;
    }
  };
  const renderReports = (items, type = null, lead = false) => {
    if (items)
      return Object.keys(items).map((value, index) => {
        return (
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      {value}
                    </CardTitle>
                    <a
                      className="h2 font-weight-bold mb-0"
                      href={getReportLink(type, value, lead)}
                    >
                      {items[value]}
                    </a>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        );
      });
  };
  return (
    <>
      <CardsHeader name="" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Users
                    </CardTitle>
                    <a
                      className="h2 font-weight-bold mb-0"
                      href="/admin/users?lead=1"
                    >
                      {reports?.users}
                    </a>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-active-40" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Active Users
                    </CardTitle>
                    <a
                      className="h2 font-weight-bold mb-0"
                      href="/admin/users?verified=1"
                    >
                      {reports?.active_users}
                    </a>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                      <i className="ni ni-chart-pie-35" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total Referrals
                    </CardTitle>
                    <a
                      className="h2 font-weight-bold mb-0"
                      href="/admin/refers"
                    >
                      {reports?.refers}
                    </a>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="card-stats h-150">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total Subscriptions YTD
                    </CardTitle>
                    <a
                      className="h2 font-weight-bold mb-0"
                      href="/admin/subscriptions"
                    >
                      {reports?.total_subs_ytd}
                    </a>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>All Users</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.userc, "usertype", true)}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Active Users</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.userac, "usertype")}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>All Organizations</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.orgc, "usertype")}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Active Organizations</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.orgac, "usertype")}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>User Refer Ins</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.casein)}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>User Refer Outs</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.caseout)}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Organization Refer Ins</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.caseinorg)}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Organization Refer Outs</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.caseoutorg)}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Users per State</h3>
              </CardHeader>
              <CardBody>
                <Row>{renderReports(reports.userstates, "userstate")}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminDashboard;
