import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import { Lang } from "consts/localization";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

export default function FilterModal(props) {
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                From
                {props.fromDate ? (
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => props.setFromDate(null)}
                  >
                    Clear
                  </Button>
                ) : (
                  <></>
                )}
              </label>
              <DatePicker
                value={props.fromDate}
                onChange={props.setFromDate}
                shouldHighlightWeekends
                wrapperClassName="form-control"
                inputPlaceholder="From"
                calendarPopperPosition="bottom"
                formatInputText={() =>
                  props.fromDate
                    ? moment(
                        props.fromDate.year +
                          "-" +
                          props.fromDate.month +
                          "-" +
                          props.fromDate.day
                      ).format("yyyy-MM-DD")
                    : ""
                }
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                To
                {props.toDate ? (
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => props.setToDate(null)}
                  >
                    Clear
                  </Button>
                ) : (
                  <></>
                )}
              </label>
              <DatePicker
                value={props.toDate}
                onChange={props.setToDate}
                shouldHighlightWeekends
                wrapperClassName="form-control"
                inputPlaceholder="To"
                calendarPopperPosition="bottom"
                formatInputText={() =>
                  props.toDate
                    ? moment(
                        props.toDate.year +
                          "-" +
                          props.toDate.month +
                          "-" +
                          props.toDate.day
                      ).format("yyyy-MM-DD")
                    : ""
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => props.toggleModal(false)}>
          Discard
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={() => props.filterReports()}
        >
          Filter
        </Button>
      </ModalFooter>
    </Modal>
  );
}
