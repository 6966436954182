import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import { Lang } from "consts/localization";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import { states } from "consts/USSTATES";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

export default function FilterModal(props) {
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                State
              </label>
              <Input
                placeholder="State"
                type="select"
                value={props.stateName}
                onChange={(e) => {
                  props.setStateName(e.target.value);
                  props.query.append("state", e.target.value);
                }}
              >
                <option>Select a State</option>
                {states.map((state) => {
                  return <option value={state.name}>{state.name}</option>;
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                City
              </label>

              <Input
                id={`input-city`}
                placeholder="City"
                type="text"
                value={props.city}
                onChange={(e) => {
                  props.setCity(e.target.value);
                  props.query.append("city", e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                User Type
              </label>
              <Input
                id="user_type_id"
                placeholder="User Type"
                type="select"
                value={props.userType}
                onChange={(e) => {
                  props.setUserType(e.target.value);
                  props.query.append("type", e.target.value);
                }}
              >
                <option>Select a User Type</option>
                <option value="1">Attorney</option>
                <option value="2">Medical Provider</option>
                <option value="3">Case Funding</option>
                <option value="4">Pharmacy</option>
                <option value="5">Transportation</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                UTM
              </label>
              <Input
                placeholder="UTM"
                type="text"
                value={props.utm}
                onChange={(e) => {
                  props.setUtm(e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                Filter by verification
              </label>
              <Input
                type="select"
                value={props.verified}
                onChange={(e) => {
                  props.setVerified(e.target.value);
                  props.query.append("verified", e.target.value);
                }}
              >
                <option>All Verified/Unverified Users</option>
                <option value="1">Verified Users</option>
                <option value="0">Unverified Users</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                Filter by badge
              </label>
              <Input
                type="select"
                value={props.badged}
                onChange={(e) => {
                  props.setBadged(e.target.value);
                  props.query.append("badged", e.target.value);
                }}
              >
                <option>All Badged/Nonbadged Users</option>
                <option value="1">Badged Users</option>
                <option value="0">Nonbadged Users</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                Leads/Registered Users
              </label>
              <Input
                type="select"
                value={props.lead}
                onChange={(e) => {
                  props.setLead(e.target.value);
                  props.query.append("lead", e.target.value);
                }}
              >
                <option>Registered Users</option>
                <option value="1">Leads</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col lg={12}>
            <h2>Filter by Registration Date</h2>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                From
                {props.fromDate ? (
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => props.setFromDate(null)}
                  >
                    Clear
                  </Button>
                ) : (
                  <></>
                )}
              </label>
              <DatePicker
                value={props.fromDate}
                onChange={props.setFromDate}
                shouldHighlightWeekends
                wrapperClassName="form-control"
                inputPlaceholder="From"
                calendarPopperPosition="bottom"
                formatInputText={() =>
                  props.fromDate
                    ? moment(
                        props.fromDate.year +
                          "-" +
                          props.fromDate.month +
                          "-" +
                          props.fromDate.day
                      ).format("yyyy-MM-DD")
                    : ""
                }
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label className="form-control-label" htmlFor={`input-feetype`}>
                To
                {props.toDate ? (
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => props.setToDate(null)}
                  >
                    Clear
                  </Button>
                ) : (
                  <></>
                )}
              </label>
              <DatePicker
                value={props.toDate}
                onChange={props.setToDate}
                shouldHighlightWeekends
                wrapperClassName="form-control"
                inputPlaceholder="To"
                calendarPopperPosition="bottom"
                formatInputText={() =>
                  props.toDate
                    ? moment(
                        props.toDate.year +
                          "-" +
                          props.toDate.month +
                          "-" +
                          props.toDate.day
                      ).format("yyyy-MM-DD")
                    : ""
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => props.toggleModal(false)}>
          Discard
        </Button>
        <Button
          color="primary"
          type="button"
          disabled={props.loading}
          onClick={() => props.filterItems()}
        >
          Filter
        </Button>
      </ModalFooter>
    </Modal>
  );
}
