import React, { useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  ModalFooter,
  Form,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";

function ProfileHeader(props) {
  const [showEndorseModal, setShowEndorseModal] = useState(false);
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "200px",
        }}
      >
        {props.likers ? (
          <Modal
            isOpen={showEndorseModal}
            toggle={() => setShowEndorseModal(!showEndorseModal)}
            size="md"
          >
            <ModalHeader>Endorsements</ModalHeader>
            <ModalBody>
              <Row>
                <div className="p-2 d-flex flex-row flex-wrap mx-3">
                  {props.likers.length ? (
                    props.likers.map((item) => (
                      <a href={`/user/u/${item.id}`} target="_blank">
                        <span
                          className="btn-default m-1 px-4 py-2 rounded"
                          key={item.id}
                        >
                          {item.fullname}
                        </span>
                      </a>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => setShowEndorseModal(false)}
                color="secondary"
                type="submit"
              >
                Dismiss
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          <></>
        )}
        <span className="mask bg-default opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">
                {props.userData ? props.userData.firstname : ""}
                {props.likers ? (
                  props.likers.length ? (
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => setShowEndorseModal(true)}
                      className="ml-2"
                    >
                      Endorsed by {props.likers.length}{" "}
                      {props.likers.length === 1 ? "Person" : "Persons"}
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </h1>
              <p className="text-white mt-0 mb-5">
                {props.userData ? props.userData.about : ""}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
