import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { APP_IMAGE_URL } from "consts/Constants";
import Swal from "sweetalert2";
import CreateOrganizationModal from "./UserEditModals/CreateOrganizationModal";
import JoinOrganizationModal from "./UserEditModals/JoinOrganizationModal";
export default function OrganizationCardInfo(props) {
  const [createModal, setCreateModal] = useState(false);
  const [joinModal, setJoinModal] = useState(false);

  if (!props.coudBeRendered) {
    return;
  }

  const renderOrganization = () => {
    if (props.userData.organization_id) {
      return (
        <div className="text-center mt-2">
          <img
            className="img rounded mb-3"
            src={
              props.userData.organization?.logo
                ? APP_IMAGE_URL + props.userData.organization?.logo
                : require("./../../../../../../assets/img/LN-logo.png")
            }
            width={100}
            alt="."
          />
          <a
            href={
              props.userData.organization?.website
                ? props.userData.organization?.website
                : `/user/o/${props.userData.organization?.id}`
            }
          >
            <h3 className="mb-3">{props.userData.organization?.name}</h3>
          </a>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h4 className="text-muted mt-3">
            You haven't joined an organization yet.
          </h4>
          <div className="mt-4 mb-4">
            <Button
              color="primary"
              size="md"
              onClick={() => setJoinModal(true)}
            >
              Join
            </Button>
            <Button
              color="primary"
              size="md"
              onClick={() => setCreateModal(true)}
            >
              Create
            </Button>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <CreateOrganizationModal
        title="Create Organization"
        userData={props.userData}
        showModal={createModal}
        toggleModal={setCreateModal}
        getProfile={props.getProfile}
      />
      <JoinOrganizationModal
        title="Join Organization"
        userData={props.userData}
        showModal={joinModal}
        toggleModal={setJoinModal}
        getProfile={props.getProfile}
      />
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Organization</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 sortable-list">
          {renderOrganization()}
        </CardBody>
      </Card>
    </>
  );
}
