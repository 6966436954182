import OrganizationBilling from "views/pages/Organization/OrganizationBilling";
import OrganizationCases from "views/pages/Organization/OrganizationCases";
import OrganizationShowCase from "views/pages/Organization/OrganizationCases/Case/ShowCase";
import OrganizationClients from "views/pages/Organization/OrganizationClients";
import OrganizationDashboard from "views/pages/Organization/OrganizationDashboard";
import OrganizationProfile from "views/pages/Organization/OrganizationProfile";
import OrganizationReferOuts from "views/pages/Organization/OrganizationRefers/OrganizationReferOut";
import OrganizationReferrals from "views/pages/Organization/OrganizationRefers/OrganizationRefersIn";
import OrganizationReferWizard from "views/pages/Organization/OrganizationReferWizard";
import UserPublicProfile from "views/pages/Profiles/UserPublicProfile";

const userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: OrganizationDashboard,
    layout: "/organization",
    showInMenu: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: OrganizationProfile,
    icon: "fa fa-user text-primary",
    layout: "/organization",
    showInMenu: true,
  },
  {
    path: "/clients",
    name: "Clients",
    component: OrganizationClients,
    icon: "fa fa-users text-primary",
    layout: "/organization",
    showInMenu: true,
  },

  {
    path: "/cases/:uuid",
    name: "Case",
    component: OrganizationShowCase,
    icon: "fa fa-file-invoice text-primary",
    layout: "/organization",
    showInMenu: false,
  },
  {
    path: "/cases",
    name: "Cases",
    component: OrganizationCases,
    icon: "fa fa-file-invoice text-primary",
    layout: "/organization",
    showInMenu: true,
  },

  {
    collapse: true,
    name: "Referrals",
    icon: "fa fa-exchange-alt text-primary",
    state: "refersCollapse",
    showInMenu: true,
    views: [
      {
        path: "/refers/in",
        name: "Refers In",
        component: OrganizationReferrals,
        miniName: "G",
        layout: "/organization",
        showInMenu: true,
      },
      {
        path: "/refers/out",
        name: "Refers Out",
        component: OrganizationReferOuts,
        miniName: "G",
        layout: "/organization",
        showInMenu: true,
      },
    ],
  },

  {
    path: "/refers/:case_id",
    name: "Refer",
    component: OrganizationReferWizard,
    icon: "fa fa-file-invoice text-primary",
    layout: "/organization",
    showInMenu: false,
  },
  {
    path: "/billing",
    name: "Billing",
    component: OrganizationBilling,
    icon: "fa fa-credit-card text-primary",
    layout: "/organization",
    showInMenu: false,
  },

  {
    path: "/u/:user_id",
    name: "User Profile",
    component: UserPublicProfile,
    icon: "fa fa-credit-card text-primary",
    layout: "/organization",
    showInMenu: false,
  },
  {
    path: "/o/:user_id",
    name: "Organization Profile",
    component: UserPublicProfile,
    icon: "fa fa-credit-card text-primary",
    layout: "/organization",
    showInMenu: false,
  },
];

export default userRoutes;
