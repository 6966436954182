import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import { Lang } from "consts/localization";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required(Lang.emptyFieldError),
    cost: yup.string().required(),
    date: yup.string().nullable(),
    completed: yup.boolean().default(0),
  })
  .required();
export default function AddEditServiceModal(props) {
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    var url = "organization/cases/services/";
    if (props.currentModel?.id) {
      url = url + "update/" + props.currentModel.id;
    } else {
      url = url + "store/" + props.case_id;
    }
    setLoading(true);
    data.date = moment(data.date).format("yyyy-MM-DD");
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getItem();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reset(props.currentModel);
  }, [reset, props.currentModel]);

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup key={name}>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>{props.title}</ModalHeader>
      {loading ? (
        <div className="d-flex flex-row jc-center ai-center m-4">
          <Spinner />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Row>
              <Col lg={6}>
                {createInputs(
                  "name",
                  props.serviceName ? props.serviceName : "Service",
                  props.serviceName ? props.serviceName : "Service",
                  "text"
                )}
              </Col>
              <Col lg={6}>{createInputs("cost", "Cost", "Cost", "number")}</Col>
              <Col lg={6}>
                <FormGroup>
                  <label className="form-control-label">Date</label>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <ReactDatePicker
                        showIcon
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        name="date"
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor={`input-start`}>
                    Completed
                  </label>
                  <Input
                    type="select"
                    disabled={loading}
                    {...registerRs("completed", register)}
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          {loading ? (
            <ModalFooter>
              <div
                className="d-flex ai-center jc-center"
                style={{ width: "100%" }}
              >
                <Spinner animation="border" />
              </div>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => props.toggleModal(false)}
              >
                Discard
              </Button>
              <Button disabled={loading} color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Modal>
  );
}
