import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";
import FilterModal from "./FilterModal";
import Pagination from "react-js-pagination";
import moment from "moment";
import DownloadModal from "views/pages/components/DownloadModal";

export default function AdminReferrals(props) {
  const query = new URLSearchParams(props.location.search);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [filterisOn, setFilterIsOn] = useState(false);
  const [url, setUrl] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const searchItems = (pageNum = 1, exportFile = false) => {
    setFilterIsOn(true);
    window.scrollTo(0, 0);
    var fromD = fromDate
      ? moment(
          fromDate.year + "-" + fromDate.month + "-" + fromDate.day
        ).format("yyyy-MM-DD")
      : "";
    var toD = toDate
      ? moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
          "yyyy-MM-DD"
        )
      : "";
    setLoading(true);
    setShowModal(false);
    axiosConfig
      .get(
        `admin/refers/search?keyword=${keyword}&page=${pageNum}${
          from ? "&from=" + from : ""
        }${to ? "&to=" + to : ""}${fromD ? "&start=" + fromD : ""}${
          toD ? "&end=" + toD : ""
        }${exportFile ? "&export=1" : ""}`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setData(res.data.result.refers);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const resetFilter = () => {
    setFilterIsOn(false);
    setTo("");
    setFrom("");
    setKeyWord("");
  };
  const getItems = (pageNum, exportFile = false) => {
    resetFilter();
    window.scrollTo(0, 0);

    setLoading(true);
    axiosConfig
      .get(
        `admin/refers/search?page=${pageNum}&${query.toString()}${
          exportFile ? "&export=1" : ""
        }`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setData(res.data.result.refers);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (data.data) {
      return data.data.map((tr, index) => {
        return (
          <tr key={tr.id} className={`text-center`}>
            <td> {tr.from?.fullname ? tr.from?.fullname : tr.from?.name}</td>
            <td>
              {tr.to_organization?.name
                ? tr.to_organization.name
                : tr.to_user?.firstname + " " + tr.to_user?.lastname}
            </td>
            <td>{moment(tr.created_at).format("yyyy-MM-DD HH:mm")}</td>
            <td>{tr.client?.fullname}</td>
            <td>
              <Button
                color="info"
                size="sm"
                target="_blank"
                href={"/admin/cases/" + tr.case_id}
              >
                Show Case
              </Button>
            </td>
          </tr>
        );
      });
    }
  };
  const renderActiveFilters = () => {
    if (!showModal)
      return (
        <>
          {from ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              From:
              {from}
            </Button>
          ) : (
            <></>
          )}

          {to ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              From:
              {to}
            </Button>
          ) : (
            <></>
          )}

          {fromDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Cases Created Since:{" "}
              {fromDate
                ? moment(
                    fromDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {toDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Cases Created Until:{" "}
              {toDate
                ? moment(
                    toDate.year + "-" + toDate.month + "-" + toDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {filterisOn || keyword || from || fromDate || toDate || to ? (
            <Button color="link" size="sm" onClick={() => getItems()}>
              Reset
            </Button>
          ) : (
            <></>
          )}
        </>
      );
  };

  const exportFile = () => {
    if (filterisOn) {
      searchItems(1, true);
    } else {
      getItems(1, true);
    }
  };
  return (
    <>
      <FilterModal
        showModal={showModal}
        toggleModal={setShowModal}
        filterItems={searchItems}
        title="Filter"
        query={query}
        loading={loading}
        to={to}
        setTo={setTo}
        from={from}
        setFrom={setFrom}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
      <ListHeader
        name="Referrals"
        parentName="Dashboard"
        setShowModal={setShowModal}
        btnName="Filter"
        setExtraButton={() => exportFile()}
        extraBtnName="Export"
      />
      <DownloadModal
        showModal={downloadModal}
        toggleModal={setDownloadModal}
        url={url}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Refers</h3>
              </CardHeader>
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1 mt-4"
              >
                <form
                  onSubmit={(e) => {
                    setCurrenPage(1);
                    setData([]);
                    searchItems(1);
                    e.preventDefault();
                  }}
                >
                  <label>
                    Search:
                    <label for="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => {
                          setKeyWord(e.target.value);
                        }}
                      />
                    </label>
                    {renderActiveFilters()}
                  </label>
                </form>
              </div>
              <Table striped responsive>
                {loading ? (
                  <div className="overlay-loading d-flex jc-center pt-3">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <></>
                )}
                <thead>
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Time</th>
                    <th>Client</th>
                    <th>Case</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                <div>
                  <p>
                    Showing {data?.from}-{data?.to} from {data?.total} results
                  </p>
                </div>
                <Pagination
                  activePage={data?.current_page ? data?.current_page : 0}
                  itemsCountPerPage={data?.per_page ? data?.per_page : 0}
                  totalItemsCount={data?.total ? data?.total : 0}
                  onChange={(pageNumber) => {
                    filterisOn ? searchItems(pageNumber) : getItems(pageNumber);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
