import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import UserProfileEditButtonWidget from "../Components/UserProfileEditButtonWidget";
import BasicInformationModal from "./UserEditModals/BasicInformationModal";
import AddPhoneModal from "./AddPhoneEmailModal/AddPhoneModal";
import AddEmailModal from "./AddPhoneEmailModal/AddEmailModal";
import { formatPhoneNumber } from "consts/Constants";

export default function BasicInformationComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  return (
    <>
      <BasicInformationModal
        userData={props.userData}
        showModal={showModal}
        toggleModal={setShowModal}
        title={props.title}
        getProfile={props.getProfile}
        updateProfile={props.updateProfile}
      />
      <AddPhoneModal
        showModal={showPhoneModal}
        toggleModal={setShowPhoneModal}
        getProfile={props.getProfile}
      />
      <AddEmailModal
        showModal={showEmailModal}
        toggleModal={setShowEmailModal}
        getProfile={props.getProfile}
      />
      <Row className="align-items-center mb-4">
        <Col xs="8">
          <h6 className="heading-small text-muted mb-0">{props.title}</h6>
        </Col>
        <UserProfileEditButtonWidget
          setShowModal={setShowModal}
          editable={props.editable}
        />
      </Row>
      <div className="pl-lg-4">
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Practice Name
              </label>
              <h5 className="h3">
                {props.userData.practice_name
                  ? props.userData.practice_name
                  : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                First Name
              </label>
              <h5 className="h3">{props.userData.firstname}</h5>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Last Name
              </label>
              <h5 className="h3">{props.userData.lastname}</h5>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Email Address
              </label>
              {props.userData.email ? (
                <h5 className="h3">{props.userData.email}</h5>
              ) : (
                <>
                  <br />
                  <Button
                    size="sm"
                    color="link"
                    className="pl-0"
                    onClick={() => setShowEmailModal(true)}
                  >
                    Add Email
                  </Button>
                </>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Cell Phone
              </label>
              {props.userData.phone ? (
                <h5 className="h3">
                  {formatPhoneNumber(props.userData.phone)}
                </h5>
              ) : (
                <>
                  <br />
                  <Button
                    size="md"
                    color="link"
                    className="pl-0"
                    onClick={() => setShowPhoneModal(true)}
                  >
                    Add Cell Phone
                  </Button>
                </>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Website
              </label>
              <h5 className="h3">
                {props.userData.website ? props.userData.website : "-"}
              </h5>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
