import { removeWidgetItem } from "consts/Constants";
import { currencyFormatter } from "consts/Constants";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import AddAppointmentModal from "./AddAppointmentModal";

export default function CaseAppointmentsSection(props) {
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);

  const removeItem = (id) => {
    removeWidgetItem(
      "organization/cases/appointments/delete/" + id,
      props.getItem
    );
  };
  const renderAppointments = () => {
    if (props.caseData.appointments) {
      if (props.caseData.appointments.length) {
        return props.caseData.appointments.map((appointment) => {
          return (
            <tr>
              <td scope="row">
                <a
                  className="btn btn-link"
                  href={appointment.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {appointment.title}
                </a>
              </td>
              <td>{appointment.datetime}</td>
              <td>{appointment.status}</td>
              <td>{appointment.description}</td>
              <td>
                {props.functions.appointments ? (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          removeItem(appointment.id);
                        }
                      })
                    }
                  >
                    <i className="fa fa-trash" />
                  </button>
                ) : (
                  <>-</>
                )}
              </td>
            </tr>
          );
        });
      }
    }
    return (
      <tr>
        <td colSpan={5} className="text-center">
          No Items
        </td>
      </tr>
    );
  };
  return (
    <Card>
      {props.functions.appointments ? (
        <AddAppointmentModal
          showModal={showAddAppointmentModal}
          toggleModal={setShowAddAppointmentModal}
          caseData={props.caseData}
          getItem={props.getItem}
          title="Add Appointment"
        />
      ) : (
        <></>
      )}
      <CardHeader>
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>Appointments</h3>
          {props.functions.appointments ? (
            <button
              className="btn btn-link btn-md"
              onClick={() => {
                setShowAddAppointmentModal(true);
              }}
            >
              <i className="fa fa-plus" /> Add New Appointment
            </button>
          ) : (
            <></>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Status</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{renderAppointments()}</tbody>
          </Table>
        </Row>
      </CardBody>
    </Card>
  );
}
