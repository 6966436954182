import React, { useEffect, useState } from "react";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import { useWizard } from "react-use-wizard";
import { APP_IMAGE_URL } from "consts/Constants";

export default function CPStepOne(props) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const { nextStep } = useWizard();
  const getItems = () => {
    setLoading(true);
    axiosConfig
      .get("user_type_list")
      .then((res) => {
        setLoading(false);
        setUserTypes(res.data.result.types);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const selectUserType = (type_id) => {
    setLoading(true);
    axiosConfig
      .post("user_type_update", { type_id: type_id })
      .then((res) => {
        if (!res.data.hasError) {
          if (res.data.result?.next_step === "profile") {
            history.push("/user/profile");
          } else {
            props.setOrgType(res.data.result.organization_type.singular);
            nextStep();
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  const renderTypes = (items, type) => {
    return items.map((item) => {
      return (
        <Col lg={6}>
          <Card key={item.id}>
            <CardBody>
              <div className="text-center">
                <img
                  className="img rounded-circle"
                  src={APP_IMAGE_URL + item.avatar}
                  width={100}
                  height={100}
                  alt="."
                />
                <h3 className="mt-3">{item.name}</h3>
              </div>
              <p className="text-center">{item.description}</p>
            </CardBody>
            <div className="d-flex flex-row ai-center jc-center mb-3">
              <Button
                color="info"
                size="md"
                onClick={() => {
                  selectUserType(item.id);
                }}
              >
                Pick
              </Button>
            </div>
          </Card>
        </Col>
      );
    });
  };
  return (
    <Card className="bg-secondary border-0">
      <CardHeader>
        <h3 className="text-center">Please select your account type</h3>
      </CardHeader>
      <div className="px-lg-5 py-lg-5">
        {loading ? (
          <div className="text-center">
            <Spinner color="#000" />
          </div>
        ) : userTypes.length ? (
          <Row>{renderTypes(userTypes)}</Row>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}
