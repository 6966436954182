import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import axiosConfig from "consts/axiosConfig";

export default function Subscription(props) {
  const [loading, setLoading] = useState(false);
  const [autoRenew, setAutoRenew] = useState(0);
  useEffect(() => {
    setAutoRenew(props.subscription.auto_renew);
  }, [setAutoRenew, props]);

  const changeAutoRenewal = () => {
    axiosConfig
      .post("organization/billing/toggle_renew/" + props.subscription.id)
      .then((res) => {
        if (!res.data.hasError) {
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const cancelSub = () => {
    axiosConfig
      .post("organization/billing/cancel/" + props.subscription.id)
      .then((res) => {
        if (!res.data.hasError) {
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Card>
      <CardHeader>
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>Subscription</h3>
          <Button
            size="sm"
            color="danger"
            disabled={props.subscription.cancel}
            onClick={() => cancelSub()}
          >
            {props.subscription.cancel ? "Canceled" : "Cancel"}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <h2>
          Your active subscription:{" "}
          {props.subscription.name.charAt(0).toUpperCase() +
            props.subscription.name.slice(1)}
        </h2>
        <hr className="my-4" />
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>
            Next renew at:{" "}
            {moment(props.subscription.expires_at).format("yyyy-MM-DD")}
          </h3>
          {props.subscription.cancel === 0 ? (
            <div className="m-3 d-flex flex-row">
              <label className="mr-1">Auto Renew</label>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  checked={autoRenew}
                  onChange={() => changeAutoRenewal()}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
