import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import { useWizard } from "react-use-wizard";
import Swal from "sweetalert2";
import moment from "moment";

export default function StepConfirm(props) {
  const [loading, setLoading] = useState(false);
  const { handleStep, previousStep, nextStep } = useWizard();

  const submitQuery = () => {
    var url = "user/powerups/confirm";
    setLoading(true);
    axiosConfig
      .post(url, {
        powerup_id: props.resPowerUp.powerup.id,
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal();
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col lg={12} className="text-center">
        <h3 className="text-center">Confirmation</h3>
      </Col>
      <Col lg={12} className="mt-5 mb-5">
        <span className="mt-4 mb-4">
          Your preferred membership will be applied from{" "}
          <b>
            {moment(props.resPowerUp.powerup.starts_at).format("MM/DD/YYYY")}
          </b>{" "}
          to{" "}
          <b>
            {moment(props.resPowerUp.powerup.expires_at).format("MM/DD/YYYY")}
          </b>{" "}
          for <b>{props.resPowerUp.area.name}</b> in{" "}
          <b>{props.resPowerUp.powerup.state}</b>.<br />
          <br />
          The total price is <b>${props.resPowerUp.powerup.price}</b> and it
          will be charged to your default payment method. This amount will be
          charged in addition to your monthly subscription plan. Powerups are
          non-refundable and you can’t cancel or modify them.
        </span>
        <h2 className="text-center mt-3">Do you confirm?</h2>
      </Col>
      <Col lg={6}>
        <Button
          disabled={loading}
          color="secondary"
          type="button"
          onClick={() => props.toggleModal()}
        >
          Discard
        </Button>
      </Col>
      <Col lg={6}>
        <div className="float-right">
          <Button
            disabled={loading}
            color="info"
            type="button"
            onClick={() => previousStep()}
          >
            Back
          </Button>
          <Button
            disabled={loading}
            color="primary"
            type="button"
            onClick={() => submitQuery()}
          >
            Confirm
          </Button>
        </div>
      </Col>
    </Row>
  );
}
