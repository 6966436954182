import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  Row,
  Spinner,
  NavLink,
  CardFooter,
} from "reactstrap";
import { useWizard } from "react-use-wizard";
import { APP_IMAGE_URL } from "consts/Constants";
import MyNetwork from "./Users/MyNetwork";
import SearchNetwork from "./Users/SearchNetwork";
import Swal from "sweetalert2";

export default function StepUsers(props) {
  const [activeTab, setActiveTab] = useState(0);
  const { handleStep, previousStep, nextStep } = useWizard();
  const goToNextStep = () => {
    if (props.userIds.length) {
      nextStep();
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Please pick one to continue case refer",
        showConfirmButton: false,
        toast: true,
        timer: 2500,
      });
    }
  };
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col lg={2} className="d-flex jc-center ai-center">
            <Button
              color="primary"
              size="md"
              onClick={() => {
                props.setUserIds([]);
                previousStep();
              }}
            >
              Previous
            </Button>
          </Col>
          <Col lg={8}>
            <h3 className="text-center my-3 ">Who do you want to refer to?</h3>
          </Col>
          <Col lg={2} className="d-flex jc-center ai-center">
            <Button color="info" size="md" onClick={() => goToNextStep()}>
              Next
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-row jc-center ai-center">
          <Nav pills>
            <NavItem>
              <NavLink
                onClick={() => setActiveTab(0)}
                href="#"
                active={activeTab === 0 ? "active" : ""}
              >
                My Network
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => setActiveTab(1)}
                href="#"
                active={activeTab === 1 ? "active" : ""}
              >
                Search
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab === 0 ? (
          <MyNetwork
            userIds={props.userIds}
            setUserIds={props.setUserIds}
            userType={props.userType}
            avatarData={props.avatarData}
            goToNextStep={goToNextStep}
            previousStep={previousStep}
          />
        ) : (
          <SearchNetwork
            userIds={props.userIds}
            setUserIds={props.setUserIds}
            case_id={props.case_id}
            userType={props.userType}
            selectedType={props.selectedType}
            avatarData={props.avatarData}
            goToNextStep={goToNextStep}
            previousStep={previousStep}
          />
        )}
      </CardBody>
    </Card>
  );
}
