import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { removeFromArray } from "consts/Constants";

const schema = yup
  .object({
    name: yup.string().required(),
    link: yup.string().nullable(),
    desc: yup.string().nullable(),
  })
  .required();
export default function UserContactModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const onSubmit = (data) => {
    var url = props.url_perfix + "contacts_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "contacts_update/" + props.currentModel.id;
    }
    data.phones = phones.length ? JSON.stringify(phones) : null;
    data.emails = emails.length ? JSON.stringify(emails) : null;
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setPhones(
      props.currentModel.phones ? JSON.parse(props.currentModel.phones) : ""
    );
    setEmails(
      props.currentModel.emails ? JSON.parse(props.currentModel.emails) : ""
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };

  const addPhoneSwal = async () => {
    const { value: phone } = await Swal.fire({
      title: "Phone",
      input: "text",
      inputLabel: "Phone Number",
      inputPlaceholder: "Enter...",
    });
    if (phone) {
      setPhones([...phones, phone]);
    }
  };
  const addEmailSwal = async () => {
    const { value: email } = await Swal.fire({
      title: "Email",
      input: "email",
      inputLabel: "Email Address",
      inputPlaceholder: "Enter...",
    });
    if (email) {
      setEmails([...emails, email]);
    }
  };
  const removeItem = (item, setNewItem, items) => {
    setNewItem(removeFromArray(items, item));
  };
  const renderItems = (items, setNewItem) => {
    if (items.length) {
      return items.map((item) => {
        return (
          <div
            className="d-flex flex-row jc-space-between ai-center multiple-tag"
            key={item}
          >
            <span>{item}</span>
            <i
              className="fa fa-times"
              onClick={() => removeItem(item, setNewItem, items)}
            />
          </div>
        );
      });
    }
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {createInputs("name", "Name", "Name")}
          {createInputs("link", "Link", "Link")}{" "}
          {createInputs("desc", "Description", "Description", "textarea")}
          <FormGroup>
            <label className="form-control-label">Phone</label>
            <Button
              size="sm"
              color="link"
              className="ml-2"
              onClick={() => {
                addPhoneSwal();
              }}
            >
              Add Phone
            </Button>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(phones, setPhones)}
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">Email</label>
            <Button
              size="sm"
              color="link"
              className="ml-2"
              onClick={() => {
                addEmailSwal();
              }}
            >
              Add Email
            </Button>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(emails, setEmails)}
            </div>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
