import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";

export default function AdminLeadsRefers(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");

  const getItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get("admin/leads/refers?page=" + pageNum)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.users.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  const renderUsers = () => {
    if (users) {
      return users.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>{tr.firstname + " " + tr.lastname}</td>
            <td>{tr.type?.name}</td>
            <td>{tr.contact_email}</td>
            <td>{tr.contact_phone}</td>
            <td>{tr.funnel_status}</td>
            <td>{tr.city}</td>
            <td>{tr.state}</td>
            <td>
              <a href={tr.website}>{tr.website}</a>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <>
      <ListHeader name="Referral Leads" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Referral Leads</h3>
              </CardHeader>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Website</th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              {loading ? (
                <div className="d-flex flex-row jc-center ai-center m-3">
                  <Spinner color="info" size="xl" />
                </div>
              ) : users.length === 0 ? (
                <p className="text-center m-3">No items yet!</p>
              ) : (
                <></>
              )}
              <Pagination
                className="pagination justify-content-center m-2 text-center"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => {
                      setUsers([]);
                      getItems(currentPage !== 1 ? currentPage - 1 : 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                  <PaginationLink
                    onClick={(e) => {
                      setUsers([]);
                      getItems(currentPage + 1);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
