import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
export default function ShowReferNoteModal(props) {
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>Refer Notes</ModalHeader>

      <ModalBody>
        <Row>
          <Col lg={12}>
            <p>{props.refer_note}</p>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => props.toggleModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
