import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import FilterModal from "./FilterModal";
import Pagination from "react-js-pagination";
import moment from "moment";
import ShowClientModal from "views/pages/User/UserClients/ShowClientModal";
import ClientCases from "./ClientCases";
import DownloadModal from "views/pages/components/DownloadModal";

export default function AdminClients(props) {
  const query = new URLSearchParams(props.location.search);

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [url, setUrl] = useState("");
  const [stateName, setStateName] = useState("");
  const [city, setCity] = useState("");
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sort, setSort] = useState(null);
  const [sort_type, setSortType] = useState(null);
  const [filterisOn, setFilterIsOn] = useState(false);
  const [currentModel, setCurrenModel] = useState({});
  const [showClientModal, setShowClientModal] = useState(false);
  const [showClientCaseModal, setShowClientCaseModal] = useState(false);
  const [clienCase, setClientCase] = useState({});

  const searchItems = (
    pageNum = 1,
    exportFile = false,
    s = null,
    stype = null
  ) => {
    setSort(s);
    setSortType(stype);
    setFilterIsOn(true);
    window.scrollTo(0, 0);
    var from = fromDate
      ? moment(
          fromDate.year + "-" + fromDate.month + "-" + fromDate.day
        ).format("yyyy-MM-DD")
      : "";
    var to = toDate
      ? moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
          "yyyy-MM-DD"
        )
      : "";
    setLoading(true);
    setShowModal(false);
    axiosConfig
      .get(
        `admin/clients/search?keyword=${keyword}&page=${pageNum}${
          stateName ? "&state=" + stateName : ""
        }${city ? "&city=" + city : ""}${from ? "&start=" + from : ""}${
          to ? "&end=" + to : ""
        }${exportFile ? "&export=1" : ""}${s ? "&sort=" + s : ""}${
          stype ? "&sort_type=" + stype : ""
        }`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setClients(res.data.result.clients);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const resetFilter = () => {
    setFilterIsOn(false);
    setFromDate(null);
    setToDate(null);
    setCity(null);
    setStateName(null);
    setKeyWord("");
  };
  const getItems = (pageNum, exportFile = false) => {
    resetFilter();
    window.scrollTo(0, 0);

    setLoading(true);
    axiosConfig
      .get(
        `admin/clients/search?page=${pageNum}&${query.toString()}${
          stateName ? "&state=" + stateName : ""
        }${city ? "&city=" + city : ""}${sort ? "&sort=name" : ""}${
          exportFile ? "&export=1" : ""
        }`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setClients(res.data.result.clients);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setCity(query.get("city"));
    setStateName(query.get("state"));
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (clients.data) {
      return clients.data.map((tr, index) => {
        return (
          <tr
            key={tr.id}
            className={`text-center ${tr.verified ? "table-success" : ""}`}
          >
            <td>{tr.fullname}</td>
            <td>{tr.city}</td>
            <td>{tr.state}</td>
            <td>
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  setCurrenModel(tr);
                  setShowClientModal(true);
                }}
              >
                <i className="fa fa-eye" />
              </Button>
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  setClientCase(tr);
                  setShowClientCaseModal(true);
                }}
              >
                Cases
              </Button>
            </td>
          </tr>
        );
      });
    }
  };
  const renderActiveFilters = () => {
    if (!showModal)
      return (
        <>
          {stateName ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              State: {stateName}
            </Button>
          ) : (
            <></>
          )}
          {city ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              City: {city}
            </Button>
          ) : (
            <></>
          )}

          {fromDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Since:{" "}
              {fromDate
                ? moment(
                    fromDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {toDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Until:{" "}
              {toDate
                ? moment(
                    toDate.year + "-" + toDate.month + "-" + toDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {filterisOn || stateName || city || keyword || fromDate || toDate ? (
            <Button color="link" size="sm" onClick={() => getItems()}>
              Reset
            </Button>
          ) : (
            <></>
          )}
        </>
      );
  };

  const exportFile = () => {
    if (filterisOn) {
      searchItems(1, true);
    } else {
      getItems(1, true);
    }
  };
  return (
    <>
      {showClientCaseModal ? (
        <div>
          <ClientCases
            clienCase={clienCase}
            setShowClientCaseModal={setShowClientCaseModal}
          />
        </div>
      ) : (
        <></>
      )}
      <div className={`${showClientCaseModal ? "d-none" : ""}`}>
        <FilterModal
          showModal={showModal}
          toggleModal={setShowModal}
          city={city}
          setCity={setCity}
          stateName={stateName}
          setStateName={setStateName}
          filterItems={searchItems}
          title="Filter"
          query={query}
          loading={loading}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <ShowClientModal
          showModal={showClientModal}
          toggleModal={setShowClientModal}
          currentModel={currentModel}
          url_perfix="clients/"
        />
        <ListHeader
          name="Clients"
          parentName="Dashboard"
          setShowModal={setShowModal}
          btnName="Filter"
          setExtraButton={() => exportFile()}
          extraBtnName="Export"
        />
        <DownloadModal
          showModal={downloadModal}
          toggleModal={setDownloadModal}
          url={url}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Clients</h3>
                </CardHeader>
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1 mt-4"
                >
                  <form
                    onSubmit={(e) => {
                      setCurrenPage(1);
                      setClients([]);
                      searchItems(1);
                      e.preventDefault();
                    }}
                  >
                    <label>
                      Search:
                      <label for="search-bar-0" className="search-label">
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control form-control-sm"
                          placeholder="Search"
                          value={keyword}
                          onChange={(e) => {
                            setKeyWord(e.target.value);
                          }}
                        />
                      </label>
                      {renderActiveFilters()}
                    </label>
                  </form>
                </div>
                <Table striped responsive>
                  {loading ? (
                    <div className="overlay-loading d-flex jc-center pt-3">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <></>
                  )}
                  <thead>
                    <tr>
                      <th>
                        Name
                        {filterisOn ? (
                          <i
                            className="fa fa-sort"
                            onClick={() => {
                              searchItems(
                                1,
                                false,
                                "firstname",
                                sort_type === "ASC" ? "DESC" : "ASC"
                              );
                            }}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </th>
                      <th>
                        City
                        {filterisOn ? (
                          <i
                            className="fa fa-sort"
                            onClick={() => {
                              searchItems(
                                1,
                                false,
                                "city",
                                sort_type === "ASC" ? "DESC" : "ASC"
                              );
                            }}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </th>
                      <th>
                        State
                        {filterisOn ? (
                          <i
                            className="fa fa-sort"
                            onClick={() => {
                              searchItems(
                                1,
                                false,
                                "city",
                                sort_type === "ASC" ? "DESC" : "ASC"
                              );
                            }}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderUsers()}</tbody>
                </Table>

                <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                  <div>
                    <p>
                      Showing {clients?.from}-{clients?.to} from{" "}
                      {clients?.total} results
                    </p>
                  </div>
                  <Pagination
                    activePage={
                      clients?.current_page ? clients?.current_page : 0
                    }
                    itemsCountPerPage={
                      clients?.per_page ? clients?.per_page : 0
                    }
                    totalItemsCount={clients?.total ? clients?.total : 0}
                    onChange={(pageNumber) => {
                      filterisOn
                        ? searchItems(pageNumber)
                        : getItems(pageNumber);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
