import React from "react";
import { Col } from "reactstrap";

export default function UserAddButtonWidget(props) {
  if (props.editable)
    return (
      <Col className="text-right" xs="4">
        <i
          className="fa fa-plus"
          onClick={() => {
            props.setCurrenModel({});
            props.setShowModal(true);
          }}
        />
      </Col>
    );
}
