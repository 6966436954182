/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Badge, Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import moment from "moment";
import ShowReferNoteModal from "views/pages/User/Refers/ShowReferNoteModal";

const { SearchBar } = Search;

export default function OrganizationReferrals(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [referNote, setReferNote] = useState("");
  const renderStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <Badge color="primary">{status.toUpperCase()}</Badge>;
      case "approved":
        return <Badge color="success">{status.toUpperCase()}</Badge>;
      case "rejected":
        return <Badge color="danger">{status.toUpperCase()}</Badge>;
      case "expired":
        return <Badge color="warning">{status.toUpperCase()}</Badge>;
      case "canceled":
        return <Badge color="warning">{status.toUpperCase()}</Badge>;
      case "completed":
        return <Badge color="info">{status.toUpperCase()}</Badge>;

      default:
        break;
    }
  };
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const getItems = () => {
    setLoading(true);
    axiosConfig
      .get("organization/refers/index")
      .then((res) => {
        setLoading(false);
        setData(res.data.result.refer_ins);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const acceptCase = (refer_id) => {
    setLoading(true);
    axiosConfig
      .post("organization/refers/approve/" + refer_id)
      .then((res) => {
        getItems();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const rejectCase = (refer_id) => {
    setLoading(true);
    axiosConfig
      .post("organization/refers/reject/" + refer_id)
      .then((res) => {
        getItems();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  return (
    <>
      <ListHeader name="Referrals In" parentName="Dashboard" />
      <ShowReferNoteModal
        showModal={showModal}
        toggleModal={setShowModal}
        refer_note={referNote}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Referrals</h3>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: "case.name",
                    text: "Case Name",
                    sort: false,
                  },
                  {
                    dataField: "from.fullname",
                    text: "From",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <span>
                          {row.from.fullname ? (
                            <a href={`/user/u/${row.from.id}`} target="_blank">
                              {row.from.fullname}
                            </a>
                          ) : (
                            <a href={`/user/o/${row.from.id}`} target="_blank">
                              {row.from.name}
                            </a>
                          )}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: "notes",
                    text: "REFERRAL NOTES",
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          className="btn btn-link btn-sm"
                          onClick={() => {
                            setReferNote(row.notes);
                            setShowModal(true);
                          }}
                        >
                          Show Referral Notes
                        </a>
                      );
                    },
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    formatter: (cell, row) => {
                      return renderStatusBadge(row.status);
                    },
                  },
                  {
                    dataField: "from.name",
                    text: " Time",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <span>
                          {moment(row.created_at).format("yyyy-MM-DD HH:mm")}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: "client.id",
                    text: "Client",
                    sort: true,
                    formatter: (cell, row) => {
                      return <span>{row.client.fullname}</span>;
                    },
                  },
                  {
                    dataField: "name",
                    text: "Actions",
                    editable: true,
                    sort: false,
                    formatter: (cell, row) => {
                      if (row.status === "pending") {
                        return (
                          <>
                            <a
                              className="btn btn-info btn-sm"
                              href={"/user/cases/" + row.case_id}
                              target="_blank"
                            >
                              Show Case
                            </a>
                            <a
                              className="btn btn-success btn-sm"
                              onClick={() => acceptCase(row.id)}
                            >
                              Accept
                            </a>
                            <a
                              className="btn btn-danger btn-sm"
                              onClick={() => rejectCase(row.id)}
                            >
                              Reject
                            </a>
                          </>
                        );
                      } else if (row.status === "approved") {
                        return (
                          <a
                            className="btn btn-info btn-sm"
                            href={"/user/cases/" + row.case_id}
                            target="_blank"
                          >
                            Show Case
                          </a>
                        );
                      } else {
                        return "-";
                      }
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      hover={true}
                      noDataIndication={
                        loading ? (
                          <Spinner color="info" size="xl" />
                        ) : (
                          <p>No Refers yet!</p>
                        )
                      }
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
