import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Wizard } from "react-use-wizard";
import StepRefer from "./Steps/StepRefer";
import StepTypes from "./Steps/StepTypes";
import StepUsers from "./Steps/StepUsers";

export default function WizardController(props) {
  const [userType, setUserType] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [avatarData, setAvatarData] = useState([]);
  const prepareData = (entity_id, entity_type) => {
    setUserType({ entity_id: entity_id, entity_type: entity_type });
  };
  return (
    <Wizard>
      <StepTypes
        prepareData={prepareData}
        userType={userType}
        setAvatarData={setAvatarData}
        case_id={props.case_id}
        setSelectedType={setSelectedType}
      />
      <StepUsers
        userType={userType}
        userIds={userIds}
        avatarData={avatarData}
        setUserIds={setUserIds}
        case_id={props.case_id}
        selectedType={selectedType}
      />
      <StepRefer
        userType={userType}
        userIds={userIds}
        case_id={props.case_id}
      />
    </Wizard>
  );
}
