import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import AdminLeadFunnelStatus from "./AdminLeadFunnelStatus";
import Pagination from "react-js-pagination";

export default function AdminLeads(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get("admin/leads?page=" + pageNum)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.users);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const searchItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get(`admin/leads/search?keyword=${keyword}&page=${pageNum}`)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.users);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const SendMail = (id) => {
    setLoading(true);
    axiosConfig
      .post(`admin/leads/email/${id}`)
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  const renderUsers = () => {
    if (users?.data) {
      return users?.data.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>
              <Button
                color="link"
                onClick={() => {
                  setUser(tr);
                  setShowModal(true);
                }}
              >
                {tr.firstname + " " + tr.lastname}
              </Button>
            </td>
            <td>{tr.type?.name}</td>
            <td>{tr.contact_email}</td>
            <td>{tr.contact_phone}</td>
            <td>{tr.funnel_status}</td>
            <td>{tr.city}</td>
            <td>{tr.state}</td>
            <td>
              <a href={tr.website}>{tr.website}</a>
            </td>
            <td>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => SendMail(tr.id)}
              >
                Send Email
              </button>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <>
      <ListHeader name="Leads" parentName="Dashboard" />
      <AdminLeadFunnelStatus
        showModal={showModal}
        toggleModal={setShowModal}
        user={user}
        getItems={getItems}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Leads</h3>
              </CardHeader>
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1 mt-4"
              >
                <form
                  onSubmit={(e) => {
                    setCurrenPage(1);
                    setUsers([]);
                    searchItems(1);
                    e.preventDefault();
                  }}
                >
                  <label>
                    Search:
                    <label for="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => {
                          setKeyWord(e.target.value);
                        }}
                      />
                    </label>
                  </label>
                </form>
              </div>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Website</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              {loading ? (
                <div className="d-flex flex-row jc-center ai-center m-3">
                  <Spinner color="info" size="xl" />
                </div>
              ) : users.length === 0 ? (
                <p className="text-center m-3">No items yet!</p>
              ) : (
                <></>
              )}

              <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                <div>
                  <p>
                    Showing {users?.from}-{users?.to} from {users?.total}{" "}
                    results
                  </p>
                </div>
                <Pagination
                  activePage={users?.current_page ? users?.current_page : 0}
                  itemsCountPerPage={users?.per_page ? users?.per_page : 0}
                  totalItemsCount={users?.total ? users?.total : 0}
                  onChange={(pageNumber) => {
                    getItems(pageNumber);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
