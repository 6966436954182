import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required(),
    entity_type: yup.string().nullable(),
    entity_id: yup.string().nullable(),
    type: yup.string().required(),
    amount: yup.number().required(),
    limit: yup.number().nullable(),
    expires_at: yup.string().nullable(),
  })
  .required();
export default function AddEditVoucherModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [entityType, setEntityType] = useState("organizations");
  const onSubmit = (data) => {
    var url = "admin/vouchers/store";
    if (props.currentModel?.id) {
      url = "admin/vouchers/update/" + props.currentModel.id;
    }
    data.expires_at = date ? moment(date).format("yyyy-MM-DD") : null;
    data.entity_type = entityType;
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.setCurrenModel({});
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setDate(
      props?.currentModel.expires_at
        ? new Date(props?.currentModel.expires_at)
        : new Date()
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  const renderEntities = () => {
    if (entityType === "users") {
      return props.userTypes.map((uT) => {
        return (
          <option key={uT.id} value={uT.id}>
            {uT.name}
          </option>
        );
      });
    } else {
      return props.orgTypes.map((uT) => {
        return (
          <option key={uT.id} value={uT.id}>
            {uT.name}
          </option>
        );
      });
    }
  };
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <Col lg={12}>
              {createInputs("name", "Voucher Name", "Voucher Name")}
            </Col>
            <Col lg={6}>
              {createInputs("limit", "Usage limit", "Usage limit", "number")}
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Expiration Date
                </label>
                <ReactDatePicker
                  showIcon
                  selected={date}
                  disabled={loading}
                  onChange={(date) => setDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Discount Type
                </label>
                <Input
                  type="select"
                  disabled={loading}
                  {...registerRs("type", register)}
                >
                  <option value="amount">Amount</option>
                  <option value="percent">Percent</option>
                  <option value="freemonth">Free Month</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={6}>
              {createInputs(
                "amount",
                "Discount Value",
                "Discount Value",
                "number"
              )}
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Entity Type
                </label>
                <Input
                  type="select"
                  disabled={loading}
                  value={entityType}
                  onChange={(e) => setEntityType(e.target.value)}
                >
                  <option value="organizations">Organizations</option>
                  <option value="users">Users</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Entity Type
                </label>
                <Input
                  type="select"
                  disabled={loading}
                  {...registerRs("entity_id", register)}
                >
                  {renderEntities()}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.setCurrenModel({});
                props.toggleModal(false);
              }}
            >
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
