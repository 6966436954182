import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

const schema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().nullable(),
    image: yup.mixed().nullable(),
  })
  .required();
export default function UserAwardModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(null);
  const onSubmit = (data) => {
    data.year = moment(year.year + "-" + year.month + "-" + year.day).format(
      "yyyy-MM-DD"
    );
    var url = props.url_perfix + "awards_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "awards_update/" + props.currentModel.id;
    }
    let formData = new FormData();
    for (var prop in data) {
      if (prop === "image") {
        formData.append("image", data.image ? data.image[0] : null);
      } else {
        formData.append(prop, data[prop]);
      }
    }
    setLoading(true);
    axiosConfig
      .post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setYear(
      props?.currentModel.year && props?.currentModel.year !== "0000-00-00"
        ? {
            year: parseInt(moment(props?.currentModel.year).format("YYYY")),
            month: parseInt(moment(props?.currentModel.year).format("MM")),
            day: parseInt(moment(props?.currentModel.year).format("DD")),
          }
        : null
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          {createInputs("name", "Name", "Name")}

          <FormGroup>
            <label className="form-control-label" htmlFor={`input-start`}>
              Date
            </label>
            <br />
            <DatePicker
              value={year}
              onChange={setYear}
              shouldHighlightWeekends
              wrapperClassName="form-control"
              inputPlaceholder=" "
              calendarPopperPosition="bottom"
              formatInputText={() =>
                year
                  ? moment(
                      year.year + "-" + year.month + "-" + year.day
                    ).format("yyyy-MM-DD")
                  : ""
              }
            />
          </FormGroup>
          {createInputs(
            "description",
            "Description",
            "Description",
            "textarea"
          )}

          <FormGroup>
            <label className="form-control-label" htmlFor="input-image">
              Logo
            </label>
            <Input
              id="input-image"
              placeholder="Image"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("image", register)}
            />

            <FormText color="danger">{errors.image?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
