import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
// core components
import axiosConfig from "consts/axiosConfig";
import UserEducationComponent from "views/pages/User/UserProfile/Sections/Education/UserEducationComponent";
import UserAffiliationComponent from "views/pages/User/UserProfile/Sections/Affiliation/UserAffiliationComponent";
import UserAwardComponent from "views/pages/User/UserProfile/Sections/Award/UserAwardComponent";
import ContactInformationComponent from "views/pages/User/UserProfile/Sections/ProfileInformation/ContactInformationComponent";
import SocialMediaInformationComponent from "views/pages/User/UserProfile/Sections/ProfileInformation/SocialMediaInformationComponent";
import UserBarComponent from "views/pages/User/UserProfile/Sections/Bar/UserBarComponent";
import UserCaseHistoryComponent from "views/pages/User/UserProfile/Sections/CaseHistory/UserCaseHistoryComponent";
import UserCertificateComponent from "views/pages/User/UserProfile/Sections/Certificate/UserCertificateComponent";
import UserContactComponent from "views/pages/User/UserProfile/Sections/Contact/UserContactComponent";
import UserClientComponent from "views/pages/User/UserProfile/Sections/Client/UserClientComponent";
import UserExperienceComponent from "views/pages/User/UserProfile/Sections/Experience/UserExperienceComponent";
import UserMembershipComponent from "views/pages/User/UserProfile/Sections/Membership/UserMembershipComponent";
import UserTestimonialComponent from "views/pages/User/UserProfile/Sections/Testimonial/UserTestimonialComponent";
import UserLocationComponent from "views/pages/User/UserProfile/Sections/Location/UserLocationComponent";
import UserPersonnelComponent from "views/pages/User/UserProfile/Sections/Personnel/UserPersonnelComponent";
import UserBriefCard from "views/pages/User/UserProfile/Sections/ProfileCard/UserBriefCard";
import OrganizationAboutInformationComponent from "views/pages/User/UserProfile/Sections/ProfileInformation/OrganizationAboutInformationComponent";
import OrganizationProfileHeader from "components/Headers/OrganizationProfileHeader";
import OrganizationInvitationCard from "views/pages/User/UserProfile/Sections/ProfileInformation/OrganizationInvitationCard";
import OrganizationUsersComponent from "views/pages/User/UserProfile/Sections/OrganizationUsers/OrganizationUsersComponent";
function OrganizationProfile() {
  const isEditable = true;
  const url_perfix = "organization/";
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    setLoading(true);
    axiosConfig
      .get("organization")
      .then((res) => {
        setLoading(false);

        setUserData(res.data.result.organization);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const updateProfile = (data, toggleModal, setModalLoading) => {
    setModalLoading(true);
    axiosConfig
      .post("organization/update_basic_profile", data)
      .then((res) => {
        if (!res.data.hasError) {
          toggleModal(false);
          getProfile();
        }
        setModalLoading(false);
      })
      .catch((error) => {
        setModalLoading(false);
      });
  };

  if (loading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
      <OrganizationProfileHeader userData={userData} />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <UserBriefCard
              userData={userData}
              editable={isEditable}
              title="Change Background/Profile Photo"
              getProfile={getProfile}
              updateProfile={updateProfile}
              currentUser={true}
              url_perfix={url_perfix}
            />
            {/* <OrganizationInvitationCard
              userData={userData}
              editable={isEditable}
              currentUser={true}
              url_perfix={url_perfix}
            /> */}
            <OrganizationUsersComponent
              userData={userData}
              title="Members"
              getProfile={getProfile}
            />
            <UserEducationComponent
              getProfile={getProfile}
              userData={userData}
              title="Education"
              coudBeRendered={userData?.type?.education}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserAffiliationComponent
              getProfile={getProfile}
              userData={userData}
              title="Affiliations"
              coudBeRendered={userData?.type?.affiliations}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserAwardComponent
              getProfile={getProfile}
              userData={userData}
              title="Awards"
              coudBeRendered={userData?.type?.awards}
              editable={isEditable}
              url_perfix={url_perfix}
            />
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Profile Information</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <OrganizationAboutInformationComponent
                  userData={userData}
                  title="Basic Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
                <hr className="my-4" />
                <ContactInformationComponent
                  userData={userData}
                  title="Contact Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
                <hr className="my-4" />
                <SocialMediaInformationComponent
                  userData={userData}
                  title="Social Media Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
              </CardBody>
            </Card>
            <Row>
              <UserBarComponent
                getProfile={getProfile}
                userData={userData}
                title="State License"
                coudBeRendered={userData?.type?.bars}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserCaseHistoryComponent
                getProfile={getProfile}
                userData={userData}
                title="Case Histories"
                coudBeRendered={userData?.type?.case_histories}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserCertificateComponent
                getProfile={getProfile}
                userData={userData}
                title="Certificates"
                coudBeRendered={userData?.type?.certificates}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserContactComponent
                getProfile={getProfile}
                userData={userData}
                title="Contacts"
                coudBeRendered={userData?.type?.contacts}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserClientComponent
                getProfile={getProfile}
                userData={userData}
                title="Customers"
                coudBeRendered={userData?.type?.customers}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserMembershipComponent
                getProfile={getProfile}
                userData={userData}
                title="Memberships"
                coudBeRendered={userData?.type?.memberships}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserExperienceComponent
                getProfile={getProfile}
                userData={userData}
                title="Experiences"
                coudBeRendered={userData?.type?.experiences}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserTestimonialComponent
                getProfile={getProfile}
                userData={userData}
                title="Testimontals"
                coudBeRendered={userData?.type?.testimonials}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserPersonnelComponent
                getProfile={getProfile}
                userData={userData}
                title="Personnel"
                coudBeRendered={userData?.type?.personnels}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserLocationComponent
                getProfile={getProfile}
                userData={userData}
                title="Locations"
                coudBeRendered={userData?.type?.locations}
                editable={isEditable}
                url_perfix={url_perfix}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrganizationProfile;
