import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";

export const APP_ADMIN_URL = "https://api.360legalsoft.com/api/mln/";
// export const APP_ADMIN_URL = "http://127.0.0.1:8080/api/mln/";
export const APP_IMAGE_URL = "https://lsppublic.s3.amazonaws.com/";
export const registerRs = (fieldName, register) => {
  const registeredField = register(fieldName);
  const ref = registeredField.ref;
  delete registeredField.ref;
  return { ...registeredField, innerRef: ref };
};
export const lsToJson = () => {
  var account = localStorage.getItem("account");
  if (account) {
    return JSON.parse(localStorage.getItem("account"));
  }
  return null;
};
export const removeFromArray = (items, forDeletion) => {
  return items.filter((item) => item !== forDeletion);
};
export const sortWidgetItems = (
  url,
  setItems,
  newItems,
  getProfile,
  editable
) => {
  if (editable) {
    axiosConfig
      .post(url, newItems)
      .then((res) => {
        if (!res.data.hasError) {
          getProfile();
        }
      })
      .catch((error) => {});
    setItems(newItems);
  }
};
export const removeWidgetItem = (url, getProfile) => {
  axiosConfig
    .post(url)
    .then((res) => {
      if (!res.data.hasError) {
        getProfile();
        Swal.fire("Deleted!", "", "success");
      }
    })
    .catch((error) => {});
};
export const simpleWebRequest = (url, getProfile) => {
  axiosConfig
    .get(url)
    .then((res) => {
      if (!res.data.hasError) {
        getProfile();
      }
    })
    .catch((error) => {});
};
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return phoneNumberString;
  }
};
