import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import OverlayLoader from "consts/OverlayLoader";
import { currencyFormatter } from "consts/Constants";
import moment from "moment";
import AddEditVoucherModal from "./AddEditVoucherModal";
import Swal from "sweetalert2";
import { removeWidgetItem } from "consts/Constants";

const { SearchBar } = Search;

export default function AdminVouchers(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [orgTypes, setOrganizationTypes] = useState([]);
  const [currentModel, setCurrenModel] = useState({});

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const getItems = () => {
    setLoading(true);
    axiosConfig
      .get("refers/types")
      .then((res) => {
        setUserTypes(res.data.result.users);
        setOrganizationTypes(res.data.result.organizations);
      })
      .catch((error) => {});
    axiosConfig
      .get("admin/vouchers/index")
      .then((res) => {
        setLoading(false);
        setVouchers(res.data.result.vouchers);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);

  const removeItem = (id) => {
    removeWidgetItem("admin/vouchers/delete/" + id, getItems);
  };
  return (
    <>
      <ListHeader
        name="Vouchers"
        parentName="Dashboard"
        setShowModal={setShowModal}
        setCurrenModel={setCurrenModel}
      />
      <Container className="mt--6" fluid>
        <AddEditVoucherModal
          userTypes={userTypes}
          orgTypes={orgTypes}
          showModal={showModal}
          toggleModal={setShowModal}
          currentModel={currentModel}
          setCurrenModel={setCurrenModel}
          title={"Voucher"}
          getItems={getItems}
        />
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Vouchers</h3>
              </CardHeader>
              <ToolkitProvider
                data={vouchers}
                keyField="name"
                columns={[
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "code",
                    text: "Code",
                    sort: true,
                  },
                  {
                    dataField: "type",
                    text: "Type",
                    sort: true,
                    formatter: (cell, row) => {
                      return <span>{row.entity ? row.entity.name : ""}</span>;
                    },
                  },
                  {
                    dataField: "amount",
                    text: "Amount",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <span>
                          {row.type === "amount"
                            ? currencyFormatter.format(row.amount)
                            : row.amount}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: "limit",
                    text: "Limit",
                    sort: true,
                  },
                  {
                    dataField: "expires_at",
                    text: "Expiration",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <span>
                          {row.expires_at
                            ? moment(row.expires_at).format("yyyy-MM-DD")
                            : "-"}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: "name",
                    text: "Actions",
                    editable: true,
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <a
                            className="btn btn-info btn-sm"
                            onClick={() => {
                              setCurrenModel(row);
                              setShowModal(true);
                            }}
                          >
                            <i className="fa fa-pen" />
                          </a>
                          <a
                            className="btn btn-danger btn-sm"
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  removeItem(row.id);
                                }
                              })
                            }
                          >
                            <i className="fa fa-trash" />
                          </a>
                        </>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      hover={true}
                      noDataIndication={
                        loading ? (
                          <Spinner color="info" size="xl" />
                        ) : (
                          <p>No Vouchers yet!</p>
                        )
                      }
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
