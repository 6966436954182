import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  CardFooter,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { useWizard } from "react-use-wizard";
import axiosConfig from "consts/axiosConfig";
import { useHistory } from "react-router-dom";

import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import Swal from "sweetalert2";
export default function StepRefer(props) {
  const history = useHistory();
  const location = useHistory();
  const [loading, setLoading] = useState(false);
  const { previousStep, nextStep } = useWizard();
  const [notes, setNotes] = useState("");
  const [feeType, setFeeType] = useState("none");
  const [feeAmount, setFeeAmount] = useState("");
  const [expiresAt, setExpiresAt] = useState(null);

  const referCase = () => {
    if (expiresAt === null) {
      Swal.fire({
        position: "top",
        icon: "warning",
        title: "Please specify expration date for this referral!",
        showConfirmButton: false,
        toast: true,
        timer: 2500,
      });
      return false;
    }
    setLoading(true);
    axiosConfig
      .post("refers/refer/" + props.case_id, {
        entity_id: props.userType.entity_id,
        entity_type: props.userType.entity_type,
        notes: notes,
        list: JSON.stringify(props.userIds),
        refer_fee_type: feeType,
        refer_fee_amount: feeAmount,
        expires_at: moment(
          expiresAt.year + "-" + expiresAt.month + "-" + expiresAt.day
        ).format("yyyy-MM-DD"),
      })
      .then((res) => {
        if (!res.data.hasError) {
          history.push("out");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Card>
      <CardHeader>
        <h3 className="text-center">Who do you want to refer to?</h3>
      </CardHeader>
      {loading ? (
        <div className="text-center">
          <Spinner color="#000" />
        </div>
      ) : (
        <>
          <CardBody>
            {props.userType.entity_id === 1 &&
            JSON.parse(localStorage.getItem("account")).type_id === 1 ? (
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-feetype`}
                    >
                      Refer Fee Type
                    </label>
                    <Input
                      id={`input-feetype`}
                      placeholder="Fee type"
                      type="select"
                      value={feeType}
                      onChange={(e) => setFeeType(e.target.value)}
                    >
                      <option value="none">None</option>
                      <option value="percent">Percent</option>
                      <option value="amount">Amount</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-feeamount`}
                    >
                      Refer Fee Amount
                    </label>
                    <Input
                      id={`input-feeamount`}
                      placeholder="Fee amount"
                      type="text"
                      disabled={feeType === "none"}
                      value={feeAmount}
                      onChange={(e) => setFeeAmount(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-feetype`}
                    >
                      Refer Expiration Date
                    </label>
                    <DatePicker
                      value={expiresAt}
                      onChange={setExpiresAt}
                      shouldHighlightWeekends
                      wrapperClassName="form-control"
                      inputPlaceholder=" "
                      calendarPopperPosition="bottom"
                      formatInputText={() =>
                        expiresAt
                          ? moment(
                              expiresAt.year +
                                "-" +
                                expiresAt.month +
                                "-" +
                                expiresAt.day
                            ).format("yyyy-MM-DD")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-feetype`}
                    >
                      Refer Expiration Date
                    </label>
                    <DatePicker
                      value={expiresAt}
                      onChange={setExpiresAt}
                      shouldHighlightWeekends
                      wrapperClassName="form-control"
                      inputPlaceholder=" "
                      calendarPopperPosition="bottom"
                      formatInputText={() =>
                        expiresAt
                          ? moment(
                              expiresAt.year +
                                "-" +
                                expiresAt.month +
                                "-" +
                                expiresAt.day
                            ).format("yyyy-MM-DD")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-notes`}
                    >
                      Note
                    </label>
                    <Input
                      id={`input-notes`}
                      placeholder="Note"
                      type="textarea"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CardBody>
          <CardFooter>
            <div className="d-flex flex-row ai-center jc-space-between">
              <Button
                color="primary"
                size="md"
                onClick={() => {
                  previousStep();
                }}
              >
                Previous
              </Button>
              <div>
                <Button color="info" size="md" onClick={() => referCase()}>
                  Skip and Continue
                </Button>
                <Button color="info" size="md" onClick={() => referCase()}>
                  Finish
                </Button>
              </div>
            </div>
          </CardFooter>
        </>
      )}
    </Card>
  );
}
