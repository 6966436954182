import { APP_IMAGE_URL } from "consts/Constants";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Row,
} from "reactstrap";
import ChangeAvatarCoverModal from "./ChangeAvatarCoverModal";
import axiosConfig from "consts/axiosConfig";
import InviteUsersToApp from "../ProfileInformation/UserEditModals/InviteUsersToApp";

export default function UserBriefCard(props) {
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  var avatarOrLogo = require("assets/img/user.webp");
  if (props.userData?.avatar) {
    avatarOrLogo = APP_IMAGE_URL + props.userData.avatar;
  } else if (props.userData?.logo) {
    avatarOrLogo = APP_IMAGE_URL + props.userData.logo;
  }
  const requestFollowUser = () => {
    setLoadingFollow(true);
    axiosConfig
      .get(
        "network/follow_request/" + props.url_perfix + "/" + props.userData.id
      )
      .then((res) => {
        setLoadingFollow(false);
        props.getProfile();
      })
      .catch((error) => {
        setLoadingFollow(false);
      });
  };
  const endorseUser = () => {
    setLoadingFollow(true);
    axiosConfig
      .get("network/endorse/" + props.url_perfix + "/" + props.userData.id)
      .then((res) => {
        setLoadingFollow(false);
        props.getProfile();
      })
      .catch((error) => {
        setLoadingFollow(false);
      });
  };
  const unEndorseUser = () => {
    setLoadingFollow(true);
    axiosConfig
      .get("network/unendorse/" + props.url_perfix + "/" + props.userData.id)
      .then((res) => {
        setLoadingFollow(false);
        props.getProfile();
      })
      .catch((error) => {
        setLoadingFollow(false);
      });
  };
  const cancelFollowRequest = () => {
    setLoadingFollow(true);
    axiosConfig
      .get(
        "network/cancel_request/" + props.url_perfix + "/" + props.userData.id
      )
      .then((res) => {
        setLoadingFollow(false);
        props.getProfile();
      })
      .catch((error) => {
        setLoadingFollow(false);
      });
  };
  const unFollowUser = () => {
    setLoadingFollow(true);
    axiosConfig
      .get("network/unfollow/" + props.url_perfix + "/" + props.userData.id)
      .then((res) => {
        setLoadingFollow(false);
        props.getProfile();
      })
      .catch((error) => {
        setLoadingFollow(false);
      });
  };
  const isEndrosed = () => {
    if (props.following)
      if (props.endorsed) {
        return (
          <Button
            className="float-right"
            color="danger"
            disabled={loadingFollow}
            onClick={(e) => unEndorseUser()}
            size="sm"
          >
            Unendorse
          </Button>
        );
      } else {
        return (
          <Button
            className="float-right"
            color="success"
            disabled={loadingFollow}
            onClick={(e) => endorseUser()}
            size="sm"
          >
            Endorse
          </Button>
        );
      }
  };
  const renderFollowSection = () => {
    if (props.currentUser || props.url_perfix !== "user") {
      return <></>;
    } else {
      if (props.following) {
        return (
          <div className="d-flex justify-content-between">
            <Button
              className="float-right"
              color="default"
              disabled={loadingFollow}
              onClick={(e) => unFollowUser()}
              size="sm"
            >
              Disconnect
            </Button>
            {isEndrosed()}
          </div>
        );
      } else if (!props.following && props.followRequest === 1) {
        return (
          <div className="d-flex justify-content-between">
            <Button
              className="mr-4"
              color="secondary"
              disabled={loadingFollow}
              onClick={(e) => cancelFollowRequest()}
              size="sm"
            >
              Requested
            </Button>
            {isEndrosed()}
          </div>
        );
      } else {
        return (
          <div className="d-flex justify-content-between">
            <Button
              className="mr-4"
              color="info"
              disabled={loadingFollow}
              onClick={(e) => requestFollowUser()}
              size="sm"
            >
              Connect
            </Button>
            {isEndrosed()}
          </div>
        );
      }
    }
  };
  return (
    <>
      <ChangeAvatarCoverModal
        userData={props.userData}
        showModal={showModal}
        toggleModal={setShowModal}
        title={props.title}
        getProfile={props.getProfile}
        updateProfile={props.updateProfile}
        url_perfix={props.url_perfix}
      />
      <Card className="card-profile">
        <div className="edit-profile-cover" onClick={() => setShowModal(true)}>
          {props.editable ? (
            <div className="change-cover">
              <i className="fa fa-camera" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <CardImg
          alt="..."
          src={
            props.userData?.cover
              ? APP_IMAGE_URL + props.userData?.cover
              : require("assets/img/theme/img-1-1000x600.jpg")
          }
        />
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <img alt="..." className="rounded-circle" src={avatarOrLogo} />
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          {renderFollowSection()}
        </CardHeader>
        <CardBody className={props.currentUser ? "mt-3" : ""}>
          <div className="card-profile-stats d-flex flex-column justify-content-center ai-center">
            <span className="heading">{props.userData?.following_count}</span>
            <span className="description text-muted">
              {props.currentUser ? "My Network" : "Network"}
            </span>
          </div>

          {props.currentUser ? (
            <div className="w-100 d-flex jc-center ai-center my-3">
              <InviteUsersToApp
                toggleModal={setShowInviteModal}
                showModal={showInviteModal}
                title="Invite"
              />
              <Button
                color="link"
                size="md"
                className="text-center"
                onClick={() => setShowInviteModal(true)}
              >
                Invite to Lien Networks
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div
            className={`text-center ${
              props.url_perfix !== "user" ? "mt-4" : ""
            }`}
          >
            <h5 className="h3">
              {props.userData?.firstname
                ? props.userData.firstname + " " + props.userData.lastname
                : props.userData.name}
            </h5>
            <div className="h5 font-weight-300">
              <i className="ni location_pin mr-2" />
              {props.userData.city ? props.userData.city : ""}
              {", "}
              {props.userData?.country ? props.userData.country : ""}
            </div>
            <div className="h5 mt-4">
              <i className="ni business_briefcase-24 mr-2" />
              {props.userData.specialty ? props.userData.specialty : ""}
            </div>
            <div>
              <i className="ni education_hat mr-2" />
              {props.userData.tagline ? props.userData.tagline : ""}
            </div>
            {props.currentUser && props.userData.invite_code ? (
              <div className="text-center mt-4 mb-4">
                <p className="text-muted">
                  Your members could join your organization using this code
                  <h3 className="mt-2">{props.userData.invite_code}</h3>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
}
