import React, { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Spinner, Table } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import AdminImportModal from "./AdminImportModal";
import axiosConfig from "consts/axiosConfig";
import moment from "moment";

export default function AdminImports(props) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const getItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get("admin/imports")
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.imports);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  const renderUsers = () => {
    if (users) {
      return users.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>{tr.filename}</td>
            <td>{tr.status}</td>
            <td>{tr.success}</td>
            <td>{tr.failed}</td>
            <td>{tr.funnel_status}</td>
            <td>{moment(tr.created_at).format("YYYY-MM-DD")}</td>
          </tr>
        );
      });
    }
  };
  return (
    <>
      <ListHeader
        name="Imports"
        parentName="Dashboard"
        setShowModal={setShowModal}
      />
      <AdminImportModal
        showModal={showModal}
        toggleModal={setShowModal}
        title={"Import Users"}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Imports</h3>
              </CardHeader>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>STATUS</th>
                    <th>SUCCESS</th>
                    <th>FAILED</th>
                    <th>FUNNEL STATUS</th>
                    <th>UPLOADED AT</th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>
              {loading ? (
                <div className="d-flex flex-row jc-center ai-center m-3">
                  <Spinner color="info" size="xl" />
                </div>
              ) : users.length === 0 ? (
                <p className="text-center m-3">No items yet!</p>
              ) : (
                <></>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
