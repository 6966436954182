import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import moment from "moment";

const schema = yup
  .object({
    title: yup.string().required(),
    name: yup.string().nullable(),
    active: yup.boolean().required(),
    description: yup.string().nullable(),
    image: yup.mixed().nullable(),
  })
  .required();
export default function UserMembershipModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onSubmit = (data) => {
    data.start = moment(
      startDate.year + "-" + startDate.month + "-" + startDate.day
    ).format("yyyy-MM-DD");
    data.end = moment(
      endDate.year + "-" + endDate.month + "-" + endDate.day
    ).format("yyyy-MM-DD");
    var url = props.url_perfix + "memberships_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "memberships_update/" + props.currentModel.id;
    }
    let formData = new FormData();
    for (var prop in data) {
      if (prop === "image") {
        console.log(data);
        formData.append("image", data.image ? data.image[0] : null);
      } else {
        formData.append(prop, data[prop]);
      }
    }
    setLoading(true);
    axiosConfig
      .post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setStartDate(
      props?.currentModel.start && props?.currentModel.start !== "0000-00-00"
        ? {
            year: parseInt(moment(props?.currentModel.start).format("YYYY")),
            month: parseInt(moment(props?.currentModel.start).format("MM")),
            day: parseInt(moment(props?.currentModel.start).format("DD")),
          }
        : null
    );
    setEndDate(
      props?.currentModel.end && props?.currentModel.end !== "0000-00-00"
        ? {
            year: parseInt(moment(props?.currentModel.end).format("YYYY")),
            month: parseInt(moment(props?.currentModel.end).format("MM")),
            day: parseInt(moment(props?.currentModel.end).format("DD")),
          }
        : null
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          <Row>
            <Col>{createInputs("title", "Title", "Title")}</Col>
            <Col>{createInputs("name", "Name", "Name")}</Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Start from
                </label>
                <DatePicker
                  value={startDate}
                  onChange={setStartDate}
                  shouldHighlightWeekends
                  wrapperClassName="form-control"
                  inputPlaceholder=" "
                  calendarPopperPosition="bottom"
                  formatInputText={() =>
                    startDate
                      ? moment(
                          startDate.year +
                            "-" +
                            startDate.month +
                            "-" +
                            startDate.day
                        ).format("yyyy-MM-DD")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Until
                </label>
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}
                  shouldHighlightWeekends
                  wrapperClassName="form-control"
                  inputPlaceholder=" "
                  calendarPopperPosition="bottom"
                  formatInputText={() =>
                    endDate
                      ? moment(
                          endDate.year + "-" + endDate.month + "-" + endDate.day
                        ).format("yyyy-MM-DD")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          {createInputs(
            "description",
            "Description",
            "Description",
            "textarea"
          )}

          <FormGroup>
            <label className="form-control-label" htmlFor="input-active">
              Active
            </label>
            <Input
              id="input-active"
              type="select"
              disabled={loading}
              {...registerRs("active", register)}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Input>
            <FormText color="danger">{errors.active?.message}</FormText>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-image">
              Image
            </label>
            <Input
              id="input-image"
              placeholder="image"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("image", register)}
            />

            <FormText color="danger">{errors.image?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
