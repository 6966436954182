import React, { useEffect, useState } from "react";
import axiosConfig from "consts/axiosConfig";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useWizard } from "react-use-wizard";
import { APP_IMAGE_URL } from "consts/Constants";
import { useHistory } from "react-router-dom";

export default function CPStepTwo(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [joinOrCreate, setJoinOrCreate] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const { nextStep, previousStep } = useWizard();
  const joinOrganization = () => {
    setLoading(true);
    axiosConfig
      .post("join_organization", { invite_code: inviteCode })
      .then((res) => {
        if (!res.data.hasError) {
          history.push("/user/dashboard");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Card className="bg-secondary border-0">
      <CardBody>
        <h3 className="text-center">
          Are you member of a{" "}
          {props.orgType === "Lawfirm" ? "Law Firm" : props.orgType}?
        </h3>
        <br />
        <div className="d-flex flex-row jc-center">
          <Button
            color="primary"
            onClick={() => {
              history.push("/user/profile");
            }}
          >
            No
          </Button>
          <Button color="primary" onClick={() => nextStep(true)}>
            Yes
          </Button>
        </div>
      </CardBody>
      <CardFooter>
        <Button color="info" onClick={() => previousStep()} disabled={loading}>
          Back
        </Button>
      </CardFooter>
    </Card>
  );
}
