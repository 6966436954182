import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import { useWizard } from "react-use-wizard";
import Swal from "sweetalert2";

export default function StepPlan(props) {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const { handleStep, previousStep, nextStep } = useWizard();

  const submitQuery = () => {
    if (quantity && props.plan) {
      var url = "user/powerups/store";
      setLoading(true);
      axiosConfig
        .post(url, {
          area_id: props.areaId,
          state: props.stateName,
          slot: props.resQuery.slot,
          start_date: props.resQuery.start_date,
          plan: props.plan,
          quantity: quantity,
        })
        .then((res) => {
          if (!res.data.hasError) {
            props.setResPowerUp(res.data.result);
            nextStep();
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      if (props.plan) {
        Swal.fire({
          position: "top",
          icon: "error",
          title: "Quantity could not be empty!",
          showConfirmButton: false,
          toast: true,
          timer: 2500,
        });
      } else {
        Swal.fire({
          position: "top",
          icon: "error",
          title: "Please select a plan to continue!",
          showConfirmButton: false,
          toast: true,
          timer: 2500,
        });
      }
    }
  };
  const renderPrice = (type, price, discount) => {
    if (discount > 0) {
      return (
        <>
          <span>{type}</span>
          <span className="price-before pr-2 pl-2">${price + discount}</span>
          <span className="price-after">${price}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{type}</span>
          <span className="price-after pl-2">${price}</span>
        </>
      );
    }
  };
  const renderPlans = () => {
    if (props.resQuery.prices) {
      return (
        <>
          <Col lg={4} className="mt-4 mb-6">
            <Button
              block
              color={props.plan === "daily" ? "info" : "secondary"}
              onClick={() => props.setPlan("daily")}
            >
              {renderPrice(
                "Daily",
                props.resQuery.prices.daily.price,
                props.resQuery.prices.daily.discount
              )}
            </Button>
          </Col>

          <Col lg={4} className="mt-4 mb-6">
            <Button
              block
              color={props.plan === "monthly" ? "info" : "secondary"}
              onClick={() => props.setPlan("monthly")}
            >
              {renderPrice(
                "Monthly",
                props.resQuery.prices.monthly.price,
                props.resQuery.prices.monthly.discount
              )}
            </Button>
          </Col>

          <Col lg={4} className="mt-4 mb-6">
            <Button
              block
              color={props.plan === "yearly" ? "info" : "secondary"}
              onClick={() => props.setPlan("yearly")}
            >
              {renderPrice(
                "Annually",
                props.resQuery.prices.yearly.price,
                props.resQuery.prices.yearly.discount
              )}
            </Button>
          </Col>
        </>
      );
    }
  };
  const renderQuantityLabel = () => {
    if (props.plan === "daily") {
      return "How many days would like to be preferred?";
    } else if (props.plan === "monthly") {
      return "How many months would like to be preferred?";
    } else {
      return "How many years would like to be preferred?";
    }
  };
  const renderQuantity = () => {
    if (props.plan) {
      return (
        <>
          <Col lg={2} className="mb-4"></Col>
          <Col lg={8} className="mb-4">
            <FormGroup className="text-center">
              <label className="form-control-label">
                {renderQuantityLabel()}
              </label>
              <Input
                placeholder="Quantity"
                type="number"
                disabled={loading}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col lg={2} className="mb-4"></Col>
        </>
      );
    }
  };
  return (
    <Row>
      <Col lg={12} className="text-center">
        <h3 className="text-center">{props.resQuery.message}</h3>
        <br />
        <span className="mt-4">Please select a plan</span>
      </Col>
      {renderPlans()}
      {renderQuantity()}

      <Col lg={6}>
        <Button
          disabled={loading}
          color="secondary"
          type="button"
          onClick={() => props.toggleModal()}
        >
          Discard
        </Button>
      </Col>
      <Col lg={6}>
        <div className="float-right">
          <Button
            disabled={loading}
            color="info"
            type="button"
            onClick={() => previousStep()}
          >
            Back
          </Button>
          <Button
            disabled={loading}
            color="primary"
            type="button"
            onClick={() => submitQuery()}
          >
            Next
          </Button>
        </div>
      </Col>
    </Row>
  );
}
