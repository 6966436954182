import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { removeFromArray } from "consts/Constants";
import MapPicker from "react-google-map-picker";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import axios from "axios";
const DefaultZoom = 10;

const schema = yup
  .object({
    name: yup.string().required(),
    website: yup.string().nullable(),
    address: yup.string().nullable(),
    zipcode: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
  })
  .required();
export default function UserLocationModal(props) {
  const [defaultLocation, setDefaultLocation] = useState({});
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [showMap, setShowMap] = useState(false);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const onSubmit = (data) => {
    var url = props.url_perfix + "locations_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "locations_update/" + props.currentModel.id;
    }
    data.phones = phones.length ? JSON.stringify(phones) : null;
    data.emails = emails.length ? JSON.stringify(emails) : null;
    if (location.lat) {
      data.lat = location.lat;
      data.lng = location.lng;
    }
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          setShowMap(false);
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setPhones(
      props.currentModel.phones ? JSON.parse(props.currentModel.phones) : []
    );
    setEmails(
      props.currentModel.emails ? JSON.parse(props.currentModel.emails) : []
    );

    if (props?.currentModel.lat) {
      setDefaultLocation({
        lat: parseFloat(props?.currentModel.lat),
        lng: parseFloat(props?.currentModel.lng),
      });
      setLocation({
        lat: parseFloat(props?.currentModel.lat),
        lng: parseFloat(props?.currentModel.lng),
      });
    } else {
      setDefaultLocation({
        lat: 33.836889278534,
        lng: -117.4841168417,
      });
    }
    const s = watch((_, { name, type }) => {
      if (name === "zipcode") {
        if (watch("zipcode").length === 5) {
          getAddress();
        }
      }
    });
    setTimeout(() => {
      setShowMap(true);
    }, 1000);
  }, [props.currentModel, reset, setDefaultLocation, setLocation]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };

  const addPhoneSwal = async () => {
    const { value: phone } = await Swal.fire({
      title: "Phone",
      input: "text",
      inputLabel: "Phone Number",
      inputPlaceholder: "Enter...",
    });
    if (phone) {
      setPhones([...phones, phone]);
    }
  };
  const addEmailSwal = async () => {
    const { value: email } = await Swal.fire({
      title: "Email",
      input: "email",
      inputLabel: "Email Address",
      inputPlaceholder: "Enter...",
    });
    if (email) {
      setEmails([...emails, email]);
    }
  };
  const removeItem = (item, setNewItem, items) => {
    setNewItem(removeFromArray(items, item));
  };
  const renderItems = (items, setNewItem) => {
    if (items) {
      return items.map((item) => {
        return (
          <div
            className="d-flex flex-row jc-space-between ai-center multiple-tag"
            key={item}
          >
            <span>{item}</span>
            <i
              className="fa fa-times"
              onClick={() => removeItem(item, setNewItem, items)}
            />
          </div>
        );
      });
    }
  };
  const getAddress = () => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${watch(
          "zipcode"
        )}&key=AIzaSyDLmGjR-Xn0EZOyp1qF65vQ1an0AhlbjtE`
      )
      .then((response) => {
        if (response.data.status == "OK") {
          setDefaultLocation({
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          });
          setLocation({
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          });
        }
      })
      .catch((e) => (this.error = e.response.data.errors ?? e.response.data));
  };
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <Col>{createInputs("name", "Name", "Name")}</Col>
            <Col>{createInputs("website", "Website", "Website")}</Col>
          </Row>
          <Row>
            <Col>{createInputs("country", "Country", "Country")}</Col>
            <Col>
              <FormGroup>
                <label className="form-control-label">State</label>
                <Controller
                  control={control}
                  name={"state"}
                  render={({ field }) => (
                    <Select2
                      onChange={(id) => field.onChange(id)}
                      value={field.value}
                      className="form-control"
                      options={{
                        placeholder: "State",
                      }}
                      data={
                        states
                          ? states.map((cl) => {
                              return {
                                id: cl.name,
                                text: cl.name,
                              };
                            })
                          : []
                      }
                    />
                  )}
                />
                <FormText color="danger">{errors.state?.message}</FormText>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{createInputs("city", "City", "City")}</Col>
            <Col>{createInputs("zipcode", "Zip Code", "Zip Code")}</Col>
          </Row>
          <Row>
            <Col>{createInputs("address", "Address", "Address")}</Col>
          </Row>
          <FormGroup>
            <label className="form-control-label">Phone</label>
            <Button
              size="sm"
              color="link"
              className="ml-2"
              onClick={() => {
                addPhoneSwal();
              }}
            >
              Add Phone
            </Button>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(phones, setPhones)}
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">Email</label>
            <Button
              size="sm"
              color="link"
              className="ml-2"
              onClick={() => {
                addEmailSwal();
              }}
            >
              Add Email
            </Button>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(emails, setEmails)}
            </div>
          </FormGroup>
          {showMap ? (
            <MapPicker
              defaultLocation={defaultLocation}
              zoom={zoom}
              mapTypeId="roadmap"
              style={{ height: "700px" }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey="AIzaSyDl61ZWwYqoQkF-3Rtr3hgLDGDn9cPKx_0"
            />
          ) : (
            <Button color="link" onClick={() => setShowMap(true)}>
              Select location on map
            </Button>
          )}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setShowMap(false);
                props.toggleModal(false);
              }}
            >
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
