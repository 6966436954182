import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { formatPhoneNumber } from "consts/Constants";

export default function ShowClientModal(props) {
  const [customFields, setCustomFields] = useState([]);
  const getCustomFields = () => {
    var url = props.url_perfix + "create";
    if (props.currentModel?.id) {
      url = props.url_perfix + "show/" + props.currentModel.id;
    }
    axiosConfig
      .get(url)
      .then((res) => {
        setCustomFields(res.data.result.custom_fields);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getCustomFields();
  }, []);
  const renderItems = (items, isPhone = false) => {
    if (items) {
      items = items;
    } else {
      return <span>No items</span>;
    }
    if (items.length) {
      return items.map((item) => {
        return (
          <div
            className="d-flex flex-row jc-space-between ai-center multiple-tag"
            key={item}
          >
            <span>{isPhone ? formatPhoneNumber(item) : item}</span>
          </div>
        );
      });
    }
  };
  const renderStaticData = (key, label) => {
    return (
      <FormGroup>
        <label>
          <h4 className="fs-14">{label}</h4>
          {props.currentModel?.[key]}
        </label>
      </FormGroup>
    );
  };
  const renderCustomFields = () => {
    if (customFields.length && props.currentModel?.cf_data) {
      return customFields.map((cf) => {
        if (cf.type === "text") {
          return (
            <Col key={`element-${cf.id}`} lg={6}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {props.currentModel?.cf_data[`element-${cf.id}`]}
                </label>
              </FormGroup>
            </Col>
          );
        } else if (cf.type === "textarea") {
          return (
            <Col key={`element-${cf.id}`} lg={12}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {props.currentModel?.cf_data[`element-${cf.id}`]}
                </label>
              </FormGroup>
            </Col>
          );
        } else if (cf.type === "checkbox") {
          return (
            <Col key={`element-${cf.id}`} lg={6}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {props.currentModel?.cf_data[`element-${cf.id}`]}
                </label>
              </FormGroup>
            </Col>
          );
        } else if (cf.type === "radio") {
          return (
            <Col key={`element-${cf.id}`} lg={6}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {props.currentModel?.cf_data[`element-${cf.id}`]}
                </label>
              </FormGroup>
            </Col>
          );
        } else if (cf.type === "select") {
          return (
            <Col key={`element-${cf.id}`} lg={6}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {props.currentModel?.cf_data[`element-${cf.id}`]}
                </label>
              </FormGroup>
            </Col>
          );
        } else if (cf.type === "date") {
          return (
            <Col key={`element-${cf.id}`} lg={6}>
              <FormGroup>
                <label>
                  <h4 className="fs-14">{cf.name}</h4>
                  {
                    moment(
                      props.currentModel?.cf_data[`element-${cf.id}`]
                    ).format("yyyy-MM-DD")[`element-${cf.id}`]
                  }
                </label>
              </FormGroup>
            </Col>
          );
        }
      });
    }
  };
  return (
    <Modal
      isOpen={props.showModal}
      size="xl"
      className="h3-class"
      toggle={() => props.toggleModal(false)}
    >
      <ModalHeader>
        {props.currentModel
          ? props.currentModel?.firstname + " " + props.currentModel?.lastname
          : "New Client"}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>{renderStaticData("firstname", "First Name")}</Col>
          <Col>{renderStaticData("lastname", "Last Name")}</Col>
        </Row>
        <FormGroup>
          <label className="form-control-label">Phone</label>
          <br />
          <div className="d-flex flex-row flex-wrap">
            {renderItems(props.currentModel["phones"], true)}
          </div>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label">Email</label>
          <br />
          <div className="d-flex flex-row flex-wrap">
            {renderItems(props.currentModel["emails"])}
          </div>
        </FormGroup>
        {renderStaticData("address", "Address")}
        <Row>
          <Col>{renderStaticData("city", "City")}</Col>
          <Col>{renderStaticData("state", "State")}</Col>
          <Col>{renderStaticData("country", "Country")}</Col>
          <Col>{renderStaticData("zipcode", "Zip Code")}</Col>
        </Row>
        {renderStaticData("notes", "Notes")}
        <Row>{renderCustomFields()}</Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => props.toggleModal(false)}>
          Dismiss
        </Button>
      </ModalFooter>
    </Modal>
  );
}
