import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useWizard, Wizard } from "react-use-wizard";
import CPStepFive from "./Steps/CPStepFive";
import CPStepFour from "./Steps/CPStepFour";
import CPStepOne from "./Steps/CPStepOne";
import CPStepThree from "./Steps/CPStepThree";
import CPStepTwo from "./Steps/CPStepTwo";

export default function RegisterWizardController(props) {
  const [orgType, setOrgType] = useState("");
  const [isCreate, setIsCreate] = useState("");

  return (
    <Wizard>
      <CPStepOne setOrgType={setOrgType} />
      <CPStepTwo orgType={orgType} />
      <CPStepThree
        orgType={orgType}
        isCreate={isCreate}
        setIsCreate={setIsCreate}
      />
      <CPStepFour
        orgType={orgType}
        isCreate={isCreate}
        setIsCreate={setIsCreate}
      />
    </Wizard>
  );
}
