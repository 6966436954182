import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";

const schema = yup
  .object({
    avatar: yup.mixed().nullable(),
    cover: yup.mixed().nullable(),
  })
  .required();
export default function ChangeAvatarCoverModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    var formData = new FormData();
    if (data.avatar) {
      if (props.userData?.name) {
        formData.append("logo", data.avatar ? data.avatar[0] : null);
      } else {
        formData.append("avatar", data.avatar ? data.avatar[0] : null);
      }
    }
    if (data.cover) {
      formData.append("cover", data.cover ? data.cover[0] : null);
    }
    var url = "update_images_profile";
    if (props.url_perfix === "organization/") {
      url = props.url_perfix + "update_images_profile";
    }
    axiosConfig
      .post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props.userData);
  }, [props.userData, reset]);

  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-cover">
              Background Photo
            </label>
            <Input
              id="input-cover"
              placeholder="Cover"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("cover", register)}
            />

            <FormText color="danger">{errors.cover?.message}</FormText>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-avatar">
              Profile Photo
            </label>
            <Input
              id="input-avatar"
              placeholder="Avatar"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("avatar", register)}
            />

            <FormText color="danger">{errors.avatar?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
