import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import { Lang } from "consts/localization";

const schema = yup
  .object({
    file: yup.mixed().required(Lang.emptyFieldError),
    category: yup.string().required(),
    notes: yup.string().nullable(),
  })
  .required();
export default function FilesUploadModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    var url = "organization/cases/file/" + props.case_id;
    setLoading(true);
    var formData = new FormData();
    formData.append("category", data.category);
    formData.append("notes", data.notes);
    if (data.file) {
      formData.append("file", data.file ? data.file[0] : null);
    }
    axiosConfig
      .post(url, formData)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getItem();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup key={name}>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>{props.title}</ModalHeader>
      {loading ? (
        <div className="d-flex flex-row jc-center ai-center m-4">
          <Spinner />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <label className="form-control-label">
                    {props.serviceName ? props.serviceName : "Category"}{" "}
                  </label>
                  <Input
                    type="select"
                    {...registerRs("category", register)}
                    disabled={loading}
                  >
                    <option value="Medical Records">Medical Records</option>
                    <option value="Medical Bills">Medical Bills</option>
                    <option value="Other">Other</option>
                  </Input>
                  <FormText color="danger">{errors.category?.message}</FormText>
                </FormGroup>
              </Col>
              <Col lg={12}>
                {createInputs("notes", "Notes", "Notes", "textarea")}
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    File
                  </label>
                  <Input
                    id="input-file"
                    placeholder="file"
                    type="file"
                    disabled={loading}
                    {...registerRs("file", register)}
                  />

                  <FormText color="danger">{errors.file?.message}</FormText>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          {loading ? (
            <ModalFooter>
              <div
                className="d-flex ai-center jc-center"
                style={{ width: "100%" }}
              >
                <Spinner animation="border" />
              </div>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => props.toggleModal(false)}
              >
                Discard
              </Button>
              <Button disabled={loading} color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Modal>
  );
}
