import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { removeWidgetItem } from "consts/Constants";
import Swal from "sweetalert2";
import AddEditCaseModal from "./AddEditCaseModal";
import { useLocation } from "react-router-dom";

const { SearchBar } = Search;

export default function OrganizationCases(props) {
  const location = useLocation();
  const url_postfix = "index";
  const [loading, setLoading] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [data, setData] = useState([]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                onSizePerPageChange(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const getItems = () => {
    setLoading(true);
    axiosConfig
      .get("organization/cases/" + url_postfix)
      .then((res) => {
        setLoading(false);
        setData(res.data.result.cases);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const removeItem = (id) => {
    removeWidgetItem("organization/cases/delete/" + id, getItems);
  };
  useEffect(() => {
    getItems();
  }, []);
  const newItemModal = () => {
    setShowAddEditModal(true);
  };
  return (
    <>
      <ListHeader
        name="Cases"
        parentName="Dashboard"
        setShowModal={newItemModal}
        setCurrentModel={null}
      />
      <Container className="mt--6" fluid>
        {showAddEditModal ? (
          <AddEditCaseModal
            showModal={showAddEditModal}
            toggleModal={setShowAddEditModal}
            url_postfix={url_postfix}
          />
        ) : (
          <></>
        )}
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Cases</h3>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                  },
                  {
                    dataField: "date",
                    text: "Date",
                    sort: true,
                  },
                  {
                    dataField: "id",
                    text: "Actions",
                    editable: true,
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <a
                            className="btn btn-info btn-sm"
                            href={location.pathname + "/" + row.id}
                          >
                            <i className="fa fa-eye" />
                          </a>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() =>
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  removeItem(row.id);
                                }
                              })
                            }
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      noDataIndication={
                        loading ? (
                          <Spinner color="info" size="xl" />
                        ) : (
                          <div>
                            <p>No Cases yet!</p>
                            <a
                              className="btn btn-link text-muted font-italic"
                              onClick={() => {
                                setShowAddEditModal(true);
                              }}
                            >
                              Start by adding to your cases
                            </a>
                          </div>
                        )
                      }
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
