import React, { useCallback, useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  FormText,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { APP_ADMIN_URL } from "consts/Constants";
import { useHistory } from "react-router-dom";
import { registerRs } from "consts/Constants";
import AuthLayout from "../AuthLayout";
const schema = yup
  .object({
    username: yup.string().required(Lang.emptyFieldError),
    otp: yup.string(),
  })
  .required();

function OtpLogin() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [otpField, setOtpField] = useState(false);
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    var url = APP_ADMIN_URL;
    var formData = new FormData();
    if (otpField && watch("otp")) {
      url = url + "otp_verify";
      formData.append("phone", data.username);
      formData.append("code", data.otp);
      otpVerify(url, formData);
    } else {
      url = url + "otp_send";
      formData.append("phone", data.username);
      formData.append("lawfirm_id", 1);
      otpRequest(url, formData);
    }
    setLoading(true);
  };
  const otpRequest = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.result.next === "otp_verify") {
          setTimer(60);
          setOtpField(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const otpVerify = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (!res.data.hasError) {
          localStorage.setItem("token", `Bearer ${res.data.result.token}`);
          localStorage.setItem("firstname", res.data.result.data.firstname);
          localStorage.setItem("lastname", res.data.result.data.lastname);
          localStorage.setItem("account", JSON.stringify(res.data.result.data));
          if (res.data.result.data.is_admin) {
            history.push("/admin/dashboard");
          } else {
            history.push("/user/profile");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const resetTimer = function () {
    if (timer <= 0) {
      setTimer(60);
      setOtpField(false);
      onSubmit({ username: watch("username") });
    }
  };
  useEffect(() => {}, []);

  return (
    <AuthLayout>
      <Col lg={7} md={12}>
        <div className="d-flex flex-column ai-center jc-center h-100 w-100">
          <img
            src={require("./../../../../../assets/img/LN-logo.png")}
            height={150}
            alt="Lien Networks"
            className="mt-4"
          />
          <h1 className="heading-color mt-5">Login Without Password</h1>
          <p className="text-center heading-color my-3 w-60">
            Welcome to Lien Networks!
            <br />
            Connect with medical and legal professionals nationwide and unlock
            endless possibilities.
          </p>
          <Form
            role="form"
            onSubmit={handleSubmit(onSubmit)}
            className="d-block w-60"
          >
            <FormGroup className="mb-2">
              <Input
                placeholder="Enter your email or phone number"
                type="text"
                disabled={loading || otpField}
                {...registerRs("username", register)}
              />
              <FormText color="danger">{errors.username?.message}</FormText>
            </FormGroup>
            {otpField ? (
              <FormGroup className="mb-2">
                <span className="text-muted fs14 mb-3 block">
                  We’ve sent you an email with a 4-digit code, please check your
                  inbox
                </span>

                <Input
                  placeholder="4 Digits"
                  type="text"
                  name="otp"
                  disabled={loading || !otpField}
                  {...registerRs("otp", register)}
                />
                <FormText color="danger">{errors.otp?.message}</FormText>
              </FormGroup>
            ) : (
              <></>
            )}

            <FormGroup>
              <Button block color="info" type="submit" disabled={loading}>
                {loading ? "Request login..." : "Request login"}
              </Button>
            </FormGroup>
            {/* {otpField && timer >= 0 ? (
              <div className="text-center">
                <Button
                  className="my-4"
                  color="link"
                  type="submit"
                  onClick={resetTimer}
                  disabled={loading}
                >
                  {timer === 0
                    ? "Request code again"
                    : `Request code in (${timer})s later`}
                </Button>
              </div>
            ) : (
              <></>
            )} */}
          </Form>
        </div>
      </Col>
    </AuthLayout>
  );
}

export default OtpLogin;
