import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import UserAddButtonWidget from "../Components/UserAddButtonWidget";
import UserPracticesModal from "./UserPracticesModal";

export default function UserPracticesComponent(props) {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentModel, setCurrenModel] = useState({});

  useEffect(() => {
    setItems(props.userData.practice_areas);
  }, [props.userData.practice_areas]);
  const renderAreas = () => {
    return items.map((area) => {
      return (
        <span className="btn-default m-1 px-2 py-1 rounded" key={area.id}>
          {area.name}
        </span>
      );
    });
  };
  if (!props.coudBeRendered) {
    return;
  }
  return (
    <>
      {props.editable ? (
        <UserPracticesModal
          showModal={showModal}
          toggleModal={setShowModal}
          getProfile={props.getProfile}
          areas={props.areas}
          title={props.title}
          currentModel={currentModel}
          url_perfix={props.url_perfix}
          selectedAreas={props.userData.practice_areas}
        />
      ) : (
        <></>
      )}
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{props.title}</h3>
            </Col>
            <UserAddButtonWidget
              editable={props.editable}
              setCurrenModel={setCurrenModel}
              setShowModal={setShowModal}
            />
          </Row>
        </CardHeader>
        <CardBody className="p-0 sortable-list">
          {items.length ? (
            <div className="p-2 d-flex flex-row flex-wrap mx-3">
              {renderAreas()}
            </div>
          ) : (
            <div className="text-center p-3">
              <h3 className="text-muted">No {props.title} yet!</h3>
              <a
                className="btn btn-link text-muted font-italic"
                onClick={() => setShowModal(true)}
              >
                Start by adding to your {props.title}
              </a>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
