import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import Editor, {
  BtnBold,
  BtnItalic,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";

const schema = yup
  .object({
    title: yup.string().required(),
    short: yup.string().required(),
    status: yup.string().nullable(),
    image: yup.mixed().nullable(),
    publish_date: yup.string().nullable(),
    expires_at: yup.string().nullable(),
    will_expire: yup.number().nullable(),
    has_more: yup.number().nullable(),
  })
  .required();
export default function AddEditAnnouncementModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [html, setHtml] = useState("dsaasdadsasd");

  const onSubmit = (data) => {
    var url = "admin/announcements";

    if (props.currentModel?.id) {
      url = "admin/announcements/" + props.currentModel.id;
    }
    if (publishDate) {
      data.publish_date = moment(
        publishDate.year + "-" + publishDate.month + "-" + publishDate.day
      ).format("yyyy-MM-DD");
    }
    if (expireDate) {
      data.will_expire = 1;
      data.expires_at = moment(
        expireDate.year + "-" + expireDate.month + "-" + expireDate.day
      ).format("yyyy-MM-DD");
    }

    data.has_more = hasMore;
    data.body = html;
    let formData = new FormData();
    for (var prop in data) {
      console.log(data.image);

      if (prop === "image") {
        if (data.image[0] && data.image[0] !== "m") {
          formData.append("image", data.image[0] ? data.image[0] : null);
        }
      } else {
        formData.append(prop, data[prop]);
      }
    }
    setLoading(true);
    axiosConfig
      .post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setPublishDate(
      props?.currentModel.publish_date &&
        props?.currentModel.publish_date !== "0000-00-00"
        ? {
            year: parseInt(
              moment(props?.currentModel.publish_date).format("YYYY")
            ),
            month: parseInt(
              moment(props?.currentModel.publish_date).format("MM")
            ),
            day: parseInt(
              moment(props?.currentModel.publish_date).format("DD")
            ),
          }
        : null
    );
    setExpireDate(
      props?.currentModel.expires_at &&
        props?.currentModel.expires_at !== "0000-00-00"
        ? {
            year: parseInt(
              moment(props?.currentModel.expires_at).format("YYYY")
            ),
            month: parseInt(
              moment(props?.currentModel.expires_at).format("MM")
            ),
            day: parseInt(moment(props?.currentModel.expires_at).format("DD")),
          }
        : null
    );
    setHasMore(
      props?.currentModel.has_more ? props?.currentModel.has_more : false
    );
    setHtml(props?.currentModel.body ? props?.currentModel.body : false);
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };

  return (
    <Modal isOpen={props.showModal} size="xl">
      <ModalHeader>
        {props?.currentModel.id ? "Edit Announcement" : "Add Announcement"}
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          <Row>
            <Col>{createInputs("title", "Title", "Title")}</Col>
          </Row>
          <Row>
            <Col>
              {createInputs("short", "Short Description", "Short Description")}
            </Col>
          </Row>
          <FormGroup className="ml-4">
            <Input
              id="input-active"
              type="checkbox"
              checked={hasMore}
              disabled={loading}
              onChange={(e) => setHasMore(!hasMore)}
            ></Input>

            <label className="form-control-label" htmlFor="input-active">
              More
            </label>
            <FormText color="danger">{errors.status?.message}</FormText>
          </FormGroup>
          {hasMore ? (
            <Row>
              <Col>
                <EditorProvider>
                  <Editor
                    value={html}
                    onChange={(e) => setHtml(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Publish Date
                </label>
                <DatePicker
                  value={publishDate}
                  onChange={setPublishDate}
                  shouldHighlightWeekends
                  wrapperClassName="form-control"
                  inputPlaceholder=" "
                  calendarPopperPosition="bottom"
                  formatInputText={() =>
                    publishDate
                      ? moment(
                          publishDate.year +
                            "-" +
                            publishDate.month +
                            "-" +
                            publishDate.day
                        ).format("yyyy-MM-DD")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Expriration Date
                </label>
                <DatePicker
                  value={expireDate}
                  onChange={setExpireDate}
                  shouldHighlightWeekends
                  wrapperClassName="form-control"
                  inputPlaceholder=" "
                  calendarPopperPosition="bottom"
                  formatInputText={() =>
                    expireDate
                      ? moment(
                          expireDate.year +
                            "-" +
                            expireDate.month +
                            "-" +
                            expireDate.day
                        ).format("yyyy-MM-DD")
                      : ""
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-active">
                  Status
                </label>
                <Input
                  id="input-active"
                  type="select"
                  disabled={loading}
                  {...registerRs("status", register)}
                >
                  <option value="draft">Draft</option>
                  <option value="scheduled">Scheduled</option>
                  <option value="published">Published</option>
                  <option value="archived">Archived</option>
                </Input>
                <FormText color="danger">{errors.status?.message}</FormText>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-image">
              Image
            </label>
            <Input
              id="input-image"
              placeholder="image"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("image", register)}
            />

            <FormText color="danger">{errors.image?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
