import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PowerUpWizard from "./PowerUpWizard";

export default function AddPowerUpModal(props) {
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <PowerUpWizard
          areas={props.areas}
          toggleModal={props.toggleModal}
          getItems={props.getItems}
        />
      </ModalBody>
    </Modal>
  );
}
