import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";

export default function ClientCases(props) {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);

  const getItems = (pageNum) => {
    window.scrollTo(0, 0);

    setLoading(true);
    axiosConfig
      .get(`admin/clients/cases/${props.clienCase.client_id}`)
      .then((res) => {
        setLoading(false);
        setCases(res.data.result.cases);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (cases.data) {
      return cases.data.map((tr, index) => {
        return (
          <tr key={tr.id} className={`text-center`}>
            <td>{tr.name}</td>
            <td>{tr.completed ? "Yes" : "In-Progress"}</td>
            <td>{tr.status}</td>
            <td>{tr.client?.fullname}</td>
            <td>{tr.date}</td>
            <td>
              <Button
                color="info"
                size="sm"
                target="_blank"
                href={location.pathname + "/" + tr.id}
              >
                <i className="fa fa-eye" />
              </Button>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <>
      <ListHeader
        name={props.clienCase.fullname + `'s Cases`}
        parentName="Dashboard"
        setShowModal={() => props.setShowClientCaseModal(false)}
        btnName="Back to Clients"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Cases</h3>
              </CardHeader>
              <Table striped responsive>
                {loading ? (
                  <div className="overlay-loading d-flex jc-center pt-3">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <></>
                )}
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Completed</th>
                    <th>Status</th>
                    <th>Client</th>
                    <th>Case Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
