import axios from "axios";
import Swal from "sweetalert2";
import { APP_ADMIN_URL } from "./Constants";
import { Lang } from "./localization";

const instance = axios.create({
  baseURL: APP_ADMIN_URL,
  timeout: 60000,
});
instance.interceptors.request.use(
  (config) => {
    config.headers = Object.assign(
      {
        Authorization: localStorage.getItem("token"),
        lang: "en",
      },
      config.headers
    );
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    if (res !== undefined && res.data !== undefined && res.data.message)
      // eslint-disable-next-line array-callback-return
      res.data.message.map((msg) => {
        switch (msg.type) {
          case "success":
            Swal.fire({
              position: "top",
              icon: "success",
              title: msg.text,
              showConfirmButton: false,
              toast: true,
              timer: 2500,
            });
            break;
          case "error":
            Swal.fire({
              position: "top",
              icon: "error",
              title: msg.text,
              showConfirmButton: false,
              toast: true,
              timer: 2500,
            });
            break;
          default:
            Swal.fire({
              position: "top",
              icon: "error",
              title: Lang.warningMessage,
              showConfirmButton: false,
              toast: true,
              timer: 2500,
            });
            break;
        }
      });

    return res;
  },
  (error) => {
    if (error.response.status === 422) {
      let res = error.response;
      if (res !== undefined && res.data !== undefined && res.data.errors) {
        Swal.fire({
          position: "top",
          icon: "error",
          title: res.data.errors[Object.keys(res.data.errors)[0]],
          showConfirmButton: false,
          toast: true,
          timer: 2500,
        });
      }
    } else if (error.response.status === 403) {
      window.location.href = "/uauth/login";
      localStorage.setItem("token", "");
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        title: Lang.errorMessage,
        showConfirmButton: false,
        toast: true,
        timer: 2500,
      });
      throw new Error("Something went wrong!");
    }
  }
);

export default instance;
