import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
  NavLink,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { removeFromArray } from "consts/Constants";

const schema = yup
  .object({
    name: yup.string().required(),
    url: yup
      .string()
      .url("Please enter a valid URL. EX:https://example.com/")
      .required(),
  })
  .required();
export default function AddEditWebhooks(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState("none");
  const [activeTab, setActiveTab] = useState(0);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const onSubmit = (data) => {
    var url = "user/webhooks";
    if (props.currentModel?.id) {
      url = "user/webhooks/" + props.currentModel.id + "/edit";
    }
    data.events = selectedEvents.length ? selectedEvents : null;

    data.auth_mode = authMethod;
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.setCurrenModel({});
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setSelectedEvents(
      props.currentModel.item_ids ? props.currentModel.item_ids : []
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  const renderEvents = () => {
    console.log(selectedEvents);
    if (props.events) {
      return props.events.map((item) => {
        return (
          <Col lg={12} key={item.id}>
            <div
              className={`d-flex flex-row ai-center event-box m-2 ${
                selectedEvents.includes(item.id) ? "event-active" : ""
              }`}
              onClick={() => {
                if (selectedEvents.includes(item.id)) {
                  setSelectedEvents(removeFromArray(selectedEvents, item.id));
                } else {
                  setSelectedEvents([...selectedEvents, item.id]);
                }
              }}
            >
              <i
                className={`fa ${
                  selectedEvents.includes(item.id)
                    ? "fa-check-square"
                    : "fa-square"
                } mr-4`}
              />
              <div className="d-flex flex-column">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          </Col>
        );
      });
    }
  };
  const renderAuthMethodInputs = () => {
    if (authMethod === "basic") {
      return (
        <>
          <Col lg={6}>{createInputs("username", "Username", "Username")}</Col>
          <Col lg={6}>{createInputs("password", "Password", "Password")}</Col>
        </>
      );
    } else if (authMethod === "bearer") {
      return (
        <Col lg={12}>
          {createInputs(
            "token",
            "API Bearer Token",
            "API Bearer Token",
            "textarea"
          )}
        </Col>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Nav pills className="d-flex flex-row jc-center mb-4">
            <NavItem className="w-50">
              <NavLink
                onClick={() => setActiveTab(0)}
                href="#"
                active={activeTab === 0 ? "active" : ""}
                className="btn-info"
              >
                Settings
              </NavLink>
            </NavItem>
            <NavItem className="w-50">
              <NavLink
                onClick={() => setActiveTab(1)}
                href="#"
                active={activeTab === 1 ? "active" : ""}
                className="btn-info"
              >
                Events
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === 0 ? (
            <Row>
              <Col lg={12}>
                {createInputs("name", "Webhook Name", "Webhook Name")}
              </Col>
              <Col lg={12}>
                {createInputs("url", "Webhook URL", "Webhook URL")}
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor={`input-start`}>
                    Authentication Method
                  </label>
                  <div className="d-flex flex-row flex-wrap">
                    <Button
                      active={authMethod === "none" ? true : false}
                      onClick={() => setAuthMethod("none")}
                      color="secondary"
                    >
                      None
                    </Button>
                    <Button
                      active={authMethod === "basic" ? true : false}
                      onClick={() => setAuthMethod("basic")}
                      color="secondary"
                    >
                      Basic
                    </Button>
                    <Button
                      active={authMethod === "bearer" ? true : false}
                      onClick={() => setAuthMethod("bearer")}
                      color="secondary"
                    >
                      Bearer
                    </Button>
                  </div>
                </FormGroup>
              </Col>
              {renderAuthMethodInputs()}
            </Row>
          ) : (
            <>
              <Row>{renderEvents()}</Row>
            </>
          )}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.setCurrenModel({});
                props.toggleModal(false);
              }}
            >
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
