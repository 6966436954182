import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";
import { removeWidgetItem } from "consts/Constants";
import AddEditWebhooks from "./AddEditWebhooks";
import { simpleWebRequest } from "consts/Constants";
import HookLogModal from "./HookLogModal";
import ApiKeyModal from "./ApiKeyModal";

export default function UserWebhooks(props) {
  const [loading, setLoading] = useState(false);
  const [hooks, setHooks] = useState([]);
  const [logs, setLogs] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentModel, setCurrenModel] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showApiModal, setApiModal] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const getItems = (pageNum = 1) => {
    setLoading(true);
    axiosConfig
      .get("user/webhooks")
      .then((res) => {
        setLoading(false);
        setHooks(res.data.result.webhooks);
        setEvents(res.data.result.events);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const removeItem = (id) => {
    simpleWebRequest("user/webhooks/" + id + "/delete", getItems);
  };

  const toggleEnable = (id) => {
    simpleWebRequest("user/webhooks/" + id + "/toggle", getItems);
  };
  const verifyHook = (id) => {
    simpleWebRequest("user/webhooks/" + id + "/test", getItems);
  };
  useEffect(() => {
    getItems();
  }, []);
  const getLogs = (hook_id) => {
    setLoading(true);
    axiosConfig
      .get("user/webhooks/" + hook_id + "/log")
      .then((res) => {
        setLoading(false);
        setLogs(res.data.result.log);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const renderHooks = () => {
    if (loading) {
      return (
        <tr className="text-center">
          <td colSpan={5}>
            <Spinner color="info" size="xl" />
          </td>
        </tr>
      );
    } else if (hooks.length) {
      return hooks.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>{tr.name}</td>
            <td>{tr.url}</td>
            <td>
              {tr.verified ? (
                <i className="fa fa-check-circle color-success fs20" />
              ) : (
                <i className="fa fa-times-circle color-danger fs20" />
              )}
            </td>
            <td>
              {tr.status ? (
                <i className="fa fa-check-circle color-success fs20" />
              ) : (
                <i className="fa fa-times-circle color-danger fs20" />
              )}
            </td>
            <td>
              <>
                {/* <a
                  className="btn btn-dark btn-sm"
                  onClick={() => getLogs(tr.id)}
                >
                  Log
                </a> */}
                <a
                  className="btn btn-primary btn-sm"
                  onClick={() => verifyHook(tr.id)}
                >
                  Test
                </a>
                <a
                  className={`btn btn-${
                    tr.status ? "warning" : "success"
                  } btn-sm`}
                  onClick={() => toggleEnable(tr.id)}
                >
                  {tr.status ? "Disable" : "Enable"}
                </a>
                <a
                  className="btn btn-info btn-sm"
                  onClick={() => {
                    setCurrenModel(tr);
                    setShowModal(true);
                  }}
                >
                  Edit
                </a>
                <a
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        removeItem(tr.id);
                      }
                    })
                  }
                >
                  Delete
                </a>
              </>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-center">
          <td colSpan={5}>
            <p>No Webhooks yet!</p>
          </td>
        </tr>
      );
    }
  };
  const getApiKey = () => {
    setLoading(true);
    axiosConfig
      .get("user/api_key")
      .then((res) => {
        setApiModal(true);
        setApiKey(res.data.result.api_key);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const changeApiKey = () => {
    axiosConfig
      .get("user/change_api_key")
      .then((res) => {
        setApiKey(res.data.result.api_key);
      })
      .catch((error) => {});
  };
  return (
    <>
      <AddEditWebhooks
        showModal={showModal}
        toggleModal={setShowModal}
        currentModel={currentModel}
        setCurrenModel={setCurrenModel}
        title={"Webhook"}
        getItems={getItems}
        events={events}
      />
      <ApiKeyModal
        showModal={showApiModal}
        toggleModal={setApiModal}
        apiKey={apiKey}
        title={"Zapier API key"}
        getApiKey={changeApiKey}
      />
      <HookLogModal
        showModal={showLogModal}
        toggleModal={setShowLogModal}
        title={"Webhook recent log"}
      />
      <ListHeader
        name="Integrations"
        parentName="Dashboard"
        btnName="New Webhook"
        setShowModal={setShowModal}
        setCurrenModel={setCurrenModel}
        setExtraButton={getApiKey}
        extraBtnName="Get Zapier API key"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Webhooks</h3>
              </CardHeader>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>URL</th>
                    <th>VERIFIED</th>
                    <th>STATUS</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>{renderHooks()}</tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
