import React from "react";
import Swal from "sweetalert2";

export default function UserEditDeleteButtonWidget(props) {
  if (props.editable)
    return (
      <div className="edu-item-actions d-flex flex-column">
        <i
          className="fa fa-pen"
          onClick={() => {
            props.setCurrenModel(props.item);
            props.setShowModal(true);
          }}
        />
        <i
          className="fa fa-trash trash-btn"
          onClick={() =>
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then((result) => {
              if (result.isConfirmed) {
                props.removeItem(props.item.id);
              }
            })
          }
        />
      </div>
    );
}
