import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import markerClusters from "highcharts/modules/marker-clusters";
import { findDOMNode } from "react-dom";
import networkgraph from "highcharts/modules/networkgraph";

import proj4 from "proj4";
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

if (typeof Highcharts === "object") {
  networkgraph(Highcharts);
}

const HighMapsWrapper = Highcharts;

class NetwrokDiagram extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.setupMapData();
  }

  setupMapData() {
    const options = {
      chart: {
        type: "networkgraph",
      },
      title: {
        text: "",
        align: "left",
      },
      subtitle: {
        text: "",
        align: "left",
      },
      plotOptions: {
        networkgraph: {
          layoutAlgorithm: {
            enableSimulation: true,
            friction: -0.9,
          },
        },
      },
      series: [
        {
          accessibility: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            linkFormat: "",
            style: {
              fontSize: "1.5em",
              fontWeight: "normal",
            },
          },
          id: "lang-tree",
          data: this.diagramData(),
        },
      ],
    };

    new HighMapsWrapper["Map"](findDOMNode(this), options);
  }

  componentWillUnmount() {
    // this.chart.destroy()
  }
  diagramData() {
    var leaves = [];
    if (this.props.followers) {
      this.props.followers.map((item) => {
        leaves.push({
          from: "Me",
          to: item.fullname,
          color: "#" + item.type_color,
        });
      });
    }
    return leaves;

    var types = { attorney: 0, medical: 0, other: 0 };
    var colors = { attorney: "#000", medical: "#000", other: "#000" };
    var leaves = [];
    if (this.props.followers) {
      this.props.followers.map((item) => {
        if (item.type?.name === "Attorney") {
          types.attorney = types.attorney + 1;
          colors.attorney = "#" + item.type_color;
        } else if (item.type?.name === "Medical") {
          types.medical = types.medical + 1;
          colors.medical = "#" + item.type_color;
        } else {
          types.other = types.other + 1;
        }
      });

      if (types.attorney > 0) {
        leaves.push({
          from: "Me",
          to: types.attorney + " Attornies",
          color: colors.attorney,
        });
      } else if (types.medical) {
        leaves.push({
          from: "Me",
          to: types.medical + " Medical Providers",
          color: colors.medical,
        });
      } else {
        leaves.push({
          from: "Me",
          to: types.other + " Other",
          color: colors.other,
        });
      }
      return leaves;
    }
  }
  render() {
    return <div />;
  }
}

export default NetwrokDiagram;
