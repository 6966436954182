import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";
const stripePromise = loadStripe(
  "pk_test_51MoaVuGLNJdZHDs9gcF0CpJ7FNus3oBB4iO48lJO9dQIg1hm1SZHM56vvkGVCwoaJqefNsGwZODwmkfxQt7H7CEd00u5A10qIc"
);
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name === "" || name == null) {
      Swal.fire({
        position: "top",
        icon: "error",
        text: "Please enter mayment method name",
        showConfirmButton: false,
        toast: true,
        timer: 2500,
      });
      return false;
    }

    if (elements == null) {
      return;
    }
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    console.log(paymentMethod);
    if (error) {
      Swal.fire({
        position: "top",
        icon: "error",
        text: error.message,
        showConfirmButton: false,
        toast: true,
        timer: 2500,
      });
      setLoading(false);
    } else {
      axiosConfig
        .post("organization/billing/card", {
          paymentMethod: paymentMethod,
          name: name,
        })
        .then((res) => {
          if (!res.data.hasError) {
            props.toggleModal(false);
            props.getItems();
            setName("");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal isOpen={props.showModal}>
      <form onSubmit={handleSubmit}>
        <ModalHeader>Add payment method</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label" htmlFor={`input-name`}>
              Name
            </label>
            <Input
              id={`input-name`}
              placeholder="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <CardElement />
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button color="info" type="submit" disabled={!stripe || !elements}>
              Add
            </Button>
          </ModalFooter>
        )}
      </form>
    </Modal>
  );
};
export default function AddPaymentMethodModal(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        showModal={props.showModal}
        toggleModal={props.toggleModal}
        getItems={props.getItems}
      />
    </Elements>
  );
}
