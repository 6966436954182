/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import axiosConfig from "consts/axiosConfig";
import UserEducationComponent from "../User/UserProfile/Sections/Education/UserEducationComponent";
import UserAffiliationComponent from "../User/UserProfile/Sections/Affiliation/UserAffiliationComponent";
import UserAwardComponent from "../User/UserProfile/Sections/Award/UserAwardComponent";
import BasicInformationComponent from "../User/UserProfile/Sections/ProfileInformation/BasicInformationComponent";
import ContactInformationComponent from "../User/UserProfile/Sections/ProfileInformation/ContactInformationComponent";
import SocialMediaInformationComponent from "../User/UserProfile/Sections/ProfileInformation/SocialMediaInformationComponent";
import AboutInformationComponent from "../User/UserProfile/Sections/ProfileInformation/AboutInformationComponent";
import UserBarComponent from "../User/UserProfile/Sections/Bar/UserBarComponent";
import UserCaseHistoryComponent from "../User/UserProfile/Sections/CaseHistory/UserCaseHistoryComponent";
import UserCertificateComponent from "../User/UserProfile/Sections/Certificate/UserCertificateComponent";
import UserContactComponent from "../User/UserProfile/Sections/Contact/UserContactComponent";
import UserClientComponent from "../User/UserProfile/Sections/Client/UserClientComponent";
import UserExperienceComponent from "../User/UserProfile/Sections/Experience/UserExperienceComponent";
import UserMembershipComponent from "../User/UserProfile/Sections/Membership/UserMembershipComponent";
import UserTestimonialComponent from "../User/UserProfile/Sections/Testimonial/UserTestimonialComponent";
import UserLocationComponent from "../User/UserProfile/Sections/Location/UserLocationComponent";
import UserPersonnelComponent from "../User/UserProfile/Sections/Personnel/UserPersonnelComponent";
import UserBriefCard from "../User/UserProfile/Sections/ProfileCard/UserBriefCard";
import UserPracticesComponent from "../User/UserProfile/Sections/Practices/UserPracticesComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function UserPublicProfile({ match }) {
  const isEditable = false;
  const [pageLoading, setPageLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [following, setFollowing] = useState(false);
  const [endorsed, setEndorsed] = useState(false);
  const [followRequest, setFollowRequest] = useState(false);
  const [areaPlural, setAreaPlural] = useState("");
  const [likers, setLikers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    axiosConfig
      .get("user/profile/" + match.params.user_id)
      .then((res) => {
        setPageLoading(false);
        setFollowing(res.data.result?.following);
        setFollowRequest(res.data.result?.follow_request);
        setEndorsed(res.data.result?.endorsed);
        setLikers(res.data.result?.likers);
        setUserData(res.data.result.user);
        setAreaPlural(
          res.data.result.user.type?.area_plural
            ? res.data.result.user.type?.area_plural
            : "Practice areas"
        );
      })
      .catch((error) => {});
  };

  if (JSON.parse(localStorage.getItem("account")).id === match.params.user_id) {
    history.push("/user/profile");
  } else {
    if (pageLoading) {
      return (
        <div
          className="d-flex flex-column jc-center ai-center"
          style={{ height: 800 }}
        >
          <Spinner />
          <span className="text-muted mt-2">Loading...</span>
        </div>
      );
    }
    return (
      <>
        <ProfileHeader userData={userData} likers={likers} />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-2" xl="4">
              <UserBriefCard
                userData={userData}
                editable={isEditable}
                title="Change Background/Profile Photo"
                getProfile={getProfile}
                following={following}
                followRequest={followRequest}
                endorsed={endorsed}
                url_perfix={"user"}
              />
              <UserBarComponent
                getProfile={getProfile}
                userData={userData}
                title="Bars"
                coudBeRendered={userData?.type?.bars}
                editable={isEditable}
                url_perfix=""
              />
              <UserPracticesComponent
                getProfile={getProfile}
                userData={userData}
                title={areaPlural}
                coudBeRendered={true}
                editable={isEditable}
                url_perfix=""
              />
              <UserEducationComponent
                getProfile={getProfile}
                userData={userData}
                title="Education"
                coudBeRendered={userData?.type?.education}
                editable={isEditable}
                url_perfix=""
              />
              <UserAffiliationComponent
                getProfile={getProfile}
                userData={userData}
                title="Affiliations"
                coudBeRendered={userData?.type?.affiliations}
                editable={isEditable}
                url_perfix=""
              />
              <UserAwardComponent
                getProfile={getProfile}
                userData={userData}
                title="Awards"
                coudBeRendered={userData?.type?.awards}
                editable={isEditable}
                url_perfix=""
              />
              <UserCaseHistoryComponent
                getProfile={getProfile}
                userData={userData}
                title="Case Histories"
                coudBeRendered={userData?.type?.case_histories}
                editable={isEditable}
                url_perfix=""
              />
              <UserClientComponent
                getProfile={getProfile}
                userData={userData}
                title="Customers"
                coudBeRendered={userData?.type?.customers}
                editable={isEditable}
                url_perfix=""
              />
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Profile Information</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <BasicInformationComponent
                    userData={userData}
                    title="Basic Information"
                    getProfile={getProfile}
                    editable={isEditable}
                    url_perfix=""
                  />
                  <hr className="my-4" />
                  <ContactInformationComponent
                    userData={userData}
                    title="Contact Information"
                    getProfile={getProfile}
                    editable={isEditable}
                    url_perfix=""
                  />
                  <hr className="my-4" />
                  <SocialMediaInformationComponent
                    userData={userData}
                    title="Social Media Information"
                    getProfile={getProfile}
                    editable={isEditable}
                  />
                  <hr className="my-4" />
                  <AboutInformationComponent
                    userData={userData}
                    title="More About Me"
                    getProfile={getProfile}
                    editable={isEditable}
                    url_perfix=""
                  />
                </CardBody>
              </Card>
              <Row>
                <UserCertificateComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Certificates"
                  coudBeRendered={userData?.type?.certificates}
                  editable={isEditable}
                  url_perfix=""
                />
                <UserContactComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Contacts"
                  coudBeRendered={userData?.type?.contacts}
                  editable={isEditable}
                  url_perfix=""
                />
                <UserTestimonialComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Testimontals"
                  coudBeRendered={userData?.type?.testimonials}
                  editable={isEditable}
                  url_perfix=""
                />
                <UserMembershipComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Memberships"
                  coudBeRendered={userData?.type?.memberships}
                  editable={isEditable}
                  url_perfix=""
                />
                <UserPersonnelComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Personnels"
                  coudBeRendered={userData?.type?.personnels}
                  editable={isEditable}
                  url_perfix=""
                />

                <UserLocationComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Locations"
                  coudBeRendered={userData?.type?.locations}
                  editable={isEditable}
                  url_perfix=""
                />

                <UserExperienceComponent
                  getProfile={getProfile}
                  userData={userData}
                  title="Experiences"
                  coudBeRendered={userData?.type?.experiences}
                  editable={isEditable}
                  url_perfix=""
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserPublicProfile;
