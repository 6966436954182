import moment from "moment";
import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";

export default function Transactions(props) {
  const renderTransactions = () => {
    if (props.transactions.length) {
      return props.transactions.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td className="text-left">{tr.name}</td>
            <td>${tr.amount}</td>
            <td>{moment(tr.created_at).format("yyyy-MM-DD HH:mm")}</td>
          </tr>
        );
      });
    }
  };
  return (
    <Card>
      <CardHeader>
        <h3>Transactions</h3>
      </CardHeader>
      <CardBody>
        <Table striped>
          <thead>
            <tr>
              <th className="text-left">Description</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>{renderTransactions()}</tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
