import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import axiosConfig from "consts/axiosConfig";
import UserEducationComponent from "./Sections/Education/UserEducationComponent";
import UserAffiliationComponent from "./Sections/Affiliation/UserAffiliationComponent";
import UserAwardComponent from "./Sections/Award/UserAwardComponent";
import BasicInformationComponent from "./Sections/ProfileInformation/BasicInformationComponent";
import ContactInformationComponent from "./Sections/ProfileInformation/ContactInformationComponent";
import SocialMediaInformationComponent from "./Sections/ProfileInformation/SocialMediaInformationComponent";
import AboutInformationComponent from "./Sections/ProfileInformation/AboutInformationComponent";
import UserBarComponent from "./Sections/Bar/UserBarComponent";
import UserCaseHistoryComponent from "./Sections/CaseHistory/UserCaseHistoryComponent";
import UserCertificateComponent from "./Sections/Certificate/UserCertificateComponent";
import UserContactComponent from "./Sections/Contact/UserContactComponent";
import UserClientComponent from "./Sections/Client/UserClientComponent";
import UserExperienceComponent from "./Sections/Experience/UserExperienceComponent";
import UserMembershipComponent from "./Sections/Membership/UserMembershipComponent";
import UserTestimonialComponent from "./Sections/Testimonial/UserTestimonialComponent";
import UserLocationComponent from "./Sections/Location/UserLocationComponent";
import UserPersonnelComponent from "./Sections/Personnel/UserPersonnelComponent";
import UserBriefCard from "./Sections/ProfileCard/UserBriefCard";
import UserPracticesComponent from "./Sections/Practices/UserPracticesComponent";
import OrganizationCardInfo from "./Sections/ProfileInformation/OrganizationCardInfo";
import ProfileProgress from "./Sections/ProfileProgress";
import Network from "./Sections/Netwrok";
// import { useTour } from "@reactour/tour";
function UserProfile() {
  const isEditable = true;
  const url_perfix = "user/";
  const [pageLoading, setPageLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [progress, setProgress] = useState(null);
  const [progressItems, setProgressItems] = useState([]);
  const [areas, setAreas] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [likers, setLikers] = useState([]);
  const [areaPlural, setAreaPlural] = useState("");
  // const { setIsOpen } = useTour();

  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = () => {
    axiosConfig
      .get(url_perfix + "practice_areas")
      .then((res) => {
        setAreas(res.data.result.areas);
      })
      .catch((error) => {});
    axiosConfig
      .get("user")
      .then((res) => {
        setPageLoading(false);
        setProgress(res.data.result?.progress);
        setProgressItems(res.data.result?.items);
        setUserData(res.data.result.user);
        setFollowers(res.data.result.following);
        setLikers(res.data.result.likers);
        // setIsOpen(res.data.result.user.has_tour);

        setAreaPlural(
          res.data.result.user.type?.area_plural
            ? res.data.result.user.type?.area_plural
            : "Practice areas"
        );
        localStorage.setItem("firstname", res.data.result.user.firstname);
        localStorage.setItem("lastname", res.data.result.user.lastname);
        localStorage.setItem("account", JSON.stringify(res.data.result.user));
      })
      .catch((error) => {});
  };
  const updateProfile = (data, toggleModal, setModalLoading) => {
    setModalLoading(true);
    axiosConfig
      .post("update_basic_profile", data)
      .then((res) => {
        if (!res.data.hasError) {
          toggleModal(false);
          getProfile();
        }
        setModalLoading(false);
      })
      .catch((error) => {
        setModalLoading(false);
      });
  };
  if (pageLoading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
      <ProfileHeader userData={userData} likers={likers} />

      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <UserBriefCard
              userData={userData}
              editable={isEditable}
              title="Change Background/Profile Photo"
              getProfile={getProfile}
              updateProfile={updateProfile}
              currentUser={true}
            />
            <OrganizationCardInfo
              getProfile={getProfile}
              userData={userData}
              coudBeRendered={true}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserBarComponent
              getProfile={getProfile}
              userData={userData}
              title="State License"
              coudBeRendered={userData?.type?.bars}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserPracticesComponent
              getProfile={getProfile}
              userData={userData}
              title={areaPlural}
              areas={areas}
              coudBeRendered={true}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserEducationComponent
              getProfile={getProfile}
              userData={userData}
              title="Education"
              coudBeRendered={userData?.type?.education}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserAffiliationComponent
              getProfile={getProfile}
              userData={userData}
              title="Affiliations"
              coudBeRendered={userData?.type?.affiliations}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserAwardComponent
              getProfile={getProfile}
              userData={userData}
              title="Awards"
              coudBeRendered={userData?.type?.awards}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserCaseHistoryComponent
              getProfile={getProfile}
              userData={userData}
              title="Case Histories"
              coudBeRendered={userData?.type?.case_histories}
              editable={isEditable}
              url_perfix={url_perfix}
            />
            <UserClientComponent
              getProfile={getProfile}
              userData={userData}
              title="Customers"
              coudBeRendered={userData?.type?.customers}
              editable={isEditable}
              url_perfix={url_perfix}
            />
          </Col>

          <Col className="order-xl-1" xl="8">
            <ProfileProgress
              title="Complete Profile"
              progress={progress}
              progressItems={progressItems}
              coudBeRendered={true}
            />
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Profile Information</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <BasicInformationComponent
                  userData={userData}
                  title="Basic Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
                <hr className="my-4" />
                <ContactInformationComponent
                  userData={userData}
                  title="Contact Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
                <hr className="my-4" />
                <SocialMediaInformationComponent
                  userData={userData}
                  title="Social Media Information"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
                <hr className="my-4" />
                <AboutInformationComponent
                  userData={userData}
                  title="More About Me"
                  getProfile={getProfile}
                  updateProfile={updateProfile}
                  editable={isEditable}
                  url_perfix={url_perfix}
                />
              </CardBody>
            </Card>
            <Row>
              <Network
                getProfile={getProfile}
                followers={followers}
                title="My Network"
                coudBeRendered={true}
              />
              <UserCertificateComponent
                getProfile={getProfile}
                userData={userData}
                title="Certificates"
                coudBeRendered={userData?.type?.certificates}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserContactComponent
                getProfile={getProfile}
                userData={userData}
                title="Contacts"
                coudBeRendered={userData?.type?.contacts}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserTestimonialComponent
                getProfile={getProfile}
                userData={userData}
                title="Testimontals"
                coudBeRendered={userData?.type?.testimonials}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserMembershipComponent
                getProfile={getProfile}
                userData={userData}
                title="Memberships"
                coudBeRendered={userData?.type?.memberships}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserPersonnelComponent
                getProfile={getProfile}
                userData={userData}
                title="Personnel"
                coudBeRendered={userData?.type?.personnels}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserLocationComponent
                getProfile={getProfile}
                userData={userData}
                title="Locations"
                coudBeRendered={userData?.type?.locations}
                editable={isEditable}
                url_perfix={url_perfix}
              />
              <UserExperienceComponent
                getProfile={getProfile}
                userData={userData}
                title="Experiences"
                coudBeRendered={userData?.type?.experiences}
                editable={isEditable}
                url_perfix={url_perfix}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserProfile;
