import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";

export default function AddPhoneModal(props) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState(null);
  const [code, setCode] = useState(null);
  const addPhone = () => {
    setLoading(true);
    axiosConfig
      .post("user-verify", { phone: phone })
      .then((res) => {
        if (!res.data.hasError) {
          setStep(2);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const verifyPhone = () => {
    setLoading(true);
    axiosConfig
      .post("user-verify", { phone: phone, code: code })
      .then((res) => {
        console.log(res.data);
        if (!res.data.hasError) {
          setStep(1);
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>Add Phone Number</ModalHeader>
      <Form>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label" htmlFor={"input-phone"}>
              Phone Number
            </label>
            <Input
              id={`input-phone`}
              placeholder={"Enter phone number..."}
              disabled={loading || step === 2}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormGroup>
          {step === 2 ? (
            <FormGroup>
              <label className="form-control-label" htmlFor={"input-code"}>
                Verification Code
              </label>
              <Input
                id={`input-code`}
                placeholder={"Enter verification code..."}
                disabled={loading || step === 1}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormGroup>
          ) : (
            <></>
          )}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.toggleModal(false);
                setStep(1);
                setCode(null);
              }}
            >
              Discard
            </Button>
            <Button
              disabled={loading}
              color="primary"
              type="button"
              onClick={() => (step === 1 ? addPhone() : verifyPhone())}
            >
              {step === 1 ? "Next" : "Verify"}
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
