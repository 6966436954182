import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import markerClusters from "highcharts/modules/marker-clusters";
import { mapEurope } from "./mapEurope";
import { findDOMNode } from "react-dom";
import proj4 from "proj4";
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}
const proj4Wrapper = proj4;

markerClusters(Highcharts);
if (typeof window !== "undefined") {
  HighchartsMap(Highcharts);
}

const HighMapsWrapper = Highcharts;

class UDashChart extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.setupMapData();
  }

  setupMapData() {
    const options = {
      chart: {
        map: mapEurope,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      mapNavigation: {
        enabled: true,
      },
      tooltip: {
        formatter: function () {
          if (this.point.clusteredData) {
            return "Clustered points: " + this.point.clusterPointsAmount;
          }
          return (
            "<b>" +
            this.key +
            "</b><br>Lat: " +
            this.point.lat.toFixed(2) +
            ", Lon: " +
            this.point.lon.toFixed(2)
          );
        },
      },
      colorAxis: {
        min: 0,
        max: 20,
      },
      plotOptions: {
        mappoint: {
          cluster: {
            enabled: true,
            allowOverlap: false,
            animation: {
              duration: 450,
            },
            layoutAlgorithm: {
              type: "grid",
              gridSize: 70,
            },
          },
        },
      },

      series: [
        {
          name: "Basemap",
          borderColor: "#A0A0A0",
          nullColor: "rgba(200, 200, 200, 0.3)",
          showInLegend: false,
          states: {
            hover: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          },
        },
        {
          data: this.props.points,
          type: "mappoint",
          enableMouseTracking: true,
          colorKey: "clusterPointsAmount",
          color: Highcharts.getOptions().colors[1],
          name: "Cities",
        },
      ],
    };

    new HighMapsWrapper["Map"](findDOMNode(this), options);
  }

  componentWillUnmount() {
    // this.chart.destroy()
  }

  render() {
    return <div />;
  }
}

export default UDashChart;
