export const Lang = {
  successMessage: "Action performed successfully",
  errorMessage: "Something went wrong!",
  warningMessage: "Warning",
  emailIsNotValid: "Email is not valid",
  emptyFieldError: "This field can not be empty",
  loginError: "Email or Password is not correct",
  youHaveAnaccountAlready:
    "You already have an account, you will be redirected to login",
};
