import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";

const schema = yup
  .object({
    name: yup.string().required(),
    link: yup.string().nullable(),
    avatar: yup.mixed().nullable(),
  })
  .required();
export default function UserPersonnelModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    var url = props.url_perfix + "personnel_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "personnel_update/" + props.currentModel.id;
    }
    let formData = new FormData();
    for (var prop in data) {
      if (prop === "avatar") {
        console.log(data);
        formData.append("avatar", data.avatar ? data.avatar[0] : null);
      } else {
        formData.append(prop, data[prop]);
      }
    }
    formData.append("position", 0);
    setLoading(true);
    axiosConfig
      .post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          <Row>
            <Col>{createInputs("name", "Name", "Name")}</Col>
          </Row>
          <Row>
            <Col>{createInputs("link", "Link", "Link")}</Col>
          </Row>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-avatar">
              Picture
            </label>
            <Input
              id="input-avatar"
              placeholder="avatar"
              type="file"
              accept="image/*"
              disabled={loading}
              {...registerRs("avatar", register)}
            />

            <FormText color="danger">{errors.avatar?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
