import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Lang } from "consts/localization";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import MapPicker from "react-google-map-picker";
import axiosConfig from "consts/axiosConfig";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import axios from "axios";

const DefaultZoom = 10;

const schema = yup
  .object({
    country: yup.string().required(Lang.emptyFieldError),
    state: yup.string().required(Lang.emptyFieldError),
    city: yup.string().required(Lang.emptyFieldError),
    zipcode: yup.number().required(Lang.emptyFieldError),
    address: yup.string().required(Lang.emptyFieldError),
  })
  .required();
export default function ContactInformationModal(props) {
  const [defaultLocation, setDefaultLocation] = useState({});
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [showMap, setShowMap] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    var newUserData = props.userData;
    if (location.lat) {
      newUserData.lat = location.lat;
      newUserData.lng = location.lng;
    }
    newUserData.country = data.country;
    newUserData.state = data.state;
    newUserData.city = data.city;
    newUserData.zipcode = data.zipcode;
    newUserData.address = data.address;
    setShowMap(false);
    props.updateProfile(newUserData, props.toggleModal, setLoading);
  };

  useEffect(() => {
    reset(props.userData);
    if (props.userData.lat) {
      setDefaultLocation({
        lat: parseFloat(props.userData.lat),
        lng: parseFloat(props.userData.lng),
      });
      setLocation({
        lat: parseFloat(props.userData.lat),
        lng: parseFloat(props.userData.lng),
      });
    } else {
      setDefaultLocation({
        lat: 33.836889278534,
        lng: -117.4841168417,
      });
    }
    const s = watch((_, { name, type }) => {
      if (name === "zipcode") {
        if (watch("zipcode").length === 5) {
          getAddress();
        }
      }
    });
    setTimeout(() => {
      setShowMap(true);
    }, 1000);
  }, [props.userData, reset, setDefaultLocation, setLocation, watch]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />

        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  const getAddress = () => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${watch(
          "zipcode"
        )}&key=AIzaSyDLmGjR-Xn0EZOyp1qF65vQ1an0AhlbjtE`
      )
      .then((response) => {
        if (response.data.status === "OK") {
          setDefaultLocation({
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          });
          setLocation({
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          });

          const address = response.data.results[0].formatted_address;
          let city, state, country;
          for (
            let i = 0;
            i < response.data.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.data.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.data.results[0].address_components[i].types[j]) {
                case "locality":
                  city =
                    response.data.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_2":
                  city =
                    response.data.results[0].address_components[i].long_name;
                  break;
                case "neighborhood":
                  city =
                    response.data.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state =
                    response.data.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country =
                    response.data.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          reset({
            city: city,
            state: state,
            country: country,
            address: address,
          });
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {createInputs("zipcode", "Zip Code", "Zip Code")}
          {/* {createInputs("country", "Country", "Country")}
          <FormGroup>
            <label className="form-control-label">State</label>
            <Controller
              control={control}
              name={"state"}
              render={({ field }) => (
                <Select2
                  onChange={(id) => field.onChange(id)}
                  value={field.value}
                  className="form-control"
                  options={{
                    placeholder: "State",
                  }}
                  data={
                    states
                      ? states.map((cl) => {
                          return {
                            id: cl.name,
                            text: cl.name,
                          };
                        })
                      : []
                  }
                />
              )}
            />
            <FormText color="danger">{errors.state?.message}</FormText>
          </FormGroup> */}
          {/* {createInputs("city", "City", "City")} */}
          {createInputs("address", "Address", "Address")}
          {showMap ? (
            <>
              <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "400px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                apiKey="AIzaSyDl61ZWwYqoQkF-3Rtr3hgLDGDn9cPKx_0"
              />
            </>
          ) : (
            <></>
          )}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.toggleModal(false);
                setShowMap(false);
              }}
            >
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
