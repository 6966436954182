import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import moment from "moment";
import FilterModal from "./FilterModal";
import { useDownloadExcel } from "react-export-table-to-excel";

export default function CaseReports(props) {
  const [loading, setLoading] = useState(false);
  const [items, setITems] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Cases",
    sheet: "Cases",
  });
  const getItems = () => {
    setFromDate(null);
    setToDate(null);
    setLoading(true);
    axiosConfig
      .get("user/reports/cases")
      .then((res) => {
        setLoading(false);
        setITems(res.data.result.cases);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const filterReports = () => {
    setShowModal(false);
    var from = moment(
      fromDate.year + "-" + fromDate.month + "-" + fromDate.day
    ).format("yyyy-MM-DD");
    var to = moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
      "yyyy-MM-DD"
    );
    setLoading(true);
    axiosConfig
      .get(`user/reports/cases?start=${from}&end=${to}`)
      .then((res) => {
        setLoading(false);
        setITems(res.data.result.cases);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);
  const renderItems = () => {
    if (loading) {
      return (
        <tr className="text-center">
          <td colSpan={7}>
            <Spinner color="info" size="xl" />
          </td>
        </tr>
      );
    } else if (items) {
      return items.map((tr) => {
        return (
          <tr key={tr.id} className="text-center">
            <td>{tr.name}</td>
            <td>{tr.client?.fullname}</td>
            <td>{tr.status}</td>
            <td>{tr.date}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-center">
          <td colSpan={7}>
            <p>No Cases yet!</p>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <FilterModal
        showModal={showModal}
        toggleModal={setShowModal}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        filterReports={filterReports}
        title="Filter"
      />
      <ListHeader
        name="Cases Report"
        parentName="Dashboard"
        setShowModal={setShowModal}
        btnName="Filter"
        setExtraButton={onDownload}
        extraBtnName="Export"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Cases{" "}
                  <span className="text-muted">
                    {fromDate && toDate
                      ? `from ${moment(
                          fromDate.year +
                            "-" +
                            fromDate.month +
                            "-" +
                            fromDate.day
                        ).format("yyyy-MM-DD")} 
                  to 
                  ${moment(
                    toDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")}`
                      : ""}
                  </span>
                  {fromDate && toDate ? (
                    <Button color="link" size="sm" onClick={() => getItems()}>
                      Reset
                    </Button>
                  ) : (
                    <></>
                  )}
                </h3>
              </CardHeader>

              <table className="table table-striped" ref={tableRef}>
                <thead>
                  <tr>
                    <th>CASE NAME</th>
                    <th>CLIENT NAME</th>
                    <th>STATUS</th>
                    <th>DATE</th>
                  </tr>
                </thead>
                <tbody>{renderItems()}</tbody>
              </table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
