/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { APP_IMAGE_URL } from "consts/Constants";
import UserPersonnelModal from "./UserPersonnelModal";
import UserAddButtonWidget from "../Components/UserAddButtonWidget";
import UserEditDeleteButtonWidget from "../Components/UserEditDeleteButtonWidget";
import UserSortableButtonWidget from "../Components/UserSortableButtonWidget";
import { sortWidgetItems } from "consts/Constants";
import { removeWidgetItem } from "consts/Constants";
export default function UserPersonnelComponent(props) {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentModel, setCurrenModel] = useState({});

  useEffect(() => {
    setItems(props.userData.personnels);
  }, [props.userData.personnels]);

  const SortableItem = SortableElement(({ item }) => (
    <div className="d-flex flex-row jc-space-between edu-item jc-center">
      <div className="d-flex flex-row ai-start">
        <div className="d-flex flex-row edu-img ai-center">
          <UserSortableButtonWidget editable={props.editable} />
          <img
            alt="edu-logo"
            src={
              item.avatar
                ? APP_IMAGE_URL + item.avatar
                : require("assets/img/theme/img-1-1000x600.jpg")
            }
            width={50}
            height={50}
            className="rounded-circle"
          />
        </div>
        <div className="d-flex flex-column edu-item-inner ml-3">
          <span>{item.name}</span>
          <span>
            {item.link ? (
              <a href={item.link} target="_blank" rel="noreferrer">
                {item.link}
              </a>
            ) : (
              <></>
            )}
          </span>
          <span>
            {item.description ? `Description: ${item.description}` : ""}
          </span>
        </div>
      </div>
      <hr className="my-4" />
      <UserEditDeleteButtonWidget
        editable={props.editable}
        setCurrenModel={setCurrenModel}
        setShowModal={setShowModal}
        item={item}
        removeItem={removeItem}
      />
    </div>
  ));
  const SortableList = SortableContainer(({ items }) => {
    if (items.length) {
      return (
        <ul>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} item={value} />
          ))}
        </ul>
      );
    } else {
      return (
        <div className="text-center p-3">
          <h3 className="text-muted">No {props.title} yet!</h3>
          {props.editable ? (
            <a
              className="btn btn-link text-muted font-italic"
              onClick={() => setShowModal(true)}
            >
              Increase your credibility, tell us about your personnel
            </a>
          ) : (
            <></>
          )}
        </div>
      );
    }
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(items, oldIndex, newIndex);
    sortWidgetItems(
      props.url_perfix + "personnel_priority",
      setItems,
      newItems,
      props.getProfile,
      props.editable
    );
  };
  const removeItem = (id) => {
    removeWidgetItem(
      props.url_perfix + "personnel_delete/" + id,
      props.getProfile
    );
  };
  if (!props.coudBeRendered) {
    return;
  }
  return (
    <Col lg={6}>
      {props.editable ? (
        <UserPersonnelModal
          showModal={showModal}
          toggleModal={setShowModal}
          getProfile={props.getProfile}
          title={props.title}
          currentModel={currentModel}
          url_perfix={props.url_perfix}
        />
      ) : (
        <></>
      )}
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{props.title}</h3>
            </Col>
            <UserAddButtonWidget
              editable={props.editable}
              setCurrenModel={setCurrenModel}
              setShowModal={setShowModal}
            />
          </Row>
        </CardHeader>
        <CardBody className="p-0 sortable-list">
          {items ? (
            <SortableList
              pressDelay={500}
              items={items}
              onSortEnd={onSortEnd}
            />
          ) : (
            <div className="text-center p-3">
              <h3 className="text-muted">No {props.title} yet!</h3>
              {props.editable ? (
                <a
                  className="btn btn-link text-muted font-italic"
                  onClick={() => setShowModal(true)}
                >
                  Increase your credibility, tell us about your personnel
                </a>
              ) : (
                <></>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}
