import { APP_IMAGE_URL } from "consts/Constants";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

export default function UserCardComponent(props) {
  const distanceCalc = (distance, sponsored) => {
    if (sponsored) {
      return (
        <>
          <span className={`mr-1 text-info`}>●</span>
          <small>Sponsored</small>
        </>
      );
    }
    if (distance !== undefined)
      if (distance < 1) {
        return (
          <>
            <span
              className={`mr-1 ${sponsored ? "text-info" : "text-success"}`}
            >
              ●
            </span>
            <small>Less than a mile</small>
          </>
        );
      } else if (distance > 0) {
        return (
          <>
            <span
              className={`mr-1 ${sponsored ? "text-info" : "text-success"}`}
            >
              ●
            </span>
            <small>{distance.toFixed(2)} Miles</small>
          </>
        );
      } else {
        return (
          <>
            <span
              className={`mr-1 ${sponsored ? "text-info" : "text-success"}`}
            >
              ●
            </span>
            <small>{distance.toFixed(2)} Miles</small>
          </>
        );
      }
  };
  console.log(props.item.sponsered);
  return (
    <Card className={`mt-0 ${props.item.sponsored ? "sponsered-border" : ""}`}>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <div className="avatar rounded-circle">
                  <img alt="..." src={props.avatarData} />
                </div>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <a
                    href={
                      props.item.firstname
                        ? `/user/u/${props.item.id}`
                        : `/user/o/${props.item.id}`
                    }
                  >
                    {props.item.firstname
                      ? props.item.firstname + " " + props.item.lastname
                      : props.item.name}
                  </a>
                </h4>
                <div className="text-muted" style={{ fontSize: 12 }}>
                  {props.item.practice_name ? (
                    props.item.practice_name
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>

                {distanceCalc(props.item.distance, props.item.sponsored)}
                <div>
                  <i className="fa fa-map-marker fs-12 text-primary"></i>
                  <small className="ml-2">
                    {props.item.city + ", " + props.item.state}
                  </small>
                </div>
              </div>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
}
