import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

const schema = yup
  .object({
    title: yup.string().required(),
    desc: yup.string().nullable(),
    license: yup.string().nullable(),
  })
  .required();
export default function UserBarModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const onSubmit = (data) => {
    data.date = moment(date.year + "-" + date.month + "-" + date.day).format(
      "yyyy-MM-DD"
    );
    var url = props.url_perfix + "bars_store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "bars_update/" + props.currentModel.id;
    }
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setDate(
      props?.currentModel.date && props?.currentModel.date !== "0000-00-00"
        ? {
            year: parseInt(moment(props?.currentModel.date).format("YYYY")),
            month: parseInt(moment(props?.currentModel.date).format("MM")),
            day: parseInt(moment(props?.currentModel.date).format("DD")),
          }
        : null
    );
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {createInputs("title", "Title", "Title")}
          {createInputs("license", "State Bar License", "State Bar License")}

          <FormGroup>
            <label className="form-control-label" htmlFor={`input-start`}>
              Issued Date
            </label>
            <DatePicker
              value={date}
              onChange={setDate}
              shouldHighlightWeekends
              wrapperClassName="form-control"
              inputPlaceholder=" "
              calendarPopperPosition="bottom"
              formatInputText={() =>
                date
                  ? moment(
                      date.year + "-" + date.month + "-" + date.day
                    ).format("yyyy-MM-DD")
                  : ""
              }
            />
          </FormGroup>
          {createInputs("desc", "Description", "Description", "textarea")}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
