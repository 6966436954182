import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Collapse,
  Button,
  Col,
  CardFooter,
  Spinner,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";
import OverlayLoader from "consts/OverlayLoader";
import PaymentMethods from "./PaymentMethod/PaymentMethods";
import Transactions from "./Sections/Transactions";
import Plans from "./Sections/Plans";
import Subscription from "./Sections/Subscription";
export default function Billing(props) {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const location = useLocation();
  const url_prefix = "";
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [plans, setPlans] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const getItems = () => {
    setLoading(true);
    axiosConfig
      .get(url_prefix + "billing/index")
      .then((res) => {
        setLoading(false);
        setUser(res.data.result.user);
        setPlans(res.data.result.plans);
        setSubscription(res.data.result.subscription);
        setTransactions(res.data.result.payments);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getItems();
  }, []);

  const renderPlansOrSubscription = () => {
    if (user.organization_id) {
      return (
        <Card style={{ minHeight: 315 }}>
          <CardBody className="d-flex ai-center jc-center">
            <h2 className="text-center">
              Your subscription is being handled by your organization.
            </h2>
          </CardBody>
        </Card>
      );
    }
    if (user.subscription_status === "active") {
      return (
        <Subscription
          subscription={subscription}
          user={user}
          getItems={getItems}
        />
      );
    } else {
      return <Plans plans={plans} getItems={getItems} />;
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
      <ListHeader name="Billing" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row className="billing-step">
          <Col lg={5}>
            <PaymentMethods user={user} getItems={getItems} />
          </Col>
          <Col lg={7}>{renderPlansOrSubscription()}</Col>
          <Col lg={12}>
            <Transactions transactions={transactions} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
