import React from "react";
import { Button, Col } from "reactstrap";

export default function UserProfileEditButtonWidget(props) {
  if (props.editable) {
    return (
      <Col className="text-right" xs="4">
        <Button
          className="btn btn-link"
          onClick={(e) => props.setShowModal(true)}
          size="sm"
        >
          <i className="fa fa-pen mr-1" />
          Edit
        </Button>
      </Col>
    );
  }
}
