import React from "react";
// reactstrap components
import { Input, Row, Col } from "reactstrap";
// core components

export default function AuthLayout(props) {
  return (
    <div>
      <Row>
        {props.children}
        <Col lg={5} className="d-none d-lg-block d-sm-none">
          <div className="login-right">
            <div className="d-flex flex-column jc-center ai-center login-right-wrapper">
              <h3>It has never been easier to expand your network!</h3>
              <div className="d-flex flex-row login-right-slog-wrapper">
                <div className="login-right-slog">
                  <h4>Your Network</h4>
                  <h4>Your Rules</h4>
                </div>
                <div className="login-right-line"></div>
                <img
                  src={require("./../../../../assets/img/LN-logo.png")}
                  alt="Lien Networks"
                  height={50}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="login-bg-footer px-6 pt-4 pb-6">
        <Row>
          <Col lg={2} md={6} sm={6} xs={6} className="text-center mt-4">
            <img
              src={require("./../../../../assets/img/LN-light-footer.png")}
              alt="Lien Networks"
              height={50}
            />
          </Col>
          <Col lg={2} md={6} sm={6} xs={6} className="mt-4">
            <span className="text-light text-bold">Follow us</span>
            <div className="d-flex flex-row mt-2">
              <a
                href="https://www.instagram.com/liennetworks/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("./../../../../assets/img/icons/social/instagram.png")}
                  alt="Lien Networks"
                  height={25}
                />
              </a>
              <a
                href="https://www.facebook.com/LienNetworks"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("./../../../../assets/img/icons/social/facebook.png")}
                  alt="Lien Networks"
                  height={25}
                  className="px-1"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/lien-networks/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("./../../../../assets/img/icons/social/lin.png")}
                  alt="Lien Networks"
                  height={25}
                  className="px-1"
                />
              </a>
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} xs={12} className="mt-4">
            <span className="text-light text-bold">Contact us</span>
            <div className="d-flex flex-column mt-2">
              <a href="tel:3107361397" className="text-light mt-2">
                310-736-1397
              </a>
              <a href="mailto:info@liennetworks.com" className="text-light">
                info@liennetworks.com
              </a>
              <a className="text-light mt-2">
                21731 Ventura Blvd., Suite 205, Woodland Hills, California
                91364, US
              </a>
            </div>
          </Col>
          <Col lg={5} md={12} sm={12} xs={12} className="mt-4">
            <span className="text-light text-bold">Subscribe</span>
            <p className="text-light mt-2 fs-13">
              Join our newsletter to stay up to date on features and releases.
            </p>
            <Row>
              <Col lg={9}>
                <Input
                  placeholder="Enter your email"
                  type="text"
                  className="d-block"
                />
              </Col>
              <Col lg={3}>
                <a
                  block
                  className="btn btn-default btn-block btn-subscribe"
                  type="submit"
                  href="register"
                >
                  Subscribe
                </a>
              </Col>
            </Row>
            <br />
            <span className="text-light fs-13">
              By subscribing you agree to with our Privacy Policy and provide
              consent to receive updates from our company.
            </span>
          </Col>
        </Row>
        <hr color="#f1f1f1" />

        <div className="text-center">
          <span className="text-light fs-13 mr-3 text-center">
            2023 Lien Networks. All right reserved.
          </span>
        </div>
        <Row className="text-center">
          <Col lg={12} md={12}>
            <a href="privacy" className="text-light fs-13 mr-3">
              Privacy Policy
            </a>
            <a href="terms" className="text-light fs-13 mr-3">
              Terms of Service
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}
