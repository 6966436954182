import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import Swal from "sweetalert2";
import { removeFromArray } from "consts/Constants";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();
export default function AddCustomFieldModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [type, setType] = useState("text");
  const onSubmit = (data) => {
    var url = "admin/cases/custom_fields/store";
    if (props.currentModel?.id) {
      url = "admin/cases/custom_fields/update/" + props.currentModel.id;
    }
    data.options = options.length ? options : [];
    data.type = type;
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.setCurrenModel({});
          props.getItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset(props?.currentModel);
    setOptions(props.currentModel.options ? props.currentModel.options : []);
    setType(props.currentModel.type ? props.currentModel.type : "text");
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };

  const addOptionSwal = async () => {
    const { value: option } = await Swal.fire({
      title: "Options",
      input: "text",
      inputLabel: "Options",
      inputPlaceholder: "Enter...",
    });
    if (option) {
      setOptions([...options, option]);
    }
  };
  const removeItem = (item, setNewItem, items) => {
    setNewItem(removeFromArray(items, item));
  };
  const renderItems = (items, setNewItem) => {
    if (items) {
      if (items.length) {
        return items.map((item) => {
          return (
            <div
              className="d-flex flex-row jc-space-between ai-center multiple-tag"
              key={item}
            >
              <span>{item}</span>
              <i
                className="fa fa-times"
                onClick={() => removeItem(item, setNewItem, items)}
              />
            </div>
          );
        });
      }
    } else {
      return <span>No options yet!</span>;
    }
  };
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            <Col lg={12}>{createInputs("name", "Name", "Name")}</Col>
            <Col lg={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-start`}>
                  Type
                </label>
                <Input
                  type="select"
                  disabled={loading}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="text">Text</option>
                  <option value="textarea">Textarea</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="radio">Radio Button</option>
                  <option value="select">Select</option>
                  <option value="date">Date</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={12}>
              {type == "select" || type == "checkbox" || type == "radio" ? (
                <FormGroup>
                  <label className="form-control-label">Options</label>
                  <Button
                    size="sm"
                    color="link"
                    className="ml-2"
                    onClick={() => {
                      addOptionSwal();
                    }}
                  >
                    Add Option
                  </Button>
                  <br />
                  <div className="d-flex flex-row flex-wrap">
                    {renderItems(options, setOptions)}
                  </div>
                </FormGroup>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                props.setCurrenModel({});
                props.toggleModal(false);
              }}
            >
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
