import { removeWidgetItem } from "consts/Constants";
import { currencyFormatter } from "consts/Constants";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import FilesUploadModal from "./FilesUploadModal";

export default function CaseFilesSection(props) {
  const [currentModel, setCurrentModel] = useState({});
  const [currentServiceId, setCurrentServiceId] = useState(null);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const removeItem = (id) => {
    removeWidgetItem("organization/cases/delete_file/" + id, props.getItem);
  };
  const renderFiles = () => {
    if (props.caseData.files) {
      if (props.caseData.files.length) {
        return props.caseData.files.map((file) => {
          return (
            <tr>
              <td>
                {file.category}
                <br />
                <a color="btn btn-link" href={file.file_url}>
                  Download
                </a>
              </td>
              <td>{file.notes}</td>
              <td>
                {props.functions.files ? (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          removeItem(file.id);
                        }
                      })
                    }
                  >
                    <i className="fa fa-trash" />
                  </button>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          );
        });
      }
    }
    return (
      <tr>
        <td colSpan={4} className="text-center">
          No Items
        </td>
      </tr>
    );
  };
  return (
    <Card>
      {props.functions.files ? (
        <FilesUploadModal
          showModal={showFileUploadModal}
          toggleModal={setShowFileUploadModal}
          case_id={props.caseData.id}
          getItem={props.getItem}
          title={`Attachment`}
        />
      ) : (
        <></>
      )}
      <CardHeader>
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>Case Files</h3>

          {props.functions.files ? (
            <button
              className="btn btn-link btn-md"
              onClick={() => {
                setShowFileUploadModal(true);
              }}
            >
              <i className="fa fa-plus" /> Add New File
            </button>
          ) : (
            <></>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderFiles()}</tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
