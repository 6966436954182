import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";
import OverlayLoader from "consts/OverlayLoader";
import FilterModal from "./FilterModal";
import BadgeIcon from "assets/svg/Badge";
import Pagination from "react-js-pagination";
import moment from "moment";
import DownloadModal from "views/pages/components/DownloadModal";

const { SearchBar } = Search;

export default function SubsReports(props) {
  const query = new URLSearchParams(props.location.search);

  const [loading, setLoading] = useState(false);
  const [subs, setSubs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [renew, setRenew] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [userType, setUserType] = useState("");
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [utm, setUtm] = useState("");
  const [verified, setVerified] = useState("");
  const [badged, setBadged] = useState("");
  const [sort, setSort] = useState(null);
  const [sort_type, setSortType] = useState(null);
  const [filterisOn, setFilterIsOn] = useState(false);
  const [url, setUrl] = useState("");

  const searchItems = (
    pageNum = 1,
    exportFile = false,
    s = null,
    stype = null
  ) => {
    setSort(s);
    setSortType(stype);
    setFilterIsOn(true);
    window.scrollTo(0, 0);
    var from = fromDate
      ? moment(
          fromDate.year + "-" + fromDate.month + "-" + fromDate.day
        ).format("yyyy-MM-DD")
      : "";
    var to = toDate
      ? moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
          "yyyy-MM-DD"
        )
      : "";
    setLoading(true);
    setShowModal(false);
    axiosConfig
      .get(
        `admin/reports/subs?page=${pageNum}${name ? "&name=" + name : ""}${
          user ? "&user=" + user : ""
        }${renew ? "&renew=" + renew : ""}${cancel ? "&cancel=" + cancel : ""}${
          userType ? "&type=" + userType : ""
        }${from ? "&start=" + from : ""}${to ? "&end=" + to : ""}${
          exportFile ? "&export=1" : ""
        }${s ? "&sort=" + s : ""}${stype ? "&sort_type=" + stype : ""}`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setSubs(res.data.result.subs);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const resetFilter = () => {
    setFilterIsOn(false);
    setFromDate(null);
    setToDate(null);
    setUserType(null);
    setKeyWord("");
    setUtm(null);
    setVerified("");
    setBadged("");
    setName(null);
    setUser(null);
    setRenew(null);
    setCancel(null);
  };
  const getItems = (pageNum, exportFile = false) => {
    resetFilter();
    searchItems(1, false);
  };
  useEffect(() => {
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (subs?.data) {
      return subs?.data.map((tr, index) => {
        return (
          <tr
            key={tr.id}
            className={`text-center ${tr.verified ? "table-success" : ""}`}
          >
            <td>{tr.name}</td>
            <td>
              {tr.entity?.fullname ? tr.entity?.fullname : tr.entity?.name}
            </td>
            <td>{tr.entity?.type_name ? tr.entity?.type_name : ""}</td>
            <td>{tr.entity?.email}</td>
            <td>{tr.entity?.phone}</td>
            <td>{tr.auto_renew ? "Enabled" : "Disabled"}</td>
            <td>{tr.cancel ? "Yes" : "No"}</td>
            <td>{tr.expires_at}</td>
          </tr>
        );
      });
    }
  };
  const renderActiveFilters = () => {
    if (!showModal)
      return (
        <>
          {user ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              User: {user}
            </Button>
          ) : (
            <></>
          )}
          {name ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Plan Name: {name}
            </Button>
          ) : (
            <></>
          )}
          {userType ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              User Type: {userType}
            </Button>
          ) : (
            <></>
          )}

          {renew ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              {renew === "1" ? "Auto Renew Enabled" : "Auto Renew Disabled"}
            </Button>
          ) : (
            <></>
          )}
          {cancel ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              {cancel === "1" ? "Canceled" : "Not Canceled"}
            </Button>
          ) : (
            <></>
          )}
          {fromDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Since:{" "}
              {fromDate
                ? moment(
                    fromDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {toDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Registered Until:{" "}
              {toDate
                ? moment(
                    toDate.year + "-" + toDate.month + "-" + toDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {filterisOn ||
          renew ||
          cancel ||
          name ||
          user ||
          userType ||
          fromDate ||
          toDate ? (
            <Button color="link" size="sm" onClick={() => getItems()}>
              Reset
            </Button>
          ) : (
            <></>
          )}
        </>
      );
  };

  const exportFile = () => {
    if (filterisOn) {
      searchItems(1, true);
    } else {
      getItems(1, true);
    }
  };
  return (
    <>
      <FilterModal
        showModal={showModal}
        toggleModal={setShowModal}
        name={name}
        setName={setName}
        user={user}
        setUser={setUser}
        renew={renew}
        setRenew={setRenew}
        cancel={cancel}
        setCancel={setCancel}
        filterItems={searchItems}
        title="Filter"
        query={query}
        loading={loading}
        userType={userType}
        setUserType={setUserType}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        utm={utm}
        setUtm={setUtm}
        verified={verified}
        setVerified={setVerified}
        badged={badged}
        setBadged={setBadged}
      />
      <ListHeader
        name="Subscriptions Report"
        parentName="Dashboard"
        setShowModal={setShowModal}
        btnName="Filter"
        setExtraButton={() => exportFile()}
        extraBtnName="Export"
      />
      <DownloadModal
        showModal={downloadModal}
        toggleModal={setDownloadModal}
        url={url}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Subscriptions</h3>
              </CardHeader>

              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1 mt-4"
              >
                {renderActiveFilters()}
              </div>
              <Table striped responsive>
                {loading ? (
                  <div className="overlay-loading d-flex jc-center pt-3">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <></>
                )}
                <thead>
                  <tr>
                    <th>
                      Plan Type{" "}
                      <i
                        className="fa fa-sort"
                        onClick={() => {
                          searchItems(
                            1,
                            false,
                            "name",
                            sort_type === "ASC" ? "DESC" : "ASC"
                          );
                        }}
                      ></i>
                    </th>
                    <th>User</th>
                    <th>Account Type</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>
                      Auto Renew
                      <i
                        className="fa fa-sort"
                        onClick={() => {
                          searchItems(
                            1,
                            false,
                            "auto_renew",
                            sort_type === "ASC" ? "DESC" : "ASC"
                          );
                        }}
                      ></i>
                    </th>
                    <th>
                      Canceled
                      <i
                        className="fa fa-sort"
                        onClick={() => {
                          searchItems(
                            1,
                            false,
                            "cancel",
                            sort_type === "ASC" ? "DESC" : "ASC"
                          );
                        }}
                      ></i>
                    </th>
                    <th>
                      Expires At
                      <i
                        className="fa fa-sort"
                        onClick={() => {
                          searchItems(
                            1,
                            false,
                            "expires_at",
                            sort_type === "ASC" ? "DESC" : "ASC"
                          );
                        }}
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                <div>
                  <p>
                    Showing {subs?.from}-{subs?.to} from {subs?.total} results
                  </p>
                </div>
                <Pagination
                  activePage={subs?.current_page ? subs?.current_page : 0}
                  itemsCountPerPage={subs?.per_page ? subs?.per_page : 0}
                  totalItemsCount={subs?.total ? subs?.total : 0}
                  onChange={(pageNumber) => {
                    filterisOn ? searchItems(pageNumber) : getItems(pageNumber);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
