import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row, Spinner } from "reactstrap";
import { removeFromArray } from "consts/Constants";
import axiosConfig from "consts/axiosConfig";
import UserCardComponent from "./UserCardComponent";
import { MultiSelect } from "react-multi-select-component";
import { states } from "consts/USSTATES";
import UDashChart from "views/pages/User/UserDashboard/UDashChart";

export default function SearchNetwork(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [name, setName] = useState("");
  const [stateName, setStateName] = useState([]);

  const getUsers = () => {
    setLoading(true);

    var areaList = [];
    var stateList = [];
    if (selectedAreas) {
      areaList = selectedAreas.map((a) => a.value);
    }
    if (stateName) {
      stateList = stateName.map((a) => a.value);
    }
    axiosConfig
      .post("network/search/", {
        entity_id: props.userType.entity_id,
        entity_type: props.userType.entity_type,
        areas: areaList.toString(),
        name: name,
        states: stateList.toString(),
      })
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result[props.userType.entity_type]);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const renderUsers = () => {
    if (users) {
      return Object.values(users).map((item) => {
        if (item.id !== JSON.parse(localStorage.getItem("account")).id) {
          return (
            <Col lg={4} key={item.id} className="mb-0">
              <UserCardComponent
                item={item}
                userType={props.userType}
                userIds={props.userIds}
                avatarData={props.avatarData}
              />
            </Col>
          );
        }
      });
    } else {
      return (
        <p className="text-muted text-center">
          No items found. Change your filter options and try again
        </p>
      );
    }
  };
  const getAreas = () => {
    var opts = [];
    if (props.selectedType.areas) {
      props.selectedType.areas.map((cl) => {
        opts.push({
          value: cl.id,
          label: cl.name,
        });
      });
    }

    return opts;
  };
  const getStates = () => {
    var opts = [];
    if (states) {
      states.map((cl) => {
        opts.push({
          value: cl.name,
          label: cl.name,
        });
      });
    }
    return opts;
  };
  return (
    <>
      <Row className="mt-4 ai-center">
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label">Name:</label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="mt-2"
            />
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label">State:</label>
            <MultiSelect
              options={getStates()}
              value={stateName}
              onChange={(values) => {
                setStateName(values);
              }}
              labelledBy="State"
              className="mt-2"
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <label className="form-control-label">
              {props.selectedType.area_plural}:
            </label>
            <MultiSelect
              options={getAreas()}
              value={selectedAreas}
              onChange={(values) => {
                setSelectedAreas(values);
              }}
              labelledBy={props.selectedType.area_plural}
              closeOnChangedValue={false}
              className="mt-2"
            />
          </FormGroup>
        </Col>
        <Col lg="2" className="mt-3">
          <Button
            color="primary"
            size="md"
            block
            onClick={() => {
              getUsers();
            }}
          >
            Filter
          </Button>
        </Col>
      </Row>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner color="#000" />
        </div>
      ) : users ? (
        <>
          <Row>{renderUsers()}</Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
