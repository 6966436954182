import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Wizard } from "react-use-wizard";
import StepStateArea from "./Steps/StepStateArea";
import StepPlan from "./Steps/StepPlan";
import StepConfirm from "./Steps/StepConfirm";

export default function PowerUpWizard(props) {
  const [areaId, setAreaId] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [resQuery, setResQuery] = useState(null);
  const [resPowerUp, setResPowerUp] = useState(null);
  const [plan, setPlan] = useState(null);
  return (
    <Wizard>
      <StepStateArea
        areaId={areaId}
        setAreaId={setAreaId}
        stateName={stateName}
        setStateName={setStateName}
        resQuery={resQuery}
        setResQuery={setResQuery}
        areas={props.areas}
        toggleModal={props.toggleModal}
      />
      <StepPlan
        areaId={areaId}
        setAreaId={setAreaId}
        stateName={stateName}
        setStateName={setStateName}
        resQuery={resQuery}
        setResQuery={setResQuery}
        areas={props.areas}
        toggleModal={props.toggleModal}
        plan={plan}
        setPlan={setPlan}
        resPowerUp={resPowerUp}
        setResPowerUp={setResPowerUp}
      />
      <StepConfirm
        areaId={areaId}
        setAreaId={setAreaId}
        stateName={stateName}
        setStateName={setStateName}
        resQuery={resQuery}
        setResQuery={setResQuery}
        areas={props.areas}
        toggleModal={props.toggleModal}
        plan={plan}
        setPlan={setPlan}
        resPowerUp={resPowerUp}
        setResPowerUp={setResPowerUp}
        getItems={props.getItems}
      />
    </Wizard>
  );
}
