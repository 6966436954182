import React, { useEffect, useState } from "react";
import axiosConfig from "consts/axiosConfig";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useWizard } from "react-use-wizard";

export default function CPStepThree(props) {
  const [loading, setLoading] = useState(false);
  const { previousStep, nextStep } = useWizard();
  return (
    <Card className="bg-secondary border-0">
      <CardBody>
        <h3 className="text-center">
          Do you want to join a {props.orgType} or you want to create one?
        </h3>
        <br />
        <div className="d-flex flex-row jc-center">
          <Button
            color="primary"
            onClick={() => {
              props.setIsCreate(false);
              nextStep();
            }}
          >
            Join {props.orgType}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.setIsCreate(true);
              nextStep();
            }}
          >
            Create {props.orgType}
          </Button>
        </div>
      </CardBody>
      <CardFooter>
        <Button color="info" onClick={() => previousStep()} disabled={loading}>
          Back
        </Button>
      </CardFooter>
    </Card>
  );
}
