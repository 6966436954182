import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import UserProfileEditButtonWidget from "../Components/UserProfileEditButtonWidget";
import ContactInformationModal from "./UserEditModals/ContactInformationModal";

export default function ContactInformationComponent(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal ? (
        <ContactInformationModal
          userData={props.userData}
          showModal={showModal}
          toggleModal={setShowModal}
          title={props.title}
          getProfile={props.getProfile}
          updateProfile={props.updateProfile}
        />
      ) : (
        <></>
      )}
      <Row className="align-items-center mb-4">
        <Col xs="8">
          <h6 className="heading-small text-muted mb-0">{props.title}</h6>
        </Col>
        <UserProfileEditButtonWidget
          setShowModal={setShowModal}
          editable={props.editable}
        />
      </Row>
      <div className="pl-lg-4">
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-address">
                Address
                {props.userData.lat ? (
                  <a
                    size="sm"
                    color="link"
                    target="_blank"
                    className="ml-2"
                    href={`https://maps.google.com/?q=${props.userData.lat},${props.userData.lng}`}
                  >
                    Show on map
                  </a>
                ) : (
                  <></>
                )}
              </label>
              <h5 className="h3">
                {props.userData.address ? props.userData.address : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-city">
                City
              </label>
              <h5 className="h3">
                {props.userData.city ? props.userData.city : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-country">
                State
              </label>
              <h5 className="h3">
                {props.userData.state ? props.userData.state : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-country">
                Zip Code
              </label>

              <h5 className="h3">
                {props.userData.zipcode ? props.userData.zipcode : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-country">
                Country
              </label>
              <h5 className="h3">
                {props.userData.country ? props.userData.country : "-"}
              </h5>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
