import React, { useEffect, useState } from "react";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useWizard } from "react-use-wizard";
import { APP_IMAGE_URL } from "consts/Constants";

export default function CPStepFour(props) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const { nextStep, previousStep } = useWizard();
  const [inviteCode, setInviteCode] = useState("");

  const saveOrganization = () => {
    setLoading(true);
    axiosConfig
      .post("store_organization", { name: name, website: website })
      .then((res) => {
        if (!res.data.hasError) {
          history.push("/user/profile");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const joinOrganization = () => {
    setLoading(true);
    axiosConfig
      .post("join_organization", { invite_code: inviteCode })
      .then((res) => {
        if (!res.data.hasError) {
          history.push("/user/profile");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Card className="bg-secondary border-0">
      <CardHeader>
        <h3 className="text-center">
          {props.isCreate ? `Create ${props.orgType}` : `Join ${props.orgType}`}
        </h3>
      </CardHeader>
      <CardBody>
        {props.isCreate ? (
          <Row className="justify-content-center mt-4">
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-notes`}
                    >
                      {props.orgType} Name
                    </label>
                    <Input
                      id={`input-notes`}
                      placeholder={props.orgType + " Name"}
                      type="text"
                      disabled={loading}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor={`input-notes`}
                    >
                      {props.orgType} Website
                    </label>
                    <Input
                      id={`input-notes`}
                      placeholder={props.orgType + " Website"}
                      type="text"
                      disabled={loading}
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col></Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor={`input-notes`}>
                  Your invitation code
                </label>
                <Input
                  id={`input-notes`}
                  placeholder={"Invitation Code"}
                  type="text"
                  disabled={loading}
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>
        )}
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-row jc-space-between ai-center">
          <Button
            color="info"
            onClick={() => previousStep()}
            disabled={loading}
          >
            Back
          </Button>

          <Button
            color="info"
            size="md"
            disabled={loading}
            onClick={() => {
              props.isCreate ? saveOrganization() : joinOrganization();
            }}
          >
            {props.isCreate ? "Save" : "Join"}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
