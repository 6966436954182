import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
export default function HookLogModal(props) {
  const renderLogs = () => {};

  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>{renderLogs}</ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.setCurrenModel({});
            props.toggleModal(false);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
