import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
export default function DownloadModal(props) {
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>Export</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <h2>Your export is ready.</h2>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => props.toggleModal(false)}>
          Close
        </Button>
        <Button color="primary" type="button" href={props.url}>
          Download
        </Button>
      </ModalFooter>
    </Modal>
  );
}
