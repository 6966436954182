import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { APP_IMAGE_URL } from "consts/Constants";
import { useWizard } from "react-use-wizard";
import axiosConfig from "consts/axiosConfig";

export default function StepTypes(props) {
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const { handleStep, previousStep, nextStep } = useWizard();
  const getTypes = () => {
    setLoading(true);
    axiosConfig
      .get("organization/refers/types")
      .then((res) => {
        setLoading(false);
        setOrganizations(res.data.result.organizations);
        setUsers(res.data.result.users);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getTypes();
  }, []);

  const renderTypes = (items, type) => {
    return items.map((item) => {
      return (
        <Card key={item.id}>
          <CardBody>
            <div className="text-center">
              <img
                className="img rounded"
                src={
                  item.avatar
                    ? APP_IMAGE_URL + item.avatar
                    : "https://liennetworks.com/wp-content/uploads/2023/01/Lien-Networks-Logo-Transparent-e1672931589303.png"
                }
                width={100}
                alt="."
              />
              <h3 className="mt-3">{item.name}</h3>
            </div>
            <p className="text-center">{item.description}</p>
          </CardBody>
          <div className="d-flex flex-row ai-center jc-center mb-3">
            <Button
              color="info"
              size="md"
              onClick={() => {
                props.prepareData(item.id, type);
                props.setAvatarData(
                  item.avatar
                    ? APP_IMAGE_URL + item.avatar
                    : "https://liennetworks.com/wp-content/uploads/2023/01/Lien-Networks-Logo-Transparent-e1672931589303.pnghttps://liennetworks.com/wp-content/uploads/2023/01/Lien-Networks-Logo-Transparent-e1672931589303.png"
                );
                props.setSelectedType(item);
                nextStep();
              }}
            >
              Pick
            </Button>
          </div>
        </Card>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <h3 className="text-center">Who do you want to refer to?</h3>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="text-center">
            <Spinner color="#000" />
          </div>
        ) : organizations.length || users.length ? (
          <Row>
            <Col>{renderTypes(organizations, "organizations")}</Col>
            <Col>{renderTypes(users, "users")}</Col>
          </Row>
        ) : (
          <></>
        )}
      </CardBody>
    </Card>
  );
}
