import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Card, CardBody, CardHeader, Col, Progress, Row } from "reactstrap";
import { APP_IMAGE_URL } from "consts/Constants";
export default function ProfileProgress(props) {
  if (!props.coudBeRendered) {
    return;
  }
  return (
    <Card className="card-profile">
      <CardHeader>
        <h3 className="mb-0 text-center">{props.title}</h3>
      </CardHeader>
      <CardBody className="p-4 sortable-list">
        <Row>
          <Col lg={12}>
            <div className="text-muted text-center mb-2">{props.progress}%</div>
            <Progress
              value={props.progress}
              color="success"
              style={{ height: 20 }}
            />
            <div className="text-center d-flex flex-row jc-center">
              <h3 className="mr-2">Next Step:</h3>
              <p>{props.progressItems ? props.progressItems[0] : ""}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
