import AdminCaseCustomFields from "views/pages/Admin/AdminCaseCustomFields";
import AdminCases from "views/pages/Admin/AdminCases";
import AdminClientCustomFields from "views/pages/Admin/AdminClientCustomFields";
import AdminClients from "views/pages/Admin/AdminClients";
import AdminDashboard from "views/pages/Admin/AdminDashboard";
import AdminImports from "views/pages/Admin/AdminImports";
import AdminLeads from "views/pages/Admin/AdminLeads";
import AdminOrganizations from "views/pages/Admin/AdminOrganizations";
import AdminPlans from "views/pages/Admin/AdminPlans";
import AdminReferrals from "views/pages/Admin/AdminReferrals";
import RefersReports from "views/pages/Admin/AdminReports/RefersReports";
import SubsReports from "views/pages/Admin/AdminReports/SubsReports";
import AdminUsers from "views/pages/Admin/AdminUsers";
import AdminVouchers from "views/pages/Admin/AdminVouchers";
import AdminLeadsRefers from "views/pages/Admin/AdminLeadsRefers";
import OrganizationPublicProfile from "views/pages/Profiles/OrganizationPublicProfile";
import UserPublicProfile from "views/pages/Profiles/UserPublicProfile";
import ShowCase from "views/pages/User/UserCases/Case/ShowCase";
import Announcements from "views/pages/Admin/Announcements";
import RegisteredUsers from "views/pages/Admin/AdminReports/RegisteredUsers";
import ClientCases from "views/pages/Admin/AdminClients/ClientCases";
import UnRegisteredUsers from "views/pages/Admin/AdminReports/UnRegisteredUsers";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-chart-line text-primary",
    component: AdminDashboard,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-chart-line text-primary",
    component: AdminUsers,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/organizations",
    name: "Organizations",
    icon: "fa fa-chart-line text-primary",
    component: AdminOrganizations,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/clients",
    name: "Clients",
    icon: "fa fa-users text-primary",
    component: AdminClients,
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/clients/:uuid",
    name: "Clients",
    component: ClientCases,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: false,
  },
  {
    path: "/cases/:uuid",
    name: "Case",
    component: ShowCase,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: false,
  },
  {
    path: "/cases",
    name: "Cases",
    component: AdminCases,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: Announcements,
    icon: "fa fa-podcast text-primary",
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/refers",
    name: "Refers",
    component: AdminReferrals,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/plans",
    name: "Plans",
    component: AdminPlans,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: true,
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    component: AdminVouchers,
    icon: "fa fa-file-invoice text-primary",
    layout: "/admin",
    showInMenu: true,
  },
  {
    collapse: true,
    name: "Leads",
    icon: "fa fa-globe text-primary",
    state: "leadsCollapse",
    showInMenu: true,
    views: [
      {
        path: "/imports",
        name: "Imports",
        component: AdminImports,
        miniName: "G",
        layout: "/admin",
        showInMenu: true,
      },
      {
        path: "/leads",
        name: "Leads",
        component: AdminLeads,
        miniName: "G",
        layout: "/admin",
        showInMenu: true,
      },
      {
        path: "/rleads",
        name: "Refers",
        component: AdminLeadsRefers,
        miniName: "G",
        layout: "/admin",
        showInMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Custom Fields",
    icon: "fa fa-exchange-alt text-primary",
    state: "refersCollapse",
    showInMenu: true,
    views: [
      {
        path: "/fields/client",
        name: "Client",
        component: AdminClientCustomFields,
        miniName: "C",
        layout: "/admin",
        showInMenu: true,
      },
      {
        path: "/fields/case",
        name: "Case",
        component: AdminCaseCustomFields,
        miniName: "C",
        layout: "/admin",
        showInMenu: true,
      },
    ],
  },

  {
    path: "/u/:user_id",
    name: "User Profile",
    component: UserPublicProfile,
    icon: "fa fa-credit-card text-primary",
    layout: "/admin",
    showInMenu: false,
  },
  {
    path: "/o/:user_id",
    name: "Organization Profile",
    component: OrganizationPublicProfile,
    icon: "fa fa-credit-card text-primary",
    layout: "/admin",
    showInMenu: false,
  },
  {
    collapse: true,
    name: "Reports",
    icon: "fa fa-chart-line text-primary",
    state: "reportsCollapse",
    showInMenu: true,
    views: [
      {
        path: "/reports/subscriptions",
        name: "Subscriptions",
        component: SubsReports,
        miniName: "G",
        layout: "/admin",
        showInMenu: true,
      },
      {
        path: "/reports/referrals",
        name: "Referrals",
        component: RefersReports,
        miniName: "G",
        layout: "/admin",
        showInMenu: false,
      },
      {
        path: "/reports/registeredusers",
        name: "Verified Users",
        component: RegisteredUsers,
        miniName: "G",
        layout: "/admin",
        showInMenu: false,
      },
      {
        path: "/reports/unregisteredusers",
        name: "Unregistered Users",
        component: UnRegisteredUsers,
        miniName: "G",
        layout: "/admin",
        showInMenu: false,
      },
    ],
  },
];

export default routes;
