import React, { useState } from "react";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { CardBody, Card as BSCARD, Col, Row, CardHeader } from "reactstrap";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
export default function PaymentMethods(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <BSCARD>
      <AddPaymentMethodModal
        showModal={showModal}
        toggleModal={setShowModal}
        getItems={props.getItems}
      />
      <CardHeader>
        <div className="d-flex flex-row jc-space-between ai-center">
          <h3>Payment Method</h3>
          <button
            className="btn btn-link btn-md"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <i className="fa fa-plus" />
            Add Payment Method
          </button>
        </div>
      </CardHeader>
      <CardBody>
        <div id="PaymentForm text-center">
          <Card
            cvc="***"
            expiry={
              props.user.card_month
                ? props.user.card_month + "/" + props.user.card_year
                : ""
            }
            focused=""
            name={props.user.card_name ? props.user.card_name : ""}
            number={
              props.user.card_digits
                ? "************" + props.user.card_digits
                : ""
            }
            issuer={props.user.card_brand ? props.user.card_brand : ""}
            preview={true}
          />
        </div>
      </CardBody>
    </BSCARD>
  );
}
