import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { APP_IMAGE_URL, removeFromArray } from "consts/Constants";
import axiosConfig from "consts/axiosConfig";
import UserCardComponent from "./UserCardComponent";

export default function MyNetwork(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const getTypes = () => {
    setLoading(true);
    axiosConfig
      .get("network/index")
      .then((res) => {
        setLoading(false);
        setUsers(res.data.result.following);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getTypes();
  }, []);

  const renderUsers = () => {
    return users.map((item) => {
      return (
        <Col lg={4} key={item.id}>
          <UserCardComponent
            item={item}
            userType={props.userType}
            pickRemove={pickRemove}
            userIds={props.userIds}
            avatarData={props.avatarData}
          />
        </Col>
      );
    });
  };
  const pickRemove = (id) => {
    if (props.userIds.includes(id)) {
      var ui = props.userIds;
      props.setUserIds(removeFromArray(ui, id));
    } else {
      props.setUserIds([...props.userIds, id]);
    }
  };
  return (
    <>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner color="#000" />
        </div>
      ) : users.length || users.length ? (
        <Row>{renderUsers()}</Row>
      ) : (
        <p className="text-muted text-center mt-4">
          Looks like, your network list is empty!
        </p>
      )}
    </>
  );
}
