import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
  NavLink,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import { useWizard } from "react-use-wizard";

export default function StepStateArea(props) {
  const [loading, setLoading] = useState(false);
  const { handleStep, previousStep, nextStep } = useWizard();

  const submitQuery = (data) => {
    var url = "user/powerups/query";
    setLoading(true);
    axiosConfig
      .post(url, { area_id: props.areaId, state: props.stateName })
      .then((res) => {
        if (!res.data.hasError) {
          props.setResQuery(res.data.result);
          nextStep();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col lg={6}>
        <FormGroup>
          <label className="form-control-label">Practice Area</label>
          <Select2
            onChange={(e) => props.setAreaId(e.target.value)}
            value={props.areaId}
            className="form-control"
            options={{
              placeholder: "Practice Area",
            }}
            data={
              props.areas
                ? props.areas.map((cl) => {
                    return {
                      id: cl.id,
                      text: cl.name,
                    };
                  })
                : []
            }
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup>
          <label className="form-control-label">State</label>
          <Select2
            onChange={(e) => props.setStateName(e.target.value)}
            value={props.stateName}
            className="form-control"
            options={{
              placeholder: "State",
            }}
            data={
              states
                ? states.map((cl) => {
                    return {
                      id: cl.name,
                      text: cl.name,
                    };
                  })
                : []
            }
          />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <Button
          disabled={loading}
          color="secondary"
          type="button"
          onClick={() => props.toggleModal()}
        >
          Discard
        </Button>
      </Col>
      <Col lg={6}>
        <Button
          className="float-right"
          disabled={loading}
          color="primary"
          type="button"
          onClick={() => submitQuery()}
        >
          Next
        </Button>
      </Col>
    </Row>
  );
}
