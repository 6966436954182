import React from "react";
// reactstrap components
import { Col } from "reactstrap";
import AuthLayout from "../AuthLayout";
function Privacy() {
  return (
    <AuthLayout>
      <Col lg={7} md={12}>
        <div className="d-flex flex-column ai-center jc-center h-100 w-100 text-center">
          <div className="text-center w-70">
            <h1>Privacy Policy</h1>
            <br />
            <h3>Lien Networks Privacy Policy Last updated: May 22, 2023</h3>
            <br />
            <p className="text-justify">
              Lien Networks (“we”, “us”, or “our”) respects your privacy and is
              committed to protecting it through this Privacy Policy. This
              Privacy Policy governs your access to and use of
              www.liennetworks.com, including any content, functionality and
              services offered on or through www.liennetworks.com (the
              "Website"), whether as a guest or a registered user. When
              accessing the Website, we will learn certain information about
              you, both automatically and through voluntary actions you may
              take, during your visit. This policy applies to information we
              collect on the Website and in email or other electronic messages
              between you and the Website. Please read the Privacy Policy
              carefully before you start to use the Website. By using the
              Website or by clicking to accept or agree to the Terms of Use when
              this option is made available to you, you accept and agree to be
              bound and abide by the Privacy Policy. 1. Information We Collect
              About You When you access the Website, the Lien Networks will
              learn certain information about you during your visit. The
              information that we collect and store relating to you is primarily
              used to enable us to provide our services to you, and to meet our
              contractual commitments to you. 2. Use of Cookies Similar to other
              commercial websites, our website utilizes a standard technology
              called "cookies" and web server logs to collect information about
              how our website is used. Information gathered through cookies and
              web server logs may include the date and time of visits, the pages
              viewed, time spent at our website, and the websites visited just
              before and just after our website. 3. How We Use Your Information
              We use the information that we collect about you or that you
              provide to us, including any personal information: To provide you
              with the Website and its services, and any other information,
              products or services that you request from us; to fulfill any
              other purpose for which you provide it; to notify you about
              changes to our Website or any products or services we offer or
              provide though it. 4. Disclosure of Your Information We do not
              sell, rent, or lease our customer lists to third parties. We may
              disclose your personal information to our subsidiaries,
              affiliates, and service providers for the purpose of providing our
              services to you. 5. Changes to Our Privacy Policy It is our policy
              to post any changes we make to our Privacy Policy on this page. If
              we make material changes to how we treat our users' personal
              information, we will notify you through a notice on the Website
              home page. The date the Privacy Policy was last revised is
              identified at the top of the page. You are responsible for
              ensuring we have an up-to-date active and deliverable email
              address for you, and for periodically visiting our Website and
              this Privacy Policy to check for any changes.
            </p>
          </div>
        </div>
      </Col>
    </AuthLayout>
  );
}

export default Privacy;
