import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
export default function ApiKeyModal(props) {
  const [showAPI, setShowAPI] = useState(false);
  const [copied, setCopied] = useState(false);
  return (
    <Modal isOpen={props.showModal} size="lg">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <span>Please use this code to connect your Zapier app:</span>
        <Alert color="primary" className="text-center mt-3">
          <h2 className="text-white">
            {showAPI ? props.apiKey : props.apiKey.slice(0, 4) + "############"}
          </h2>
          {/* <span
            className="btn btn-link text-light"
            onClick={() => setShowAPI(!showAPI)}
          >
            {showAPI ? "Hide API key" : "Reveal API key"}
          </span> */}
          <span
            className="btn btn-link text-light"
            onClick={() => {
              navigator.clipboard.writeText(props.apiKey);
              setCopied(true);
            }}
          >
            {copied ? "Copied" : "Copy"}
          </span>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.toggleModal(false);
            setCopied(false);
            setShowAPI(false);
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.getApiKey({});
            setCopied(false);
            setShowAPI(false);
          }}
        >
          Renew
        </Button>
      </ModalFooter>
    </Modal>
  );
}
