import React from "react";
import { Container } from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import "react-datepicker/dist/react-datepicker.css";
import WizardController from "./WizardController";

export default function ReferWizard({ match }) {
  return (
    <>
      <ListHeader parentName={"Case Referral"} />
      <Container className="mt--6" fluid>
        <WizardController case_id={match.params.case_id} />
      </Container>
    </>
  );
}
