import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function OrganizationProfileHeader(props) {
  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "200px",
        }}
      >
        <span className="mask bg-gradient-default opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">
                {props.userData ? props.userData.name : ""}
              </h1>
              <p className="text-white mt-0 mb-5">
                {props.userData ? props.userData.about : ""}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OrganizationProfileHeader;
