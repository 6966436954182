import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import { Lang } from "consts/localization";
import { useHistory, useLocation } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

const schema = yup
  .object({
    name: yup.string().required(Lang.emptyFieldError),
    client_id: yup.string().required(),
  })
  .required();
export default function AddEditCaseModal(props) {
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(false);
  const [date, setDate] = useState(null);

  const onSubmit = (data) => {
    setLoading(true);
    axiosConfig
      .post("organization/cases/store/" + data.client_id, {
        name: data.name,
        date: moment(date.year + "-" + date.month + "-" + date.day).format(
          "yyyy-MM-DD"
        ),
        status: "Open",
        completed: 0,
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          history.push(location.pathname + "/" + res.data.result.case.id);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getClients = () => {
    setLoading(true);
    axiosConfig
      .get("organization/clients/" + props.url_postfix)
      .then((res) => {
        setLoading(false);
        setClients(res.data.result.clients);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getClients();
  }, []);

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup key={name}>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal} size="md">
      <ModalHeader>{props.title}</ModalHeader>
      {loading ? (
        <div className="d-flex flex-row jc-center ai-center m-4">
          <Spinner />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Row>
              <Col lg={6}>{createInputs("name", "Name", "Case Name")}</Col>
              <Col lg={6}>
                <FormGroup>
                  <label className="form-control-label">Date</label>
                  <DatePicker
                    value={date}
                    onChange={setDate}
                    shouldHighlightWeekends
                    wrapperClassName="form-control"
                    inputPlaceholder=" "
                    calendarPopperPosition="bottom"
                    formatInputText={() =>
                      date
                        ? moment(
                            date.year + "-" + date.month + "-" + date.day
                          ).format("yyyy-MM-DD")
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <label className="form-control-label">Client</label>
                  <Controller
                    control={control}
                    name={"client_id"}
                    render={({ field }) => (
                      <Select2
                        onChange={(id) => field.onChange(id)}
                        value={field.value}
                        className="form-control"
                        options={{
                          placeholder: "Client",
                        }}
                        data={
                          clients
                            ? clients.map((cl) => {
                                return {
                                  id: cl.id,
                                  text: cl.firstname + " " + cl.lastname,
                                };
                              })
                            : []
                        }
                      />
                    )}
                  />
                  <FormText color="danger">
                    {errors.client_id?.message}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          {loading ? (
            <ModalFooter>
              <div
                className="d-flex ai-center jc-center"
                style={{ width: "100%" }}
              >
                <Spinner animation="border" />
              </div>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => props.toggleModal(false)}
              >
                Discard
              </Button>
              <Button disabled={loading} color="primary" type="submit">
                Next
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Modal>
  );
}
