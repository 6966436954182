import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { removeFromArray } from "consts/Constants";
import { Lang } from "consts/localization";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select2 from "react-select2-wrapper";
import { states } from "consts/USSTATES";
import { formatPhoneNumber } from "consts/Constants";

const schema = yup
  .object({
    firstname: yup.string().required(Lang.emptyFieldError),
    lastname: yup.string().required(),
    address: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    country: yup.string().nullable(),
    zipcode: yup.string().nullable(),
    notes: yup.string().nullable(),
  })
  .required();
export default function AddEditClientModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(true);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  const getCustomFields = () => {
    var url = props.url_perfix + "create";
    if (props.currentModel?.id) {
      url = props.url_perfix + "show/" + props.currentModel.id;
    }
    setLoading(true);
    axiosConfig
      .get(url)
      .then((res) => {
        setLoading(false);
        setCustomFields(res.data.result.custom_fields);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    var url = props.url_perfix + "store";
    if (props.currentModel?.id) {
      url = props.url_perfix + "update/" + props.currentModel.id;
    }
    data.phones = phones.length ? phones : null;
    data.emails = emails.length ? emails : null;
    setLoading(true);
    axiosConfig
      .post(url, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getClients();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomFields();
    var values = props?.currentModel;
    if (values) {
      values.custom_fields = values.cf_data;
    }
    reset(values);

    setPhones(props.currentModel.phones ? props.currentModel.phones : []);
    setEmails(props.currentModel.emails ? props.currentModel.emails : []);
  }, [props.currentModel, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup key={name}>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };

  const addPhoneSwal = async () => {
    const { value: phone } = await Swal.fire({
      title: "Phone",
      input: "text",
      inputLabel: "Phone Number",
      inputPlaceholder: "Enter...",
    });
    if (phone) {
      setPhones([...phones, phone]);
    }
  };
  const addEmailSwal = async () => {
    const { value: email } = await Swal.fire({
      title: "Email",
      input: "email",
      inputLabel: "Email Address",
      inputPlaceholder: "Enter...",
    });
    if (email) {
      setEmails([...emails, email]);
    }
  };
  const removeItem = (item, setNewItem, items) => {
    setNewItem(removeFromArray(items, item));
  };
  const renderItems = (items, setNewItem, isPhone) => {
    if (items) {
      if (items.length) {
        return items.map((item) => {
          return (
            <div
              className="d-flex flex-row jc-space-between ai-center multiple-tag"
              key={item}
            >
              <span>{isPhone ? formatPhoneNumber(item) : item}</span>
              <i
                className="fa fa-times"
                onClick={() => removeItem(item, setNewItem, items)}
              />
            </div>
          );
        });
      }
    } else {
      return <span>No items</span>;
    }
  };
  const renderCustomFields = () => {
    if (customFields.length) {
      return customFields.map((cf) => {
        if (cf.type === "text") {
          return (
            <Col key={`element-${cf.id}`} lg={12}>
              {createInputs(
                `custom_fields[element-${cf.id}]`,
                cf.name,
                cf.placeholder
              )}
            </Col>
          );
        } else if (cf.type === "textarea") {
          return (
            <Col key={`element-${cf.id}`} lg={12}>
              {createInputs(
                `custom_fields[element-${cf.id}]`,
                cf.name,
                cf.placeholder,
                "textarea"
              )}
            </Col>
          );
        } else if (cf.type === "checkbox") {
          if (cf.options) {
            var elements = [];
            cf.options.map((checkbox) => {
              elements.push(
                <div className="m-3 d-flex flex-row" key={checkbox}>
                  <label className="mr-1">{checkbox}</label>
                  <label className="custom-toggle mr-1">
                    <input
                      type="checkbox"
                      disabled={loading}
                      {...register(`custom_fields[element-${cf.id}]`)}
                      value={checkbox}
                      name={`custom_fields[element-${cf.id}]`}
                      id={`custom_fields[element-${cf.id}]`}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </div>
              );
            });
            return (
              <Col lg={12}>
                <label className="form-control-label">{cf.name}</label>
                <div className="d-flex flex-row flex-wrap ai-center">
                  {elements}
                </div>
              </Col>
            );
          }
        } else if (cf.type === "radio") {
          if (cf.options) {
            var elements = [];
            cf.options.map((radio) => {
              elements.push(
                <div className="m-3 d-flex flex-row" key={radio}>
                  <label className="mr-1"> {radio}</label>
                  <label className="custom-toggle mr-1">
                    <input
                      type="radio"
                      disabled={loading}
                      {...register(`custom_fields[element-${cf.id}]`)}
                      value={radio}
                      name={`custom_fields[element-${cf.id}]`}
                      id={`custom_fields[element-${cf.id}]`}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </div>
              );
            });
            return (
              <Col lg={12}>
                <label className="form-control-label">{cf.name}</label>
                <div className="d-flex flex-row flex-wrap ai-center">
                  {elements}
                </div>
              </Col>
            );
          }
        } else if (cf.type === "select") {
          if (cf.options)
            return (
              <Col lg={12} key={`element-${cf.id}`}>
                <FormGroup>
                  <label className="form-control-label">{cf.name}</label>
                  <Input
                    type="select"
                    disabled={loading}
                    {...registerRs(`custom_fields[element-${cf.id}]`, register)}
                  >
                    {cf.options.map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            );
        } else if (cf.type === "date") {
          return (
            <Col lg={12} key={`element-${cf.id}`}>
              <FormGroup>
                <label className="form-control-label">{cf.name}</label>
                <Controller
                  control={control}
                  name={`custom_fields[element-${cf.id}]`}
                  render={({ field }) => (
                    <ReactDatePicker
                      showIcon
                      selected={
                        field.value ? new Date(field.value) : new Date()
                      }
                      onChange={(date) => field.onChange(date)}
                      name={`custom_fields[element-${cf.id}]`}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  )}
                />
              </FormGroup>
            </Col>
          );
        }
      });
    }
  };
  return (
    <Modal isOpen={props.showModal} size="xl">
      <ModalHeader>{props.title}</ModalHeader>
      {loading ? (
        <div className="d-flex flex-row jc-center ai-center m-4">
          <Spinner />
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Row>
              <Col>{createInputs("firstname", "First Name", "First Name")}</Col>
              <Col>{createInputs("lastname", "Last Name", "Last Name")}</Col>
            </Row>
            <Row>
              <Col>{createInputs("city", "City", "City")}</Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">State</label>
                  <Controller
                    control={control}
                    name={"state"}
                    render={({ field }) => (
                      <Select2
                        onChange={(id) => field.onChange(id)}
                        value={field.value}
                        className="form-control"
                        options={{
                          placeholder: "State",
                        }}
                        data={
                          states
                            ? states.map((cl) => {
                                return {
                                  id: cl.name,
                                  text: cl.name,
                                };
                              })
                            : []
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>{createInputs("country", "Country", "Country")}</Col>
              <Col>{createInputs("zipcode", "Zip Code", "Zip Code")}</Col>
            </Row>
            {createInputs("address", "Address", "Address")}
            {createInputs("notes", "Notes", "Notes", "textarea")}
            <FormGroup>
              <label className="form-control-label">Phone</label>
              <Button
                size="sm"
                color="link"
                className="ml-2"
                onClick={() => {
                  addPhoneSwal();
                }}
              >
                Add Phone
              </Button>
              <br />
              <div className="d-flex flex-row flex-wrap">
                {renderItems(phones, setPhones, true)}
              </div>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label">Email</label>
              <Button
                size="sm"
                color="link"
                className="ml-2"
                onClick={() => {
                  addEmailSwal();
                }}
              >
                Add Email
              </Button>
              <br />
              <div className="d-flex flex-row flex-wrap">
                {renderItems(emails, setEmails)}
              </div>
            </FormGroup>
            <hr className="my-4" />
            <Row>{renderCustomFields()}</Row>
          </ModalBody>
          {loading ? (
            <ModalFooter>
              <div
                className="d-flex ai-center jc-center"
                style={{ width: "100%" }}
              >
                <Spinner animation="border" />
              </div>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => props.toggleModal(false)}
              >
                Discard
              </Button>
              <Button disabled={loading} color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Modal>
  );
}
