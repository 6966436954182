import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  FormText,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { APP_ADMIN_URL } from "consts/Constants";
import { useHistory } from "react-router-dom";
import { registerRs } from "consts/Constants";
const schema = yup
  .object({
    username: yup.string().required(Lang.emptyFieldError),
    password: yup.string().required(Lang.emptyFieldError),
  })
  .required();

export default function NewLogin() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    var url = APP_ADMIN_URL;
    var formData = new FormData();
    url = url + "login";
    formData.append("phone", data.username);
    formData.append("password", data.password);
    passwordLogin(url, formData);
    setLoading(true);
  };
  const passwordLogin = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.result.next === "find") {
          history.push("/uauth/register");
        } else {
          if (!res.data.hasError) {
            localStorage.setItem("token", `Bearer ${res.data.result.token}`);
            localStorage.setItem("firstname", res.data.result.data.firstname);
            localStorage.setItem("lastname", res.data.result.data.lastname);
            localStorage.setItem(
              "account",
              JSON.stringify(res.data.result.data)
            );
            if (res.data.result.data.is_admin) {
              history.push("/admin/dashboard");
            } else {
              history.push("/user/profile");
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Row>
        <Col lg={7} md={12}>
          <div className="d-flex flex-column ai-center jc-center h-100 w-100">
            <h1 className="heading-color mt-5">Login</h1>
            <p className="text-center heading-color my-3 w-60">
              Welcome to Lien Networks!
              <br />
              Connect with medical and legal professionals nationwide and unlock
              endless possibilities.
            </p>
            <Form
              role="form"
              onSubmit={handleSubmit(onSubmit)}
              className="d-block w-60"
            >
              <FormGroup className="mb-2">
                <Input
                  placeholder="Enter your email or phone number"
                  type="text"
                  disabled={loading}
                  {...registerRs("username", register)}
                />
                <FormText color="danger">{errors.username?.message}</FormText>
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  placeholder="Password"
                  type="password"
                  disabled={loading}
                  {...registerRs("password", register)}
                />
                <FormText color="danger">{errors.password?.message}</FormText>

                <a className="text-muted text-right d-block" href="otp">
                  <small>Login without password</small>
                </a>
              </FormGroup>
              <FormGroup>
                <Button block color="info" type="submit" disabled={loading}>
                  {loading ? "Signing you in..." : "Sign in"}
                </Button>
              </FormGroup>
              <FormGroup>
                <a
                  block
                  className="btn btn-default btn-block"
                  type="submit"
                  href="register"
                >
                  Create new account
                </a>
              </FormGroup>
            </Form>
          </div>
        </Col>
        <Col lg={5} className="d-none d-lg-block d-sm-none">
          <div className="login-right">
            <div className="d-flex flex-column jc-center ai-center login-right-wrapper">
              <h3>It has never been easier to expand your network!</h3>
              <div className="d-flex flex-row login-right-slog-wrapper">
                <div className="login-right-slog">
                  <h4>Your Network</h4>
                  <h4>Your Rules</h4>
                </div>
                <div className="login-right-line"></div>
                <img
                  src={require("./../../../../../assets/img/LN-logo.png")}
                  alt="Lien Networks"
                  height={50}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="login-bg-footer px-6 pt-4 pb-6">
        <Row>
          <Col lg={2} md={6} sm={6} xs={6} className="text-center mt-4">
            <img
              src={require("./../../../../../assets/img/LN-light-footer.png")}
              alt="Lien Networks"
              height={50}
            />
          </Col>
          <Col lg={2} md={6} sm={6} xs={6} className="mt-4">
            <span className="text-light text-bold">Follow us</span>
            <div className="d-flex flex-row mt-2">
              <img
                src={require("./../../../../../assets/img/icons/social/instagram.png")}
                alt="Lien Networks"
                height={25}
              />
              <img
                src={require("./../../../../../assets/img/icons/social/facebook.png")}
                alt="Lien Networks"
                height={25}
                className="px-1"
              />
              <img
                src={require("./../../../../../assets/img/icons/social/lin.png")}
                alt="Lien Networks"
                height={25}
                className="px-1"
              />
            </div>
          </Col>
          <Col lg={3} md={12} sm={12} xs={12} className="mt-4">
            <span className="text-light text-bold">Contact us</span>
            <div className="d-flex flex-column mt-2">
              <a href="tel:3107361397" className="text-light mt-2">
                310-736-1397
              </a>
              <a href="mailto:info@liennetworks.com" className="text-light">
                info@liennetworks.com
              </a>
              <a className="text-light mt-2">
                21731 Ventura Blvd., Suite 205, Woodland Hills, California
                91364, US
              </a>
            </div>
          </Col>
          <Col lg={5} md={12} sm={12} xs={12} className="mt-4">
            <span className="text-light text-bold">Subscribe</span>
            <p className="text-light mt-2 fs-13">
              Join our newsletter to stay up to date on features and releases.
            </p>
            <Row>
              <Col lg={9}>
                <Input
                  placeholder="Enter your email"
                  type="text"
                  className="d-block"
                />
              </Col>
              <Col lg={3}>
                <a
                  block
                  className="btn btn-default btn-block btn-subscribe"
                  type="submit"
                  href="register"
                >
                  Subscribe
                </a>
              </Col>
            </Row>
            <br />
            <span className="text-light fs-13">
              By subscribing you agree to with our Privacy Policy and provide
              consent to receive updates from our company.
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}
