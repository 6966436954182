import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";

const schema = yup
  .object({
    funnel_status: yup.string().required(),
  })
  .required();
export default function AdminLeadFunnelStatus(props) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    axiosConfig
      .post("admin/leads/funnel/" + props.user.id, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.getItems();
          props.toggleModal(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  useEffect(() => {
    reset({ funnel_status: props.user?.funnel_status });
  }, [props.user?.funnel_status, reset]);
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>
        {props.user?.firstname + " " + props.user?.lastname}
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormGroup>
            <label className="form-control-label">Registration Code</label>

            <Input disabled={true} value={props.user?.reg_code} />
          </FormGroup>
          {createInputs("funnel_status", "Funnel Status", "Funnel Status")}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Update
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
