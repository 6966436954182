import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { APP_IMAGE_URL } from "consts/Constants";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const history = useHistory();
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("account");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    history.push("/uauth/login");
  };
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-default": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          JSON.parse(localStorage.getItem("account")).avatar
                            ? APP_IMAGE_URL +
                              JSON.parse(localStorage.getItem("account")).avatar
                            : require("assets/img/user.webp")
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {localStorage.getItem("firstname")
                          ? localStorage.getItem("firstname")
                          : "User"}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">User</h6>
                  </DropdownItem>
                  <DropdownItem href="/user/profile">
                    <i className="ni ni-single-02" />
                    <span>Profile</span>
                  </DropdownItem>
                  <DropdownItem href="/user/integrations">
                    <i className="ni ni-user-run" />
                    <span>Integrations</span>
                  </DropdownItem>
                  <DropdownItem href="/user/billing">
                    <i className="ni ni-user-run" />
                    <span>Billing</span>
                  </DropdownItem>
                  {localStorage.getItem("account") ? (
                    JSON.parse(localStorage.getItem("account")).is_manager ? (
                      <>
                        <DropdownItem divider />

                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Organization</h6>
                        </DropdownItem>
                        <DropdownItem href="/organization/dashboard">
                          <i className="ni ni-single-02" />
                          <span>Dashboard</span>
                        </DropdownItem>
                        <DropdownItem href="/organization/billing">
                          <i className="ni ni-user-run" />
                          <span>Billing</span>
                        </DropdownItem>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("account") ? (
                    JSON.parse(localStorage.getItem("account")).is_admin ? (
                      <>
                        <DropdownItem divider />
                        <DropdownItem href="/admin/dashboard">
                          <i className="ni ni-single-02" />
                          <span>Administrator</span>
                        </DropdownItem>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={(e) => {
                      logOut();
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
