import React, { useState } from "react";
// reactstrap components
import { Button, FormGroup, Form, FormText, Input, Row, Col } from "reactstrap";
// core components
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { APP_ADMIN_URL } from "consts/Constants";
import { useHistory } from "react-router-dom";
import { registerRs } from "consts/Constants";
import AuthLayout from "../AuthLayout";
const schema = yup
  .object({
    username: yup.string().required(Lang.emptyFieldError),
    password: yup.string().required(Lang.emptyFieldError),
  })
  .required();

export default function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    var url = APP_ADMIN_URL;
    var formData = new FormData();
    url = url + "login";
    formData.append("phone", data.username);
    formData.append("password", data.password);
    passwordLogin(url, formData);
    setLoading(true);
  };
  const passwordLogin = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.result.next === "find") {
          history.push("/uauth/register");
        } else {
          if (!res.data.hasError) {
            localStorage.setItem("token", `Bearer ${res.data.result.token}`);
            localStorage.setItem("firstname", res.data.result.data.firstname);
            localStorage.setItem("lastname", res.data.result.data.lastname);
            localStorage.setItem(
              "account",
              JSON.stringify(res.data.result.data)
            );
            if (res.data.result.data.is_admin) {
              history.push("/admin/dashboard");
            } else {
              history.push("/user/profile");
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <AuthLayout>
      <Col lg={7} md={12}>
        <div className="d-flex flex-column ai-center jc-center h-100 w-100">
          <img
            src={require("./../../../../../assets/img/LN-logo.png")}
            height={150}
            alt="Lien Networks"
            className="mt-4"
          />
          <h1 className="heading-color mt-5">Login</h1>
          <p className="text-center heading-color my-3 w-60">
            Welcome to Lien Networks!
            <br />
            Connect with medical and legal professionals nationwide and unlock
            endless possibilities.
          </p>
          <Form
            role="form"
            onSubmit={handleSubmit(onSubmit)}
            className="d-block w-60"
          >
            <FormGroup className="mb-2">
              <Input
                placeholder="Enter your email or phone number"
                type="text"
                disabled={loading}
                {...registerRs("username", register)}
              />
              <FormText color="danger">{errors.username?.message}</FormText>
            </FormGroup>
            <FormGroup className="mb-3">
              <Input
                placeholder="Password"
                type="password"
                disabled={loading}
                {...registerRs("password", register)}
              />
              <FormText color="danger">{errors.password?.message}</FormText>

              <a className="text-muted text-right d-block" href="otp">
                <small>Login without password</small>
              </a>
            </FormGroup>
            <FormGroup>
              <Button block color="info" type="submit" disabled={loading}>
                {loading ? "Signing you in..." : "Sign in"}
              </Button>
            </FormGroup>
            <FormGroup>
              <a
                block
                className="btn btn-default btn-block"
                type="submit"
                href="register"
              >
                Create new account
              </a>
            </FormGroup>
          </Form>
        </div>
      </Col>
    </AuthLayout>
  );
}
