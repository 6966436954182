import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import UserProfileEditButtonWidget from "../Components/UserProfileEditButtonWidget";
import SocialMediaInformationModal from "./UserEditModals/SocialMediaInformationModal";

export default function SocialMediaInformationComponent(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SocialMediaInformationModal
        userData={props.userData}
        showModal={showModal}
        toggleModal={setShowModal}
        title={props.title}
        getProfile={props.getProfile}
        updateProfile={props.updateProfile}
      />
      <Row className="align-items-center mb-4">
        <Col xs="8">
          <h6 className="heading-small text-muted mb-0">{props.title}</h6>
        </Col>
        <UserProfileEditButtonWidget
          setShowModal={setShowModal}
          editable={props.editable}
        />
      </Row>
      <div className="pl-lg-4">
        <Row>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Google
              </label>
              <h5 className="h3 mt-1">
                {props.userData.google ? (
                  <a href={props.userData.google} target="_blank">
                    <i className="fab fa-google fs-22" />
                  </a>
                ) : (
                  "-"
                )}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Facebook
              </label>
              <h5 className="h3 mt-1">
                {props.userData.facebook ? (
                  <a href={props.userData.facebook} target="_blank">
                    <i className="fab fa-facebook fs-22" />
                  </a>
                ) : (
                  "-"
                )}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Instagram
              </label>
              <h5 className="h3 mt-1">
                {props.userData.instagram ? (
                  <a href={props.userData.instagram} target="_blank">
                    <i className="fab fa-instagram fs-22" />
                  </a>
                ) : (
                  "-"
                )}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Linkedin
              </label>
              <h5 className="h3 mt-1">
                {props.userData.linkedin ? (
                  <a href={props.userData.linkedin} target="_blank">
                    <i className="fab fa-linkedin fs-22" />
                  </a>
                ) : (
                  "-"
                )}
              </h5>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
