import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { useHistory } from "react-router-dom";
import { registerRs } from "consts/Constants";
import Swal from "sweetalert2";
import AuthLayout from "../AuthLayout";
const schema = yup
  .object({
    username: yup.string().required(Lang.emptyFieldError),
    firstname: yup.string().required(Lang.emptyFieldError),
    lastname: yup.string().required(Lang.emptyFieldError),
    password: yup.string().min(8).required(Lang.emptyFieldError),
    otp: yup.string(),
  })
  .required();
function Register() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [verifyRegister, setVerifyRegister] = useState("find");
  const [otpField, setOtpField] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    var formData = new FormData();
    if (verifyRegister === "find") {
      formData.append("phone", data.username);
      findUser("find", formData);
    } else if (verifyRegister === "verify") {
      formData.append("phone", data.username);
      formData.append("code", data.otp);
      formData.append("firstname", data.firstname);
      formData.append("lastname", data.lastname);
      verifyUser("verify", formData);
    }
    setLoading(true);
  };
  const findUser = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.result.next === "verify") {
          setVerifyRegister("verify");
          setOtpField(true);
        } else {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: Lang.youHaveAnaccountAlready,
            showConfirmButton: false,
            toast: true,
            timer: 1500,
            didClose: () => {
              history.push("login");
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const verifyUser = (url, formData) => {
    axiosConfig
      .post(url, formData)
      .then((res) => {
        setLoading(false);
        if (!res.data.hasError) {
          localStorage.setItem("token", `Bearer ${res.data.result.token}`);
          localStorage.setItem("firstname", res.data.result.account.firstname);
          localStorage.setItem("lastname", res.data.result.account.lastname);
          localStorage.setItem(
            "account",
            JSON.stringify(res.data.result.account)
          );
          setVerifyRegister("register");
          registerUser();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const registerUser = () => {
    axiosConfig
      .post("register", {
        password: watch("password"),
      })
      .then((res) => {
        setLoading(false);
        if (!res.data.hasError) {
          history.push("/general/complete");
        }
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <Col lg={7} md={12}>
        <div className="d-flex flex-column ai-center jc-center h-100 w-100">
          <img
            src={require("./../../../../../assets/img/LN-logo.png")}
            height={150}
            alt="Lien Networks"
            className="mt-4"
          />
          <h1 className="heading-color mt-5">
            {otpField ? "Verify" : "Create Account"}
          </h1>
          {!otpField ? (
            <p className="text-center heading-color my-3 w-60">
              Welcome to Lien Networks!
              <br />
              Connect with medical and legal professionals nationwide and unlock
              endless possibilities.
            </p>
          ) : (
            <></>
          )}
          <Form
            role="form"
            onSubmit={handleSubmit(onSubmit)}
            className="d-block w-60"
          >
            {otpField ? (
              <FormGroup className="mb-3">
                <span className="text-muted fs14 mb-3 block">
                  We’ve sent you a 4-digit code, please check your inbox
                </span>
                <Input
                  placeholder="4 Digits"
                  type="text"
                  name="otp"
                  disabled={loading || !otpField}
                  {...registerRs("otp", register)}
                />
                <FormText color="danger">{errors.otp?.message}</FormText>
              </FormGroup>
            ) : (
              <>
                <FormGroup className="mb-3">
                  <Input
                    placeholder="Name"
                    type="text"
                    disabled={loading}
                    {...registerRs("firstname", register)}
                  />
                  <FormText color="danger">
                    {errors.firstname?.message}
                  </FormText>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input
                    placeholder="Last Name"
                    type="text"
                    disabled={loading}
                    {...registerRs("lastname", register)}
                  />
                  <FormText color="danger">{errors.lastname?.message}</FormText>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input
                    placeholder="Enter your email or phone number"
                    type="text"
                    disabled={loading}
                    {...registerRs("username", register)}
                  />
                  <FormText color="danger">{errors.username?.message}</FormText>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input
                    placeholder="Password"
                    type="password"
                    disabled={loading}
                    {...registerRs("password", register)}
                  />

                  <FormText color="danger">{errors.password?.message}</FormText>
                </FormGroup>
              </>
            )}

            <FormGroup>
              <Button block color="info" type="submit" disabled={loading}>
                Create account
              </Button>
            </FormGroup>
          </Form>
        </div>
      </Col>
    </AuthLayout>
  );
}

export default Register;
