import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import Swal from "sweetalert2";
import { registerRs } from "consts/Constants";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ClientInformationCard from "./Sections/ClientInformationCard";
import { currencyFormatter } from "consts/Constants";
import CaseInformationSection from "./Sections/CaseInformationSection";
import CaseServicesSection from "./Services/CaseServicesSection";
import CaseFilesSection from "./Files/CaseFilesSection";
import CaseAppointmentsSection from "./Appointments/CaseAppointmentsSection";
import OverlayLoader from "consts/OverlayLoader";
import CaseInformationRead from "./Sections/CaseInformationRead";
import CaseReferSection from "./Sections/CaseReferSection";
import CaseReferInSection from "./Sections/CaseReferInSection";

export default function ShowCase({ match }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [caseData, setCaseData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [referIn, setReferIn] = useState([]);
  const [referOuts, setReferOuts] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [functions, setFunctions] = useState("");

  const getRefers = () => {
    axiosConfig.get("cases/refers/" + match.params.uuid).then((res) => {
      setReferIn(res.data.result.refer_in);
      setReferOuts(res.data.result.refer_outs);
    });
  };
  const getItem = () => {
    setLoading(true);
    axiosConfig
      .get("cases/show/" + match.params.uuid)
      .then((res) => {
        setLoading(false);
        setPageLoading(false);
        setCaseData(res.data.result.case);
        setCustomFields(res.data.result.custom_fields);
        setServiceList(res.data.result.services_list);
        setServiceName(res.data.result.services_name);
        setFunctions(res.data.result.functions);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRefers();
    getItem();
  }, []);
  if (pageLoading) {
    return (
      <div
        className="d-flex flex-column jc-center ai-center"
        style={{ height: 800 }}
      >
        <Spinner />
        <span className="text-muted mt-2">Loading...</span>
      </div>
    );
  }
  if (caseData && functions.view)
    return (
      <>
        <ListHeader parentName={caseData?.name ? caseData.name : ""} />
        {loading ? (
          <div
            className="d-flex flex-column jc-center ai-center"
            style={{ height: 800 }}
          >
            <Spinner />
            <span className="text-muted mt-2">Loading...</span>
          </div>
        ) : (
          <Container className="mt--6" fluid>
            <div className="card-columns case-columns">
              {functions.edit ? (
                <CaseInformationSection
                  loading={loading}
                  setLoading={setLoading}
                  getItem={getItem}
                  caseData={caseData}
                  customFields={customFields}
                  functions={functions}
                />
              ) : (
                <CaseInformationRead
                  caseData={caseData}
                  customFields={customFields}
                  functions={functions}
                />
              )}

              <CaseReferSection
                caseData={caseData}
                functions={functions}
                referOuts={referOuts}
                getRefers={getRefers}
              />

              <CaseAppointmentsSection
                caseData={caseData}
                getItem={getItem}
                functions={functions}
              />
              {/* <CaseReferInSection
                caseData={caseData}
                functions={functions}
                referIn={referIn}
                getRefers={getRefers}
              /> */}
              <ClientInformationCard
                client={caseData.client}
                caseData={caseData}
                customFields={customFields}
              />
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Case Total Services
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {currencyFormatter.format(caseData.services_total)}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                        <i className="ni ni-chart-bar-32" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <CaseServicesSection
                serviceList={serviceList}
                serviceName={serviceName}
                caseData={caseData}
                getItem={getItem}
                functions={functions}
              />
              <CaseFilesSection
                serviceList={serviceList}
                serviceName={serviceName}
                caseData={caseData}
                getItem={getItem}
                functions={functions}
              />
            </div>
          </Container>
        )}
      </>
    );
}
