import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";

const schema = yup
  .object({
    funnel_status: yup.string().required(),
    user_type_id: yup.string().required(),
    file: yup.mixed().nullable(),
  })
  .required();
export default function AdminImportModal(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    let formData = new FormData();
    for (var prop in data) {
      if (prop === "file") {
        formData.append("file", data.file ? data.file[0] : null);
      } else {
        formData.append(prop, data[prop]);
      }
    }

    setLoading(true);
    axiosConfig
      .post("admin/import_users", formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (!res.data.hasError) {
          props.toggleModal(false);
          props.getProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <ModalBody>
          {createInputs("funnel_status", "Funnel Status", "Funnel Status")}
          <FormGroup>
            <label className="form-control-label" htmlFor="user_type_id">
              User Type
            </label>
            <Input
              id="user_type_id"
              placeholder="User Type"
              type="select"
              disabled={loading}
              {...registerRs("user_type_id", register)}
            >
              <option value="1">Attorney</option>
              <option value="2">Medical Provider</option>
              <option value="3">Case Funding</option>
              <option value="4">Pharmacy</option>
              <option value="5">Transportation</option>
            </Input>

            <FormText color="danger">{errors.user_type_id?.message}</FormText>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-image">
              File
            </label>
            <Input
              id="input-image"
              placeholder="File"
              disabled={loading}
              type="file"
              {...registerRs("file", register)}
            />

            <FormText color="danger">{errors.file?.message}</FormText>
          </FormGroup>
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
