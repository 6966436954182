import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import UserProfileEditButtonWidget from "../Components/UserProfileEditButtonWidget";
import AboutInformationModal from "./UserEditModals/AboutInformationModal";

export default function AboutInformationComponent(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AboutInformationModal
        userData={props.userData}
        showModal={showModal}
        toggleModal={setShowModal}
        title={props.title}
        getProfile={props.getProfile}
        updateProfile={props.updateProfile}
      />
      <Row className="align-items-center mb-4">
        <Col xs="8">
          <h6 className="heading-small text-muted mb-0">{props.title}</h6>
        </Col>
        <UserProfileEditButtonWidget
          setShowModal={setShowModal}
          editable={props.editable}
        />
      </Row>
      <div className="pl-lg-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Specialty
              </label>
              <h5 className="h3">
                {props.userData.specialty ? props.userData.specialty : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Tagline
              </label>
              <h5 className="h3">
                {props.userData.tagline ? props.userData.tagline : "-"}
              </h5>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                About
              </label>
              <h5 className="h3">
                {props.userData.about ? props.userData.about : "-"}
              </h5>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
