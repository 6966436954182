import { yupResolver } from "@hookform/resolvers/yup";
import { registerRs } from "consts/Constants";
import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { Lang } from "consts/localization";
import axiosConfig from "consts/axiosConfig";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required(Lang.emptyFieldError),
    date: yup.string().required(),
    status: yup.string().required(),
    completed: yup.boolean().required(),
  })
  .required();
export default function CaseInformationSection(props) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup key={name}>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={props.loading || disabled}
          {...registerRs(name, register)}
        />
        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  useEffect(() => {
    var values = props?.caseData;
    if (values.cf_data) {
      if (values.cf_data.length !== 0) {
        values.custom_fields = values.cf_data;
      } else {
        delete values.cf_data;
      }
    }

    reset(values);
  }, [reset, props.caseData]);

  const renderCustomFields = () => {
    if (props.customFields)
      if (props.customFields.length) {
        return props.customFields.map((cf) => {
          if (cf.type === "text") {
            return (
              <Col key={`element-${cf.id}`} lg={12}>
                {createInputs(
                  `custom_fields[element-${cf.id}]`,
                  cf.name,
                  cf.placeholder
                )}
              </Col>
            );
          } else if (cf.type === "textarea") {
            return (
              <Col key={`element-${cf.id}`} lg={12}>
                {createInputs(
                  `custom_fields[element-${cf.id}]`,
                  cf.name,
                  cf.placeholder,
                  "textarea"
                )}
              </Col>
            );
          } else if (cf.type === "checkbox") {
            if (cf.options) {
              var elements = [];
              cf.options.map((checkbox) => {
                elements.push(
                  <div className="m-3 d-flex flex-row" key={checkbox}>
                    <label className="mr-1">{checkbox}</label>
                    <label className="custom-toggle mr-1">
                      <input
                        type="checkbox"
                        disabled={props.loading}
                        {...register(`custom_fields[element-${cf.id}]`)}
                        value={checkbox}
                        name={`custom_fields[element-${cf.id}]`}
                        id={`custom_fields[element-${cf.id}]`}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                );
              });
              return (
                <Col lg={12} key={cf.id}>
                  <label className="form-control-label">{cf.name}</label>
                  <div className="d-flex flex-row flex-wrap ai-center">
                    {elements}
                  </div>
                </Col>
              );
            }
          } else if (cf.type === "radio") {
            if (cf.options) {
              var elements = [];
              cf.options.map((radio) => {
                elements.push(
                  <div className="m-3 d-flex flex-row" key={radio}>
                    <label className="mr-1"> {radio}</label>
                    <label className="custom-toggle mr-1">
                      <input
                        type="radio"
                        disabled={props.loading}
                        {...register(`custom_fields[element-${cf.id}]`)}
                        value={radio}
                        name={`custom_fields[element-${cf.id}]`}
                        id={`custom_fields[element-${cf.id}]`}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                );
              });
              return (
                <Col lg={12} key={cf.id}>
                  <label className="form-control-label">{cf.name}</label>
                  <div className="d-flex flex-row flex-wrap ai-center">
                    {elements}
                  </div>
                </Col>
              );
            }
          } else if (cf.type === "select") {
            if (cf.options)
              return (
                <Col lg={12} key={`element-${cf.id}`}>
                  <FormGroup>
                    <label className="form-control-label">{cf.name}</label>
                    <Input
                      type="select"
                      disabled={props.loading}
                      {...registerRs(
                        `custom_fields[element-${cf.id}]`,
                        register
                      )}
                    >
                      {cf.options.map((op) => {
                        return (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              );
          } else if (cf.type === "date") {
            return (
              <Col lg={12} key={`element-${cf.id}`}>
                <FormGroup>
                  <label className="form-control-label">{cf.name}</label>
                  <Controller
                    control={control}
                    name={`custom_fields[element-${cf.id}]`}
                    render={({ field }) => (
                      <ReactDatePicker
                        showIcon
                        selected={
                          field.value ? new Date(field.value) : new Date()
                        }
                        onChange={(date) => field.onChange(date)}
                        name={`custom_fields[element-${cf.id}]`}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            );
          }
        });
      }
  };
  const onSubmit = (data) => {
    data.date = moment(data.date).format("yyyy-MM-DD");
    console.log(data);

    props.setLoading(true);
    axiosConfig
      .post("cases/update/" + props.caseData.id, data)
      .then((res) => {
        if (!res.data.hasError) {
          props.getItem();
        }
        props.setLoading(false);
      })
      .catch((error) => {
        props.setLoading(false);
      });
  };
  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader>
          <div className="d-flex flex-row jc-space-between ai-center">
            <h3>Case Dashboard</h3>
            {props.functions.referout ? (
              <a
                className="btn btn-info btn-md"
                href={"/user/refers/" + props.caseData.id}
              >
                Refer
              </a>
            ) : (
              <></>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>{createInputs("name", "Name", "Case Name")}</Col>
            <Col lg={12}>
              <FormGroup>
                <label className="form-control-label">Date of Injury</label>
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <ReactDatePicker
                      showIcon
                      disabled={props.loading}
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => field.onChange(date)}
                      name="date"
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label">Status</label>
                <Input
                  disabled={props.loading}
                  type="select"
                  {...registerRs("status", register)}
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label className="form-control-label">Completed</label>
                <Input
                  disabled={props.loading}
                  type="select"
                  {...registerRs("completed", register)}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </Input>
              </FormGroup>
            </Col>
            {renderCustomFields()}
          </Row>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-row jc-end">
            <Button
              disabled={props.loading}
              color="primary"
              type="submit"
              className="float-right"
            >
              Save
            </Button>
          </div>
        </CardFooter>
      </Form>
    </Card>
  );
}
