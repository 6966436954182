import { simpleWebRequest } from "consts/Constants";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Swal from "sweetalert2";

export default function OrganizationUsersComponent(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.userData.users);
  }, [props.userData.users]);
  const removeItem = (id) => {
    simpleWebRequest("organization/members/remove/" + id, props.getProfile);
  };
  const renderItems = () => {
    if (items.length) {
      return (
        <ul>
          {items.map((item, index) => (
            <div>
              <div className="d-flex flex-row jc-space-between edu-item jc-center">
                <div className="d-flex flex-row ai-center">
                  <div className="d-flex flex-column edu-item-inner ml-3">
                    <h4>{item.fullname}</h4>
                    <span>{item.email}</span>
                  </div>
                </div>
                <div>
                  <a
                    className="btn btn-info btn-sm"
                    href={"/organization/u/" + item.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-eye" />
                  </a>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You are about to remove this member from your organization",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          removeItem(item.id);
                        }
                      })
                    }
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              </div>
              <hr className="my-4" />
            </div>
          ))}
        </ul>
      );
    }
  };
  if (items.length === 0) {
    return;
  }
  return (
    <>
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{props.title}</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 sortable-list">{renderItems()}</CardBody>
      </Card>
    </>
  );
}
