import React, { useState } from "react";
import { Badge, Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import moment from "moment";
import axiosConfig from "consts/axiosConfig";
import { APP_IMAGE_URL } from "consts/Constants";

export default function CaseReferSection(props) {
  const [loading, setLoading] = useState(false);
  const renderStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <Badge color="primary">{status.toUpperCase()}</Badge>;
      case "approved":
        return <Badge color="success">{status.toUpperCase()}</Badge>;
      case "rejected":
        return <Badge color="danger">{status.toUpperCase()}</Badge>;
      case "expired":
        return <Badge color="warning">{status.toUpperCase()}</Badge>;
      case "canceled":
        return <Badge color="warning">{status.toUpperCase()}</Badge>;
      case "completed":
        return <Badge color="info">{status.toUpperCase()}</Badge>;

      default:
        break;
    }
  };

  const cencelRefer = (refer_id) => {
    setLoading(true);
    axiosConfig
      .post("refers/cancel/" + refer_id)
      .then((res) => {
        if (!res.data.hasError) {
          props.getRefers();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const cencelAllRefers = () => {
    setLoading(true);
    axiosConfig
      .post("refers/cancel_all/" + props.caseData?.id)
      .then((res) => {
        if (!res.data.hasError) {
          props.getRefers();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const renderRefers = () => {
    if (props.referOuts.length) {
      return props.referOuts.map((ro) => {
        return (
          <tr key={ro.id}>
            <td>
              {ro.to_organization ? (
                <a
                  href={`/user/o/${ro.to_organization.id}`}
                  target="_blank"
                  className="ml-2"
                >
                  {ro.to_organization.name}
                </a>
              ) : ro.to_user ? (
                <a
                  href={`/user/u/${ro.to_user.id}`}
                  target="_blank"
                  className="ml-2"
                >
                  {ro.to_user.fullname}
                </a>
              ) : (
                <a
                  href={`/user/u/${ro.to_user.id}`}
                  target="_blank"
                  className="ml-2"
                >
                  {ro.to_user.name}
                </a>
              )}
            </td>
            <td>{moment(ro.created_at).fromNow()}</td>
            <td>{ro.notes}</td>
            <td>{renderStatusBadge(ro.status)}</td>
            <td>
              {ro.status === "pending" ? (
                <Button
                  size="sm"
                  color="danger"
                  disabled={loading}
                  onClick={() => cencelRefer(ro.id)}
                >
                  Cancel
                </Button>
              ) : (
                <>-</>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-center">
          <td colSpan={5}>No referrals yet!</td>
        </tr>
      );
    }
  };
  if (props.functions.referout) {
    return (
      <Card className="h3-class">
        <CardHeader>
          <div className="d-flex flex-row jc-space-between ai-center">
            <h3>Referrals</h3>
            {/* {props.referOuts.length ? (
              <Button
                color="danger"
                size="sm"
                disabled={loading}
                onClick={() => cencelAllRefers()}
              >
                Cancel All
              </Button>
            ) : (
              <></>
            )} */}
          </div>
        </CardHeader>
        <CardBody>
          <Table striped className="text-center" responsive>
            <thead>
              <tr>
                <th>To</th>
                <th>Time</th>
                <th>Note</th>
                <th>Status</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>{renderRefers()}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
