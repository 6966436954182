import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ListHeader from "components/Headers/ListHeader";
import axiosConfig from "consts/axiosConfig";
import { useLocation } from "react-router-dom";
import FilterModal from "./FilterModal";
import Pagination from "react-js-pagination";
import moment from "moment";
import DownloadModal from "views/pages/components/DownloadModal";

const { SearchBar } = Search;

export default function AdminCases(props) {
  const query = new URLSearchParams(props.location.search);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [status, setStatus] = useState(false);
  const [currentPage, setCurrenPage] = useState(1);
  const [keyword, setKeyWord] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterisOn, setFilterIsOn] = useState(false);
  const [url, setUrl] = useState("");
  const [sort, setSort] = useState(null);
  const [sort_type, setSortType] = useState(null);

  const searchItems = (
    pageNum = 1,
    exportFile = false,
    s = null,
    stype = null
  ) => {
    setSort(s);
    setSortType(stype);
    setFilterIsOn(true);
    window.scrollTo(0, 0);
    var from = fromDate
      ? moment(
          fromDate.year + "-" + fromDate.month + "-" + fromDate.day
        ).format("yyyy-MM-DD")
      : "";
    var to = toDate
      ? moment(toDate.year + "-" + toDate.month + "-" + toDate.day).format(
          "yyyy-MM-DD"
        )
      : "";
    setLoading(true);
    setShowModal(false);
    axiosConfig
      .get(
        `admin/cases/search?keyword=${keyword}&page=${pageNum}${
          from ? "&start=" + from : ""
        }${to ? "&end=" + to : ""}${
          completed ? "&completed=" + completed : ""
        }${status ? "&status=" + status : ""}${exportFile ? "&export=1" : ""}${
          s ? "&sort=" + s : ""
        }${stype ? "&sort_type=" + stype : ""}`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setCases(res.data.result.cases);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const resetFilter = () => {
    setFilterIsOn(false);
    setFromDate(null);
    setToDate(null);
    setCompleted(null);
    setStatus(null);
    setKeyWord("");
  };
  const getItems = (pageNum, exportFile = false) => {
    resetFilter();
    window.scrollTo(0, 0);

    setLoading(true);
    axiosConfig
      .get(
        `admin/cases/search?page=${pageNum}&${query.toString()}${
          exportFile ? "&export=1" : ""
        }`
      )
      .then((res) => {
        setLoading(false);
        if (exportFile) {
          setUrl(res.data.result.url);
          setDownloadModal(true);
        } else {
          setCases(res.data.result.cases);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setCompleted(query.get("completed"));
    setStatus(query.get("status"));
    getItems(1);
  }, []);

  const renderUsers = () => {
    if (cases.data) {
      return cases.data.map((tr, index) => {
        return (
          <tr key={tr.id} className={`text-center`}>
            <td>{tr.name}</td>
            <td>{tr.completed ? "Yes" : "In-Progress"}</td>
            <td>{tr.status}</td>
            <td>{tr.client?.fullname}</td>
            <td>{tr.date}</td>
            <td>
              <Button
                color="info"
                size="sm"
                target="_blank"
                href={location.pathname + "/" + tr.id}
              >
                <i className="fa fa-eye" />
              </Button>
            </td>
          </tr>
        );
      });
    }
  };
  const renderActiveFilters = () => {
    if (!showModal)
      return (
        <>
          {status ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Status: {status}
            </Button>
          ) : (
            <></>
          )}
          {completed ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Completed Status: {completed ? "Yes" : "In-Progress"}
            </Button>
          ) : (
            <></>
          )}
          {fromDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Cases Created Since:{" "}
              {fromDate
                ? moment(
                    fromDate.year + "-" + fromDate.month + "-" + fromDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {toDate ? (
            <Button
              size="sm"
              className="ml-1"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              Cases Created Until:{" "}
              {toDate
                ? moment(
                    toDate.year + "-" + toDate.month + "-" + toDate.day
                  ).format("yyyy-MM-DD")
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {filterisOn ||
          keyword ||
          fromDate ||
          toDate ||
          status ||
          completed ? (
            <Button color="link" size="sm" onClick={() => getItems()}>
              Reset
            </Button>
          ) : (
            <></>
          )}
        </>
      );
  };
  const exportFile = () => {
    if (filterisOn) {
      searchItems(1, true);
    } else {
      getItems(1, true);
    }
  };
  return (
    <>
      <FilterModal
        showModal={showModal}
        toggleModal={setShowModal}
        completed={completed}
        setCompleted={setCompleted}
        status={status}
        setStatus={setStatus}
        filterItems={searchItems}
        title="Filter"
        query={query}
        loading={loading}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
      <ListHeader
        name="Cases"
        parentName="Dashboard"
        setShowModal={setShowModal}
        btnName="Filter"
        setExtraButton={() => exportFile()}
        extraBtnName="Export"
      />
      <DownloadModal
        showModal={downloadModal}
        toggleModal={setDownloadModal}
        url={url}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Cases</h3>
              </CardHeader>
              <div
                id="datatable-basic_filter"
                className="dataTables_filter px-4 pb-1 mt-4"
              >
                <form
                  onSubmit={(e) => {
                    setCurrenPage(1);
                    setCases([]);
                    searchItems(1);
                    e.preventDefault();
                  }}
                >
                  <label>
                    Search:
                    <label for="search-bar-0" className="search-label">
                      <input
                        id="search-bar-0"
                        type="text"
                        aria-labelledby="search-bar-0-label"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => {
                          setKeyWord(e.target.value);
                        }}
                      />
                    </label>
                    {renderActiveFilters()}
                  </label>
                </form>
              </div>
              <Table striped responsive>
                {loading ? (
                  <div className="overlay-loading d-flex jc-center pt-3">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <></>
                )}
                <thead>
                  <tr>
                    <th>
                      Name{" "}
                      {filterisOn ? (
                        <i
                          className="fa fa-sort"
                          onClick={() => {
                            searchItems(
                              1,
                              false,
                              "name",
                              sort_type === "ASC" ? "DESC" : "ASC"
                            );
                          }}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th>
                      Completed{" "}
                      {filterisOn ? (
                        <i
                          className="fa fa-sort"
                          onClick={() => {
                            searchItems(
                              1,
                              false,
                              "completed",
                              sort_type === "ASC" ? "DESC" : "ASC"
                            );
                          }}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th>
                      Status
                      {filterisOn ? (
                        <i
                          className="fa fa-sort"
                          onClick={() => {
                            searchItems(
                              1,
                              false,
                              "status",
                              sort_type === "ASC" ? "DESC" : "ASC"
                            );
                          }}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th>Client</th>
                    <th>
                      Case Date
                      {filterisOn ? (
                        <i
                          className="fa fa-sort"
                          onClick={() => {
                            searchItems(
                              1,
                              false,
                              "date",
                              sort_type === "ASC" ? "DESC" : "ASC"
                            );
                          }}
                        ></i>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </Table>

              <div className="d-flex flex-row ai-center jc-space-between mt-4 mx-4">
                <div>
                  <p>
                    Showing {cases?.from}-{cases?.to} from {cases?.total}{" "}
                    results
                  </p>
                </div>
                <Pagination
                  activePage={cases?.current_page ? cases?.current_page : 0}
                  itemsCountPerPage={cases?.per_page ? cases?.per_page : 0}
                  totalItemsCount={cases?.total ? cases?.total : 0}
                  onChange={(pageNumber) => {
                    filterisOn ? searchItems(pageNumber) : getItems(pageNumber);
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
