import { formatPhoneNumber } from "consts/Constants";
import moment from "moment";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
  Table,
} from "reactstrap";

export default function ClientInformationCard(props) {
  const renderItems = (items, isPhone = false) => {
    if (items) {
      if (items.length) {
        return items.map((item) => {
          return (
            <div
              className="d-flex flex-row jc-space-between ai-center multiple-tag"
              key={item}
            >
              <span>{isPhone ? formatPhoneNumber(item) : item}</span>
            </div>
          );
        });
      }
    } else {
      return <span>-</span>;
    }
  };
  const renderStaticData = (key, label) => {
    return (
      <FormGroup>
        <label>
          <h4 style={{ fontSize: 12 }}>{label}</h4>
          {props?.client[key]}
        </label>
      </FormGroup>
    );
  };
  const renderCustomFields = () => {
    if (props.customFields)
      if (props.customFields.length && props.caseData?.cf_data) {
        return props.customFields.map((cf) => {
          if (cf.type === "text") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {props.caseData?.cf_data[`element-${cf.id}`]}
                  </label>
                </FormGroup>
              </Col>
            );
          } else if (cf.type === "textarea") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {props.caseData?.cf_data[`element-${cf.id}`]}
                  </label>
                </FormGroup>
              </Col>
            );
          } else if (cf.type === "checkbox") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {props.caseData?.cf_data[`element-${cf.id}`]}
                  </label>
                </FormGroup>
              </Col>
            );
          } else if (cf.type === "radio") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {props.caseData?.cf_data[`element-${cf.id}`]}
                  </label>
                </FormGroup>
              </Col>
            );
          } else if (cf.type === "select") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {props.caseData?.cf_data[`element-${cf.id}`]}
                  </label>
                </FormGroup>
              </Col>
            );
          } else if (cf.type === "date") {
            return (
              <Col key={`element-${cf.id}`} lg={6}>
                <FormGroup>
                  <label>
                    <h4 style={{ fontSize: 12 }}>{cf.name}</h4>
                    {moment(props.caseData?.cf_data[`element-${cf.id}`]).format(
                      "yyyy-MM-DD"
                    )}
                  </label>
                </FormGroup>
              </Col>
            );
          }
        });
      }
  };
  if (props.client)
    return (
      <Card className="h3-class">
        <CardHeader>
          <h3>Client Information</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>{renderStaticData("firstname", "First Name")}</Col>
            <Col>{renderStaticData("lastname", "Last Name")}</Col>
          </Row>
          <FormGroup>
            <label className="form-control-label">Phone</label>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(props.client["phones"], true)}
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">Email</label>
            <br />
            <div className="d-flex flex-row flex-wrap">
              {renderItems(props.client["emails"])}
            </div>
          </FormGroup>
          {renderStaticData("address", "Address")}
          <Row>
            <Col>{renderStaticData("city", "City")}</Col>
            <Col>{renderStaticData("state", "State")}</Col>
            <Col>{renderStaticData("country", "Country")}</Col>
            <Col>{renderStaticData("zipcode", "Zip Code")}</Col>
          </Row>

          {renderStaticData("notes", "Notes")}
          {/* <Row>{renderCustomFields()}</Row> */}
        </CardBody>
      </Card>
    );
}
