import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import axiosConfig from "consts/axiosConfig";
import { Lang } from "consts/localization";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerRs } from "consts/Constants";

const schema = yup
  .object({
    firstname: yup.string().required(Lang.emptyFieldError),
    lastname: yup.string().required(Lang.emptyFieldError),
    email: yup.string().email(Lang.emailIsNotValid).nullable(),
    phone: yup.string().required(Lang.emptyFieldError).nullable(),
    website: yup.string().nullable(),
    practice_name: yup.string().required(Lang.emptyFieldError),
  })
  .required();
export default function BasicInformationModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    props.updateProfile(data, props.toggleModal, setLoading);
  };

  useEffect(() => {
    reset(props.userData);
  }, [props.userData, reset]);
  const createInputs = (
    name,
    label,
    placeHolder,
    type = "text",
    disabled = false
  ) => {
    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={`input-${name}`}>
          {label}
        </label>
        <Input
          id={`input-${name}`}
          placeholder={placeHolder}
          type={type}
          disabled={loading || disabled}
          {...registerRs(name, register)}
        />

        <FormText color="danger">{errors[name]?.message}</FormText>
      </FormGroup>
    );
  };
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>{props.title}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {createInputs("firstname", "First Name", "First Name")}
          {createInputs("lastname", "Last Name", "Last Name")}
          {createInputs(
            "email",
            "Email Address",
            "Email Address",
            "email",
            true
          )}
          {createInputs("phone", "Phone Number", "Phone Number", "text", true)}
          {createInputs("website", "Webiste", "Website")}
          {createInputs("practice_name", "Parctice Name", "Parctice Name")}
        </ModalBody>
        {loading ? (
          <ModalFooter>
            <div
              className="d-flex ai-center jc-center"
              style={{ width: "100%" }}
            >
              <Spinner animation="border" />
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button color="secondary" onClick={() => props.toggleModal(false)}>
              Discard
            </Button>
            <Button disabled={loading} color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
}
