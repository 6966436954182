import React from "react";
// reactstrap components
import { Col } from "reactstrap";
import AuthLayout from "../AuthLayout";
function Terms() {
  return (
    <AuthLayout>
      <Col lg={7} md={12}>
        <div className="d-flex flex-column ai-center jc-center h-100 w-100 text-center">
          <div className="text-center w-70">
            <h1>Terms</h1>
            <br />
            <h3>LIEN NETWORKS TERMS OF USE AGREEMENT</h3>
            <br />
            <p className="text-justify">
              1. ACCEPTANCE OF TERMS By using the services offered by Lien
              Networks ("the Company"), you agree to be bound by the following
              terms and conditions ("Terms of Use").
              <br />
              2. SERVICE DESCRIPTION The Company provides a software service
              referral network for lawyers and medical providers who work on
              lien ("the Service"). You understand and agree that the Service is
              provided on an "AS IS" and "AS AVAILABLE" basis.
              <br />
              3. ELIGIBILITY By using the Service, you represent and warrant
              that you are of legal age to form a binding contract with the
              Company and meet all the foregoing eligibility requirements.
              <br />
              4. REGISTRATION To use the Service, you may be required to
              register with the Company and select a password and user name. You
              promise to provide us with accurate, complete, and updated
              registration information about yourself.
              <br />
              5. USER CONDUCT As a user, you agree not to misuse the Service or
              help anyone else do so. You agree not to engage in any conduct
              that disrupts, interferes with, or otherwise harms or violates the
              security of the Service, or any services, systems resources,
              accounts, passwords, servers or networks connected to or
              accessible through the Service.
              <br />
              6. CONTENT RESPONSIBILITY The Company does not monitor or control
              the content users post via the Service and, we cannot take
              responsibility for such content.
              <br />
              7. INTELLECTUAL PROPERTY RIGHTS All rights, titles, and interests
              in and to the Service (excluding Content provided by users) are
              and will remain the exclusive property of the Company and its
              licensors.
              <br />
              8. TERMINATION The Company may terminate or suspend your access to
              the Service, for any reason, without notice and liability,
              including in the event of your breach of these Terms.
              <br />
              9. DISCLAIMER OF WARRANTIES THE SERVICE IS PROVIDED ON AN "AS IS"
              AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTY OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              OR NON-INFRINGEMENT.
              <br />
              10. LIMITATION OF LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY
              LAW, THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS
              OR REVENUES.
              <br />
              11. INDEMNIFICATION You agree to indemnify and hold harmless the
              Company and its officers, directors, employees and agents, from
              and against any claims, suits, proceedings, disputes, demands,
              liabilities, damages, losses, costs and expenses.
              <br />
              12. GOVERNING LAW AND JURISDICTION These Terms of Use shall be
              governed by the laws of the state of California, without regard to
              conflict of law provisions.
              <br />
              13. CHANGES TO TERMS The Company reserves the right to modify
              these Terms at any time. We will always post the most current
              version on our website. By continuing to use the Service, you
              agree to be bound by the updated Terms.
              <br />
              14. CONTACT INFORMATION If you have any questions about these
              Terms, please contact the Company at info@liennetworks.com.
            </p>
          </div>
        </div>
      </Col>
    </AuthLayout>
  );
}

export default Terms;
