import OrganizationPublicProfile from "views/pages/Profiles/OrganizationPublicProfile";
import UserPublicProfile from "views/pages/Profiles/UserPublicProfile";
import CompleteProfile from "views/pages/User/Auth/CompleteProfile";
import Login from "views/pages/User/Auth/Login/Login";
import OtpLogin from "views/pages/User/Auth/Login/OtpLogin";
import NewLogin from "views/pages/User/Auth/NewLogin";
import Register from "views/pages/User/Auth/Register/index";
import Privacy from "views/pages/User/Auth/StaticPages/Privacy";
import Terms from "views/pages/User/Auth/StaticPages/Terms";
import Billing from "views/pages/User/Billing";
import ExploreNetwork from "views/pages/User/Network/ExploreNetwork";
import ManageNetwork from "views/pages/User/Network/ManageNetwork";
import RefersOut from "views/pages/User/Refers/ReferOut";
import RefersIn from "views/pages/User/Refers/RefersIn";
import ReferWizard from "views/pages/User/ReferWizard";
import CaseReports from "views/pages/User/Reporting/CaseReports";
import ClientsReports from "views/pages/User/Reporting/ClientsReports";
import ReferInsReports from "views/pages/User/Reporting/ReferInsReports";
import ReferOutsReports from "views/pages/User/Reporting/ReferOutsReports";
import UserCases from "views/pages/User/UserCases";
import ShowCase from "views/pages/User/UserCases/Case/ShowCase";
import UserClients from "views/pages/User/UserClients";
import UserDashboard from "views/pages/User/UserDashboard";
import UDashChart from "views/pages/User/UserDashboard/UDashChart";
import UserPowerUps from "views/pages/User/UserPowerUps";
import UserProfile from "views/pages/User/UserProfile";
import UserWebhooks from "views/pages/User/UserWebhooks";

const userRoutes = [
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/newlogin",
    name: "Login",
    component: NewLogin,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/otp",
    name: "OtpLogin",
    component: OtpLogin,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    layout: "/uauth",
    showInMenu: false,
  },
  {
    path: "/complete",
    name: "Complete",
    component: CompleteProfile,
    layout: "/general",
    showInMenu: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-home text-default",
    component: UserDashboard,
    layout: "/user",
    showInMenu: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    icon: "fa fa-user text-default",
    layout: "/user",
    showInMenu: true,
    step: "profile-step",
  },
  {
    path: "/clients",
    name: "Clients",
    component: UserClients,
    icon: "fa fa-users text-default",
    layout: "/user",
    showInMenu: true,
    step: "client-step",
  },
  {
    path: "/cases/:uuid",
    name: "Case",
    component: ShowCase,
    icon: "fa fa-file-invoice text-default",
    layout: "/user",
    showInMenu: false,
  },
  {
    path: "/cases",
    name: "Cases",
    component: UserCases,
    icon: "fa fa-file-invoice text-default",
    layout: "/user",
    showInMenu: true,
    step: "case-step",
  },
  {
    collapse: true,
    name: "Referrals",
    icon: "fa fa-exchange-alt text-default",
    state: "refersCollapse",
    showInMenu: true,
    step: "referrals-step",
    views: [
      {
        path: "/refers/in",
        name: "Referrals In",
        component: RefersIn,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
      {
        path: "/refers/out",
        name: "Referrals Out",
        component: RefersOut,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Network",
    icon: "fa fa-globe text-default",
    state: "networkCollapse",
    showInMenu: true,
    views: [
      {
        path: "/network/manage",
        name: "Manage Network",
        component: ManageNetwork,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
      {
        path: "/network/explore",
        name: "Explore",
        component: ExploreNetwork,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
    ],
  },
  {
    path: "/refers/:case_id",
    name: "Refer",
    component: ReferWizard,
    icon: "fa fa-file-invoice text-default",
    layout: "/user",
    showInMenu: false,
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: UserWebhooks,
    icon: "fa fa-globe text-default",
    layout: "/user",
    showInMenu: false,
    step: "integration-step",
  },
  {
    path: "/powerups",
    name: "Preferred Members",
    component: UserPowerUps,
    icon: "fa fa-ad text-default",
    layout: "/user",
    showInMenu: true,
    step: "powerups-step",
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    icon: "fa fa-credit-card text-default",
    layout: "/user",
    showInMenu: false,
    step: "billing-step",
  },
  {
    path: "/u/:user_id",
    name: "User Profile",
    component: UserPublicProfile,
    icon: "fa fa-credit-card text-default",
    layout: "/user",
    showInMenu: false,
  },
  {
    path: "/o/:user_id",
    name: "Organization Profile",
    component: OrganizationPublicProfile,
    icon: "fa fa-credit-card text-default",
    layout: "/user",
    showInMenu: false,
  },

  {
    collapse: true,
    name: "Reports",
    icon: "fa fa-chart-line text-default",
    state: "reportsCollapse",
    showInMenu: true,
    step: "reporting-step",
    views: [
      {
        path: "/reports/case",
        name: "Cases",
        component: CaseReports,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
      {
        path: "/reports/client",
        name: "Clients",
        component: ClientsReports,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
      {
        path: "/reports/referins",
        name: "Refer Ins",
        component: ReferInsReports,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
      {
        path: "/reports/referouts",
        name: "Refer Outs",
        component: ReferOutsReports,
        miniName: "G",
        layout: "/user",
        showInMenu: true,
      },
    ],
  },
];

export default userRoutes;
