import React from "react";
import { Table } from "reactstrap";

export default function NetworkList(props) {
  const renderUsers = () => {
    if (props.followers.length) {
      return props.followers.map((tr) => {
        return (
          <tr
            key={tr.id}
            className="text-center"
            style={{ color: "#" + tr.type_color }}
          >
            <td>
              <a
                href={`/user/u/${tr.id}`}
                target="_blank"
                style={{ color: "#" + tr.type_color, fontWeight: "500" }}
              >
                {tr.fullname}
              </a>
            </td>
            <td>{tr.type?.name}</td>
            <td>{tr.state}</td>
            <td>{tr.city}</td>
            <td>
              {tr.practice_areas
                ? tr.practice_areas.map((pa) => {
                    return pa.name + " ";
                  })
                : ""}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr className="text-center">
          <td colSpan={5}>
            <p>No Connections yet!</p>
          </td>
        </tr>
      );
    }
  };
  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>State</th>
          <th>City</th>
          <th>Practice</th>
        </tr>
      </thead>
      <tbody>{renderUsers()}</tbody>
    </Table>
  );
}
