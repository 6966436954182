import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import UDashChart from "views/pages/User/UserDashboard/UDashChart";
import NetworkList from "./NetworkList";
import NetwrokDiagram from "./NetworkDiagram";
export default function Network(props) {
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setItems(props.followers);
  }, [props.followers]);

  if (!props.coudBeRendered) {
    return;
  }
  return (
    <Col lg={12}>
      <Card className="card-profile">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{props.title}</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pl-3 sortable-list">
          <Nav tabs>
            <NavItem>
              <NavLink
                onClick={() => setActiveTab(0)}
                active={activeTab === 0 ? "active" : ""}
              >
                Map
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => setActiveTab(1)}
                active={activeTab === 1 ? "active" : ""}
              >
                List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => setActiveTab(2)}
                active={activeTab === 2 ? "active" : ""}
              >
                Diagram
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="mt-3">
            <TabPane tabId={0}>
              <Row>
                <Col sm="12">
                  <UDashChart
                    points={Object.values(props.followers).map((item) => {
                      if (item.lat) {
                        return {
                          lat: parseFloat(item.lat),
                          lon: parseFloat(item.lng),
                          name: item.fullname,
                          color: "#" + item.type_color,
                        };
                      }
                    })}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={1}>
              <Row>
                <Col sm="12">
                  <NetworkList followers={props.followers} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={2}>
              <Row>
                <Col sm="12">
                  <NetwrokDiagram followers={props.followers} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
}
